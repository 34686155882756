import { CircularProgress, InputAdornment } from "@material-ui/core";
import classNames from "classnames";
import { getAsyncRequest } from "gx-npm-lib";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  Paragraph,
  ProductLogo,
  TextField,
  TypographyComponent,
} from "gx-npm-ui";
import { ChangeEvent, Fragment, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import AutoComplete from "../../../../../../ui/autocomplete";
import { ProductsContext } from "../../../../products.context";
import {
  InitiativeProduct,
  ProductsAddCustomApiResponse,
  ProductsAddCustomOption,
  ProductsSearchResult,
} from "../../../../products.types";
import { ProductsAddContext } from "../../products-add.context";
import ProductsAddDialogBodyOptionV2Component from "./products-add-dialog-body-option-v2.component";
import styles from "./products-add-dialog-body-v2.styles.module.scss";
import { GCOM_3606__fontUpdate } from "../../../../../../lib/feature-flags";

const ProductsAddDialogBodyV2Component = () => {
  const { t } = useTranslation();
  const searchResultsHelperOption: ProductsAddCustomOption = {
    imageLoc: "",
    isDisable: true,
    isInEvaluation: false,
    isLogoDisplayed: false,
    message: t("Don’t see your vendor? Type it in and press Enter to add it."),
    piProductId: -1,
    piProductName: "",
  };
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [searchTimer, setSearchTimer] = useState<number>(0);
  const [suggestions, setSuggestions] = useState<ProductsAddCustomOption[]>([searchResultsHelperOption]);
  const { productSelectedOption, setProductSearchValue, setProductSelectedOption } = useContext(ProductsAddContext);
  const { products } = useContext(ProductsContext);

  const handleChange = (_event: MouseEvent, product: ProductsAddCustomOption) => {
    setProductSelectedOption(product);
  };

  const handleClickAway = () => {
    setSuggestions([searchResultsHelperOption]);
  };

  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    setProductSearchValue(value);
    setProductSelectedOption(null);
    clearTimeout(searchTimer);
    const timer = window.setTimeout(async () => {
      if (value.length > 2) {
        await fetchSuggestedProducts(value);
      } else if (value.length === 0) {
        setSuggestions([searchResultsHelperOption]);
      }
    }, 250);
    setSearchTimer(timer);
  };

  const fetchSuggestedProducts = async (searchTerm: string) => {
    setIsSearchLoading(true);
    const config = { params: { searchTerm } };
    const response: ProductsAddCustomApiResponse = await getAsyncRequest("api/v2/data/product/search", config);
    if (response?.status === 200 && Array.isArray(response.data?.data)) {
      const options = response.data.data.map((searchResultProduct: ProductsSearchResult) => {
        const isExistingProduct = products.some((existingProduct: InitiativeProduct) => {
          return (
            existingProduct.piProductId === searchResultProduct.piProductId ||
            existingProduct.name?.toLocaleLowerCase() === searchResultProduct.piProductName?.toLocaleLowerCase()
          );
        });
        return {
          imageLoc: searchResultProduct.imageLoc,
          isDisable: isExistingProduct,
          isInEvaluation: isExistingProduct,
          isLogoDisplayed: true,
          message: "",
          piProductId: searchResultProduct.piProductId,
          piProductName: searchResultProduct.piProductName,
        };
      });
      setSuggestions([searchResultsHelperOption, ...options]);
    }
    setIsSearchLoading(false);
  };

  return (
    <div className={styles.bodyContainer}>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent rootClassName={styles.helpText} styling={"p2"}>
            {t("Don’t see a vendor you’d like to include in your evaluation?")}
            {` `}
            {t("Add it here to keep track of all vendors under consideration.")}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <Paragraph rootClassName={styles.helpText} type="p2">
            {t("Don’t see a vendor you’d like to include in your evaluation?")}
            {` `}
            {t("Add it here to keep track of all vendors under consideration.")}
          </Paragraph>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
      <AutoComplete
        classes={{
          container: "customProductContainer",
          listbox: styles.autoCompleteListBox,
          option: styles.autoCompleteOption,
          paper: styles.autoCompletePaper,
          popper: classNames(styles.autoCompletePopper, "customProductPopper"),
        }}
        onChange={handleChange}
        onClickAway={handleClickAway}
        onInputChange={handleInputChange}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              autoFocus={true}
              inputProps={{ maxLength: 60 }}
              label={t("Vendor")}
              required={true}
              rootClassName={styles.addProductTextField}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {isSearchLoading && <CircularProgress color="inherit" size={20} />}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
                startAdornment: (
                  <Fragment>
                    <InputAdornment position="start">
                      {productSelectedOption && (
                        <ProductLogo
                          imageHeight="24px"
                          imageWidth="24px"
                          logo={productSelectedOption.imageLoc}
                          name={productSelectedOption.piProductName}
                        />
                      )}
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </Fragment>
                ),
              }}
            />
          );
        }}
        renderOption={(options: ProductsAddCustomOption) => <ProductsAddDialogBodyOptionV2Component {...options} />}
        suggestions={suggestions}
      />
    </div>
  );
};

export default ProductsAddDialogBodyV2Component;
