import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  Header,
  Paragraph,
  TypographyComponent,
} from "gx-npm-ui";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import NoProductsImage from "../../../../assets/images/vendors/noProducts.svg";
import ProductsAddV2Component from "../products-add-v2/products-add-v2.component";
import styles from "./products-empty-view-v2.styles.module.scss";
import { GCOM_3606__fontUpdate } from "../../../../lib/feature-flags";

const ProductsEmptyViewV2Component = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className={styles.noProducts}>
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent rootClassName={styles.title} styling={"h4"}>
              {t("Get started by adding vendors you're considering")}
            </TypographyComponent>
            <TypographyComponent rootClassName={styles.description} element={"p"}>
              {t(
                "Once you have your initial consideration set, you can finalize who is in your shortlist for a deeper evaluation."
              )}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <Header rootClassName={styles.title} type="h4">
              {t("Get started by adding vendors you're considering")}
            </Header>
            <Paragraph rootClassName={styles.description}>
              {t(
                "Once you have your initial consideration set, you can finalize who is in your shortlist for a deeper evaluation."
              )}
            </Paragraph>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
        <img alt={t("no products")} src={NoProductsImage} />
      </div>
      <div className={styles.addProductWrapper}>
        <ProductsAddV2Component />
      </div>
    </Fragment>
  );
};

export default ProductsEmptyViewV2Component;
