import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { handleEvent, isString } from "gx-npm-lib";
import { Button, Dialog } from "gx-npm-ui";
import { deleteDialogStyles as styles } from "./styles";

const propTypes = {
  isOpen: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func,
  entityName: PropTypes.string,
};
const useStyles = makeStyles(() => styles);
const DeleteEntityDialog = ({ isOpen = false, name = "", onClick = () => {}, entityName = "requirement" }) => {
  const classes = useStyles();
  const isEnabled = true;
  const defaultBody = `Untitled ${entityName}`;
  const preBody = "Are you sure you want to discard ";
  const body = (
    <span className={classNames("semi-bold", "gx-delete-dialog")}>{!!name && isString(name) ? name : defaultBody}</span>
  );
  const postBody = `? This ${entityName} will be permanently deleted.`;
  return (
    <Dialog
      body={
        <p>
          {preBody}
          {body}
          {postBody}
        </p>
      }
      footer={
        <div className={classes.root}>
          <Button rootClassName="btn-tertiary" disabled={!isEnabled} onClick={() => handleEvent(onClick, false)}>
            {"CANCEL"}
          </Button>
          <Button
            rootClassName="primary-destructive-btn"
            disabled={!isEnabled}
            onClick={() => handleEvent(onClick, true)}
          >
            {"DELETE"}
          </Button>
        </div>
      }
      handleClose={() => handleEvent(onClick, false)}
      open={isOpen}
      title={`Delete ${entityName}`}
    />
  );
};

DeleteEntityDialog.propTypes = propTypes;
export default DeleteEntityDialog;
