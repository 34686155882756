import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { defaultToZero } from "gx-npm-lib";
import { numberOfNumberV2Styles as styles } from "./number-of-number-v2.styles";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { GCOM_3477_3679__layoutChanges } from "../../../../lib/feature-flags";
import { useTranslation } from "react-i18next";

type NumberOfNumberProps = {
  numOne: number;
  numTwo: number;
  rootClassName?: string;
};

const useStyles = makeStyles(() => styles);
const NumberOfNumberV2 = ({ numOne, numTwo, rootClassName }: NumberOfNumberProps) => {
  const { t } = useTranslation();
  const one = defaultToZero(numOne);
  const two = defaultToZero(numTwo);
  const classes = useStyles();
  return (
    <div className={classNames(classes.numberOfNumberRoot, rootClassName)} data-testid={"numOfnum-test"}>
      <FeatureFlagBooleanContainer flagName={GCOM_3477_3679__layoutChanges}>
        <FeatureFlagBooleanOn>
          <TypographyComponent styling="h4">
            <TypographyComponent boldness="medium" element="span" styling="h4">
              {one}
            </TypographyComponent>
            <TypographyComponent boldness="semi" rootClassName={classes.splitter} element="span" styling="p3">
              {t("of")}
            </TypographyComponent>
            <TypographyComponent boldness="medium" element="span" styling="h4">
              {two}
            </TypographyComponent>
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <span className={classNames(classes.numberChild, "gx-num")}>{one}</span>
          <span className={classNames(classes.numberChild, "gx-of")}>of</span>
          <span className={classNames(classes.numberChild, "gx-num")}>{two}</span>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
    </div>
  );
};

export default NumberOfNumberV2;
