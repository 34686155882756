import { colorPalette } from "gx-npm-common-styles";
import { GearIcon } from "gx-npm-icons";
import { InitUserRole, useUserInitAccess, UUID } from "gx-npm-lib";
import { updateStateSubject } from "gx-npm-messenger-util";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  MiniButton,
  SnackbarBanner,
  TypographyComponent,
} from "gx-npm-ui";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as singleSpa from "single-spa";
import ExportScorecardButton from "./export-scorecard-button.component";
import styles from "./scorecard-header.styles.module.scss";
import QuickstartGuideComponent from "./quickstart-guide/quickstart-guide.component";
import divider from "../../../../../assets/images/divider.svg";
import classNames from "classnames";
import { GCOM_3606__fontUpdate } from "../../../../../lib/feature-flags";

const NON_VIEWER_ROLES: Array<InitUserRole | ""> = [InitUserRole.OWNER, InitUserRole.CONTRIBUTOR];
const ScorecardHeaderComponent = () => {
  const { t } = useTranslation();
  const { initiativeId = "" } = useParams<{ initiativeId: UUID }>();
  const { role } = useUserInitAccess(initiativeId);
  const [hasError, setHasError] = useState(false);

  const handleClickShare = () => {
    updateStateSubject("EVAL_HEADER_SOURCE_PAGE", { sourcePage: "scorecard", target: "SCORING_ASSIGNMENTS" });
    singleSpa.navigateToUrl(`/s/evaluation/${initiativeId}/scorecard/team-management`);
  };

  return (
    <Fragment>
      <div className={classNames(styles.headerContainer)}>
        <div className={styles.headingTitle}>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent color={"carbon"} styling={"h3"}>
                {t("Scorecard overview")}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <TypographyComponent color="carbon" type="h3">
                {t("Scorecard overview")}
              </TypographyComponent>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
          <img alt="divider" src={divider} className={styles.dividerImg} />
          <QuickstartGuideComponent />
        </div>
        <div className={styles.headingButtons}>
          <ExportScorecardButton
            isViewer={!NON_VIEWER_ROLES.includes(role)}
            initiativeId={initiativeId}
            onError={() => setHasError(true)}
          />

          <img alt="divider" src={divider} className={styles.dividerImg} />
          <MiniButton disabled={role !== InitUserRole.OWNER} onClick={handleClickShare}>
            <GearIcon fillPath={colorPalette.interactions.blueBerry.hex} />
            {t("Manage scoring")}
          </MiniButton>
        </div>
      </div>
      <SnackbarBanner isDefaultErrorMessage={true} isOpen={hasError} setIsOpen={setHasError} type="ERROR" />
    </Fragment>
  );
};

export default ScorecardHeaderComponent;
