import { colorPalette, weightPalette } from "gx-npm-common-styles";

const colorCarbonHex = colorPalette.neutrals.carbon.hex;
const colorCherryHex = colorPalette.status.cherry.hex;
const colorPoisonCherryHex = colorPalette.status.poisonCherry.hex;
const colorStoneHex = colorPalette.neutrals.stone.hex;
const colorLightBerryHex = colorPalette.interactions.lightBerry.hex;
const colorWhiteHex = colorPalette.basic.white.hex;
const colorIronHex = colorPalette.neutrals.iron.hex;
const defaultCta = colorPalette.interactions.defaultCta.hex;

const textAreaStyles = {
  fontUpdate: {
    "& textarea": {
      fontFamily: `"Graphik", "Roboto", "Helvetica Neue" !important`,
    },
  },
  baseTextAreaRoot: {
    "& textarea": {
      border: "0",
      borderRadius: "0",
      color: colorCarbonHex,
      fontSize: "14px",
      fontVariationSettings: weightPalette.semiBold.variation,
      fontWeight: weightPalette.semiBold.amount,
      letterSpacing: "0.25px",
      lineHeight: "21px",
      margin: "0",
      outline: "0",
      paddingBottom: "13px",
      paddingLeft: "12px",
      paddingRight: "12px",
      paddingTop: "14px",
      resize: "none",
      width: "300px",
      "&:focus, &:focus:hover": {
        backgroundColor: colorLightBerryHex,
        color: colorCarbonHex,
        marginBottom: "-2px",
        marginTop: "2px",
        outline: `2px solid ${defaultCta}`,
        paddingBottom: "11px",
        paddingTop: "12px",
        "&::selection": {
          backgroundColor: colorPalette.interactions.berry.hex,
        },
      },
      "&:hover": {
        backgroundColor: colorPalette.neutrals.quartz.hex,
        color: colorCarbonHex,
        marginBottom: "-1px",
        marginTop: "1px",
        outline: `1px solid ${colorStoneHex}`,
        paddingBottom: "12px",
        paddingTop: "13px",
      },
      "&::selection": {
        backgroundColor: colorLightBerryHex,
      },
    },
    "&.textarea-rounded": {
      "& textarea": {
        borderRadius: "4px",
        "&:focus, &:focus:hover": {
          borderRadius: "4px",
        },
        "&:hover": {
          borderRadius: "4px",
        },
      },
    },
    "&.char-count": {
      position: "relative",
      "& textarea": {
        paddingRight: 75,
      },
    },
    "&.required": {
      "& $helperText": {
        "&::after": {
          content: "'*'",
          color: colorCherryHex,
          paddingLeft: 5,
        },
      },
    },
    "&.resize-vertical": {
      "& textarea": {
        resize: "vertical",
      },
    },
  },
  // no used, but syntax for creating flavors
  baseTextAreaDefaultFlavor: {
    "&$baseTextAreaRoot": {},
  },
  baseTextAreaOutlinedFlavor: {
    position: "relative",
    backgroundColor: "transparent",
    "&.isError": {
      "& textarea": {
        outline: `2px solid ${colorCherryHex}`,
        "&:focus, &:focus:hover": {
          outline: `2px solid ${colorCherryHex}`,
        },
        "&:hover": {
          outline: `1px solid ${colorCherryHex}`,
        },
      },
      "&&& $helperText": {
        color: colorPoisonCherryHex,
      },
    },
    "& textarea:first-of-type": {
      color: colorCarbonHex,
      fontVariationSettings: weightPalette.regular.variation,
      fontWeight: weightPalette.regular.amount,
      marginBottom: "-1px",
      marginTop: "1px",
      outline: `1px solid ${colorStoneHex}`,
      paddingBottom: "12px",
      paddingTop: "13px",
      "&:focus, &:hover": {
        backgroundColor: colorWhiteHex,
      },
      "&:focus": {
        marginBottom: "-2px",
        marginTop: "2px",
        outline: `2px solid ${defaultCta}`,
        paddingBottom: "11px",
        paddingTop: "12px",
      },
      "&:focus ~ $helperText, &:focus:hover ~ $helperText": {
        color: defaultCta,
        "&::before": {
          content: "' '",
          backgroundColor: colorWhiteHex,
          height: "4px",
          left: -2,
          position: "absolute",
          top: 8,
          width: "calc(100% + 6px)",
          zIndex: -1,
        },
      },
      "&::placeholder": {
        color: "transparent",
        "&::after": {
          content: "*",
          color: "red",
        },
      },
      "&:focus, &:not(:placeholder-shown)": {
        "& ~ $helperText": {
          transform: "translate(-13px, -24px) scale(0.75)",
        },
      },
      "&:disabled": {
        backgroundColor: colorWhiteHex,
        outline: `1px dashed ${colorIronHex}`,
        textFillColor: colorIronHex,
      },
    },
    "&.filled": {
      "& $helperText": {
        "&::before": {
          content: "' '",
          backgroundColor: colorWhiteHex,
          height: "4px",
          left: -2,
          position: "absolute",
          top: 8,
          width: "calc(100% + 6px)",
          zIndex: -1,
        },
      },
    },
  },
  charCount: {
    bottom: 20,
    color: colorIronHex,
    fontSize: "12px",
    fontVariationSettings: weightPalette.regular.variation,
    fontWeight: weightPalette.regular.amount,
    position: "absolute",
    right: 12,
  },
  helperText: {
    cursor: "text",
    backgroundColor: colorWhiteHex,
    color: colorIronHex,
    letterSpacing: "0.15px",
    fontSize: 14,
    fontVariationSettings: weightPalette.regular.variation,
    fontWeight: weightPalette.regular.amount,
    left: 8,
    lineHeight: "12px",
    padding: 4,
    position: "absolute",
    top: 15,
    transition: "0.3s ease-in-out",
  },
  newFonts: {
    fontFamily: `"Graphik", "Roboto", "Helvetica Neue" !important`,
  },
  bottomHelperText: {
    color: colorIronHex,
    marginTop: "3px",
    "&$bottomHelperTextError": {
      color: colorPoisonCherryHex,
    },
  },
  bottomHelperTextError: {},
};

export { textAreaStyles };
