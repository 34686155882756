import React, { Fragment, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { Popper } from "@material-ui/core";
import { AddTeamMemberIcon, CloseIcon } from "gx-npm-icons";
import classes from "./assignment-popper.styles.module.scss";
import AddEvaluators from "./assignment-list-items/add-evaluators.component";
import AvailableEvaluator from "./assignment-list-items/available-evaluator.component";
import UnassignedEvaluator from "./assignment-list-items/unassigned-evaluator.component";
import AssignedEvaluator from "./assignment-list-items/assigned-evaluator.component";
import { AssigmentPopperType, AssignmentPopperPosition } from "./assignment-popper.types";
import { IconButton } from "../iconButton";
import { MiniButton } from "../miniButton";
import { Paragraph } from "../typography/paragraph/paragraph.component";
import { ClickAwayListener } from "@mui/material";
import { FeatureFlagBooleanContainer, FeatureFlagBooleanOff, FeatureFlagBooleanOn } from "../featureFlags";
import { TypographyComponent } from "../typography/typography.component";
import { colorPalette } from "gx-npm-common-styles";
import { GCOM_3606__fontUpdate } from "../feature-flags";

const AssignmentPopper = (props: AssigmentPopperType) => {
  const [numOfAvailableEvaluators, setNumOfAvailableEvaluators] = useState(0);
  const arrowRef = useRef<HTMLSpanElement>();
  const leftScrollRef = useRef<HTMLDivElement>();
  const rightScrollRef = useRef<HTMLDivElement>();
  const [refLoaded, setRefLoaded] = useState(false);
  const [rightShadow, setRightShadow] = useState(false);
  const [leftShadow, setLeftShadow] = useState(false);

  useEffect(() => {
    setNumOfAvailableEvaluators(props.evaluatorsAvailableList.length);
  }, [props.evaluatorsAvailableList]);

  useEffect(() => {
    setRefLoaded(false);
    if (!props.open) {
      setLeftShadow(false);
      setRightShadow(false);
    }
  }, [props]);

  const setOffset = () => {
    let offset = "0px, 0px";
    if ([AssignmentPopperPosition.LEFT, AssignmentPopperPosition.RIGHT].includes(props.position)) {
      offset = "0px, 20px";
    } else if ([AssignmentPopperPosition.TOP, AssignmentPopperPosition.BOTTOM].includes(props.position)) {
      offset = "-140px, 20px";
    }
    return offset;
  };

  useEffect(() => {
    const toggleShadows = () => {
      if (leftScrollRef?.current && rightScrollRef?.current) {
        setLeftShadow(leftScrollRef?.current?.scrollTop > 0);
        setRightShadow(rightScrollRef?.current?.scrollTop > 0);
      }
    };
    leftScrollRef.current?.addEventListener("scroll", toggleShadows);
    rightScrollRef.current?.addEventListener("scroll", toggleShadows);
    return () => {
      leftScrollRef.current?.removeEventListener("scroll", toggleShadows);
      rightScrollRef.current?.removeEventListener("scroll", toggleShadows);
    };
  }, [refLoaded]);

  return (
    <Fragment>
      <ClickAwayListener onClickAway={(event) => props.onClickAway && props.onClickAway(event)}>
        <Popper
          anchorEl={props.anchor}
          className={classNames(classes.assignmentPopper, props.rootClassName)}
          modifiers={{
            arrow: { element: arrowRef.current, enabled: true },
            flip: { enabled: false },
            offset: { offset: props.offset || setOffset() },
            preventOverflow: {
              enabled: true,
              priority: [AssignmentPopperPosition.TOP],
            },
          }}
          open={props.open}
          placement={props.position}
        >
          <div className={classes.popperContent}>
            <div
              className={classNames(classes.available)}
              ref={(element) => {
                if (element) {
                  leftScrollRef.current = element;
                  setRefLoaded(true);
                }
              }}
              data-testid={"left-scroller"}
            >
              <div
                data-testid={"scrollable"}
                className={classNames(classes.columnHeaderUnassigned, leftShadow && classes.isScrolling)}
              >
                <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                  <FeatureFlagBooleanOn>
                    <TypographyComponent rootClassName={classes.categoryTitle} boldness={"semi"} styling={"p3"}>
                      {props.categoryName}
                    </TypographyComponent>
                    <TypographyComponent boldness={"semi"} styling={"p4"}>
                      {props.availableTeamMembersText}{" "}
                      <TypographyComponent rootClassName={classNames(classes.gxCount)} styling={"span"}>
                        ({numOfAvailableEvaluators})
                      </TypographyComponent>
                    </TypographyComponent>
                  </FeatureFlagBooleanOn>
                  <FeatureFlagBooleanOff>
                    <Paragraph rootClassName={classes.categoryTitle} boldness="semi" type="p3">
                      {props.categoryName}
                    </Paragraph>
                    <Paragraph boldness="semi" type="p4">
                      {props.availableTeamMembersText}{" "}
                      <span className={classNames(classes.gxCount)}>({numOfAvailableEvaluators})</span>
                    </Paragraph>
                  </FeatureFlagBooleanOff>
                </FeatureFlagBooleanContainer>
              </div>
              <div className={classes.columnContent}>
                {props.showAddAllEvaluatorsBtn && numOfAvailableEvaluators >= 1 && (
                  <AddEvaluators
                    addBtnText={props.addBtnText}
                    allMembersText={props.allMembersText}
                    onAddAllEvaluatorsClick={props.onAddAllEvaluatorsClick}
                  />
                )}
                {props.evaluatorsAvailableList.map((evaluator) => (
                  <AvailableEvaluator
                    evaluator={evaluator}
                    onAssignEvaluatorClick={props.onAssignEvaluatorClick}
                    addBtnText={props.addBtnText}
                    key={"avail--" + evaluator.email}
                  />
                ))}
                <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                  <FeatureFlagBooleanOn>
                    {props.evaluatorsAvailableList.length === 0 && (
                      <TypographyComponent rootClassName={classes.noAssigned} styling={"p4"}>
                        {props.allTeamMembersAssignedText}
                      </TypographyComponent>
                    )}
                    {props.viewOnlyUsersList.length > 0 && (
                      <TypographyComponent rootClassName={classes.gxViewOnlyEvaluator} boldness="semi" styling={"p4"}>
                        {props.viewOnlyTeamMembersText}{" "}
                        <span className={classNames(classes.gxCount)}>({props.viewOnlyUsersList.length})</span>
                      </TypographyComponent>
                    )}
                  </FeatureFlagBooleanOn>
                  <FeatureFlagBooleanOff>
                    {props.evaluatorsAvailableList.length === 0 && (
                      <Paragraph rootClassName={classes.noAssigned} type="p4">
                        {props.allTeamMembersAssignedText}
                      </Paragraph>
                    )}
                    {props.viewOnlyUsersList.length > 0 && (
                      <Paragraph rootClassName={classes.gxViewOnlyEvaluator} boldness="semi" type="p4">
                        {props.viewOnlyTeamMembersText}{" "}
                        <span className={classNames(classes.gxCount)}>({props.viewOnlyUsersList.length})</span>
                      </Paragraph>
                    )}
                  </FeatureFlagBooleanOff>
                </FeatureFlagBooleanContainer>

                {props.viewOnlyUsersList.map((evaluator) => (
                  <UnassignedEvaluator
                    evaluator={evaluator}
                    pendingBtnText={props.pendingBtnText}
                    key={"unassigned--" + evaluator.email}
                  />
                ))}
              </div>
              {props.showAddTeamMemberBtn && (
                <div className={classes.addTeamMemberContainer}>
                  <MiniButton onClick={props.onAddTeamMemberClick}>
                    <AddTeamMemberIcon fillPath={colorPalette.interactions.defaultCta.hex} />
                    {props.addTeamMemberButtonText}
                  </MiniButton>
                </div>
              )}
              <div className={classes.unassignedFooter} />
            </div>
            <div
              className={classNames(classes.assigned)}
              data-testid={"right-scroller"}
              ref={(element) => {
                if (element) {
                  rightScrollRef.current = element;
                }
              }}
            >
              <div className={classes.closePopper}>
                <IconButton ariaLabel={"close"} onClick={props.onClose}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div>
                <div className={classNames(classes.columnHeaderAssigned, rightShadow && classes.isScrolling)}>
                  <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                    <FeatureFlagBooleanOn>
                      <TypographyComponent boldness="semi" styling={"p4"}>
                        {props.assignedText}{" "}
                        <span className={classNames(classes.gxAssignedCount)}>
                          ({props.evaluatorsAssignedList.length})
                        </span>
                      </TypographyComponent>
                    </FeatureFlagBooleanOn>
                    <FeatureFlagBooleanOff>
                      <Paragraph boldness="semi" type="p4">
                        {props.assignedText}{" "}
                        <span className={classNames(classes.gxAssignedCount)}>
                          ({props.evaluatorsAssignedList.length})
                        </span>
                      </Paragraph>
                    </FeatureFlagBooleanOff>
                  </FeatureFlagBooleanContainer>
                </div>
                <div className={classes.columnContent}>
                  {props.evaluatorsAssignedList.map((evaluator) => (
                    <AssignedEvaluator
                      dialogRemoveAssignedEvaluator={props.dialogRemoveAssignedEvaluator}
                      evaluator={evaluator}
                      onRemoveAssignedEvaluatorClick={props.onRemoveAssignedEvaluatorClick}
                      removeBtnText={props.removeBtnText}
                      showDialogRemoveAssignedEvaluator={props.showDialogRemoveAssignedEvaluator}
                      key={"assigned--" + evaluator.email}
                    />
                  ))}
                  <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                    <FeatureFlagBooleanOn>
                      {props.evaluatorsAssignedList.length === 0 && (
                        <TypographyComponent rootClassName={classes.noAssigned} styling={"p4"}>
                          {props.noTeamMembersAssignedText}
                        </TypographyComponent>
                      )}
                    </FeatureFlagBooleanOn>
                    <FeatureFlagBooleanOff>
                      {props.evaluatorsAssignedList.length === 0 && (
                        <Paragraph rootClassName={classes.noAssigned} type="p4">
                          {props.noTeamMembersAssignedText}
                        </Paragraph>
                      )}
                    </FeatureFlagBooleanOff>
                  </FeatureFlagBooleanContainer>
                </div>
                <div className={classes.assignedFooter} />
              </div>
            </div>
          </div>
          <span
            className={classNames(classes.gxPopperArrow, classes[props.position.toLowerCase()])}
            ref={(element) => {
              if (element) {
                arrowRef.current = element;
              }
            }}
          />
        </Popper>
      </ClickAwayListener>
    </Fragment>
  );
};

export default AssignmentPopper;
