import { InitState, postAsyncRequest } from "gx-npm-lib";
import { RestoreAlertBanner, SnackbarBanner } from "gx-npm-ui";
import { Fragment, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { HeaderV2Context } from "../../header-v2.context";

const InitStateBannerComponent = () => {
  const { initiativeId } = useParams();
  const { t } = useTranslation();
  const [isFailedUpdate, setIsFailedUpdate] = useState(false);
  const [isSuccessfulUpdate, setIsSuccessfulUpdate] = useState(false);
  const { initState, stateUpdateDate, setInitState } = useContext(HeaderV2Context);

  // todo use access hook, default to true
  const [isRestricted, _setIsRestricted] = useState(false);

  const handleClickRestoreBanner = async () => {
    const url = `api/v2/initiatives/${initiativeId}/action/active`;
    const response = await postAsyncRequest(url);
    if (response.status === 201) {
      setIsSuccessfulUpdate(true);
      setInitState(InitState.ACTIVE);
    } else {
      setIsFailedUpdate(true);
    }
  };
  return (
    <Fragment>
      {[InitState.ARCHIVED, InitState.DELETED].includes(initState as InitState) && (
        <RestoreAlertBanner
          actionDate={stateUpdateDate}
          initState={initState as InitState}
          isRestorable={!isRestricted}
          onClickAction={handleClickRestoreBanner}
        />
      )}
      <SnackbarBanner
        autoHideDuration={3000}
        isOpen={isSuccessfulUpdate}
        message={t("Evaluation has been restored.")}
        setIsOpen={() => setIsSuccessfulUpdate(false)}
        type="SUCCESS"
      />
      <SnackbarBanner
        autoHideDuration={3000}
        isDefaultErrorMessage={true}
        isOpen={isFailedUpdate}
        setIsOpen={() => setIsFailedUpdate(false)}
        type="ERROR"
      />
    </Fragment>
  );
};

export default InitStateBannerComponent;
