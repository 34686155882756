import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { MQ } from "../../../../../../selection.types";
import styles from "./magic-quadrant.module.scss";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TextLink,
  TypographyComponent,
} from "gx-npm-ui";
import QuadrantBox from "./components/quadrant-box/quadrant-box.component";
import { GCOM_3606__fontUpdate } from "../../../../../../../../lib/feature-flags";

type MagicQuadrantProps = {
  mq: MQ;
  isExpanded: boolean;
};

const MagicQuadrant = ({ mq, isExpanded }: MagicQuadrantProps) => {
  const { t } = useTranslation();
  const qdPositions = ["Challenger", "Leader", "Niche Player", "Visionary"];
  const noPosition = "NONE";

  const { qdPosition, docURL, qdDesc } = mq;

  if (qdPosition === "") {
    return null;
  }

  return (
    <div className={classNames(styles.root, isExpanded ? styles.isExpanded : "")}>
      <div className={styles.section}>
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent styling={"p3"} rootClassName={styles.sectionTitle}>
              {t("Gartner Magic Quadrant")}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <TypographyComponent type="p3" rootClassName={styles.sectionTitle}>
              {t("Gartner Magic Quadrant")}
            </TypographyComponent>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      </div>
      <div className={styles.qdWrapper}>
        {qdPosition !== noPosition ? (
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent styling={"h4"} rootClassName={styles.qdText}>
                {qdPosition}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <TypographyComponent type="h4" rootClassName={styles.qdText}>
                {qdPosition}
              </TypographyComponent>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        ) : (
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent styling={"p3"} rootClassName={styles.noMq}>
                {t("There is no Magic Quadrant for this technology.")}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <TypographyComponent type="p3" rootClassName={styles.noMq}>
                {t("There is no Magic Quadrant for this technology.")}
              </TypographyComponent>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        )}
        <div className={styles.qdDetails}>
          <div className={styles.qdBox}>
            {qdPosition !== noPosition &&
              qdPositions.map((position) => <QuadrantBox key={position} position={position} qdPosition={qdPosition} />)}
          </div>
          <div className={styles.qdDescWrapper}>
            {qdDesc && (
              <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                <FeatureFlagBooleanOn>
                  <TypographyComponent styling={"p4"} rootClassName={styles.qdDesc}>
                    {qdDesc}
                  </TypographyComponent>
                </FeatureFlagBooleanOn>
                <FeatureFlagBooleanOff>
                  <TypographyComponent type="p4" rootClassName={styles.qdDesc}>
                    {qdDesc}
                  </TypographyComponent>
                </FeatureFlagBooleanOff>
              </FeatureFlagBooleanContainer>
            )}
            {docURL && (
              <>
                <TextLink href={docURL} target="_blank" text={t("Learn more about this Magic Quadrant")} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MagicQuadrant;
