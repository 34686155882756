import { colorPalette } from "gx-npm-common-styles";
import { AdditionCircledIcon, SubtractionCircledIcon } from "gx-npm-icons";
import { PriorityWeightOption } from "gx-npm-lib";
import { IconButton } from "../../../iconButton";
import { TypographyComponent } from "../../../typography/typography.component";
import WeightSelection from "../weight-selection/weight-selection.component";
import styles from "./priority-row.styles.module.scss";
import { FeatureFlagBooleanContainer, FeatureFlagBooleanOff, FeatureFlagBooleanOn } from "../../../featureFlags";
import { GCOM_3606__fontUpdate } from "../../../feature-flags";
type PriorityRowProps = {
  name?: string;
  weight: PriorityWeightOption;
  onChangePriority?: (weight: PriorityWeightOption) => void;
};
const PriorityRowComponent = ({
  name,
  weight,
  onChangePriority = (_weight: PriorityWeightOption) => {},
}: PriorityRowProps) => {
  const handlePlusClick = () => {
    if (weight === PriorityWeightOption.NONE) {
      onChangePriority(PriorityWeightOption.LOW);
    } else if (weight === PriorityWeightOption.LOW) {
      onChangePriority(PriorityWeightOption.MEDIUM);
    } else {
      onChangePriority(PriorityWeightOption.HIGH);
    }
  };

  const handleSubtractClick = () => {
    if (weight === PriorityWeightOption.HIGH) {
      onChangePriority(PriorityWeightOption.MEDIUM);
    } else if (weight === PriorityWeightOption.MEDIUM) {
      onChangePriority(PriorityWeightOption.LOW);
    } else {
      onChangePriority(PriorityWeightOption.NONE);
    }
  };

  return (
    <div className={styles.row}>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent rootClassName={styles.priorityName} boldness="medium" color="coal" styling={"p3"}>
            {name}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <TypographyComponent rootClassName={styles.priorityName} boldness="medium" color="coal" type={"p3"}>
            {name}
          </TypographyComponent>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>

      <div className={styles.buttonsWeightSelectionWrapper}>
        {weight !== PriorityWeightOption.NONE && (
          <IconButton
            fillColor={colorPalette.interactions.defaultCta.hex}
            ariaLabel="decrease priority weight"
            hoverFill={colorPalette.interactions.hoverCta.hex}
            onClick={handleSubtractClick}
          >
            <SubtractionCircledIcon />
          </IconButton>
        )}
        <WeightSelection weight={weight} />
        <IconButton
          fillColor={colorPalette.interactions.defaultCta.hex}
          ariaLabel="increase priority weight"
          disabled={weight === PriorityWeightOption.HIGH}
          hoverFill={colorPalette.interactions.hoverCta.hex}
          onClick={handlePlusClick}
        >
          <AdditionCircledIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default PriorityRowComponent;
