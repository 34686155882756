import { colorPalette } from "gx-npm-common-styles";

const colorPearl = colorPalette.neutrals.pearl.hex;
const displayFlex = "flex";
const percent100 = "100%";

const libraryBodyStyles = {
  bodyContainer: {
    borderBottom: `solid 1px ${colorPalette.neutrals.silver.hex}`,
    display: displayFlex,
    padding: "48px 0px",
    width: percent100,
  },
  bodyHalve: {
    display: "block",
    width: "50%",
  },
  stepIcon: {
    alignItems: "center",
    backgroundColor: colorPearl,
    border: `3px solid ${colorPearl}`,
    borderRadius: "50%",
    display: displayFlex,
    height: "44px",
    justifyContent: "center",
    width: "44px",
  },
  sectionTitle: {
    alignItems: "center",
    display: displayFlex,
    paddingLeft: "12px",
  },
  bodyTitle: {
    display: displayFlex,
  },
  bodyDesc: {
    display: "block",
    paddingLeft: "56px",
    paddingTop: "2px",
  },
  bodyDescTwo: {
    border: `1px dashed ${colorPearl}`,
    color: colorPalette.neutrals.carbon.hex,
    padding: "20px",
    paddingBottom: "24px",
  },
  bodyDescLeft: {
    color: colorPalette.neutrals.carbon.hex,
    padding: "8px 0",
  },
  bodyDescRight: {
    "& *": {
      display: "inline",
    },
  },
  bodySubDescText: {
    color: colorPalette.neutrals.pewter.hex,
  },
};

const processErrorStyles = {
  errorContainer: {
    border: `1px solid ${colorPalette.status.cherry.hex}`,
    borderRadius: "4px",
    display: displayFlex,
    padding: "34px 34px 24px",
    width: percent100,
  },
  errorLeft: {
    display: "inline-block",
  },
  errorRight: {
    display: displayFlex,
    marginLeft: "auto",
    order: 2,
    "& .btn-tertiary": {
      margin: "auto 0px",
    },
  },
  errorTitle: {
    alignItems: "center",
    color: colorPalette.status.poisonCherry.hex,
    display: displayFlex,
  },
  errorTitleText: {
    paddingLeft: "14px",
  },
  errorPoints: {
    color: colorPalette.neutrals.iron.hex,
  },
};

const processIndicatorStyles = {
  indicatorContainer: {
    alignItems: "center",
    backgroundColor: colorPalette.neutrals.quartz.hex,
    borderRadius: "8px",
    display: displayFlex,
    minHeight: "147.5px",
    padding: "0 40px 0 32px",
    width: percent100,
  },
  divider: {
    color: colorPalette.neutrals.pewter.hex,
    margin: "0 4px",
  },
  spinIcon: {
    animationName: "$processSpinner",
    animationDuration: "1s",
    animationTimingFunction: "linear",
    animationIterationCount: "infinite",
    marginLeft: "auto",
    marginRight: 0,
    maxHeight: "24px",
    maxWidth: "24px",
    order: 2,
  },
  "@keyframes processSpinner": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
};

const libraryDialogStyles = {
  confirmDelete: {
    display: "flex",
    justifyContent: "flex-end",
  },
};

export { libraryBodyStyles, libraryDialogStyles, processErrorStyles, processIndicatorStyles };
