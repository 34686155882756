import { Box } from "@material-ui/core";
import classNames from "classnames";
import {
  Button,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
  MiniButton,
  ProductLogo,
  SnackbarBanner,
  AwardProductDialog,
} from "gx-npm-ui";
import { defaultToEmptyString, postAsyncRequest, UUID } from "gx-npm-lib";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as singleSpa from "single-spa";
import RectangleSVG from "../../../../assets/images/selection/rectangle.svg";
import VendorsSVG from "../../../../assets/images/selection/vendors.svg";
import VendorSvgGCOM_3755 from "../../../../assets/images/selection/vendorsGCOM_3755.svg";
import styles from "./pre-selection-view.styles.module.scss";
import { FileTypePdfIcon } from "gx-npm-icons";
import { useState } from "react";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { GCOM_3755__vendorSelections } from "../../../../lib/feature-flags";
import { Product } from "../../selection.types";

type PreselectionViewProps = {
  productsInEval?: Product[]; // making it optional until GCOM_3755 is removed
};
const PreSelectionView = ({ productsInEval }: PreselectionViewProps) => {
  const { t } = useTranslation();
  const { initiativeId = "" } = useParams<{ initiativeId: UUID }>();
  const [selectedProductDetails, setSelectedProductDetails] = useState<{
    id: string;
    imageLoc: string;
    name: string;
  }>();
  const [showAwardedProductDialog, setShowAwardedProductDialog] = useState(false);
  const [showError, setShowError] = useState(false);
  const [confirmButtonLoading, setConfirmButtonLoading] = useState(false);

  const handleClick = () => {
    singleSpa.navigateToUrl(`/s/evaluation/${initiativeId}/scorecard`);
  };
  const navigateToVendorsTab = () => {
    singleSpa.navigateToUrl(`/s/evaluation/${initiativeId}/products`);
  };
  const handleVendorSelect = (e: SelectChangeEvent) => {
    const productId = e.target.value;
    const productDetails = productsInEval?.find((product) => product.id === productId);
    setSelectedProductDetails({
      id: productDetails?.id as UUID,
      name: productDetails?.name as string,
      imageLoc: productDetails?.imageLoc as string,
    });
    setShowAwardedProductDialog(true);
  };

  const downloadSamplePDF = async () => {
    const url = "/assets/templates/buysmart_export_sample_summary.pdf";
    window.open(url);
  };

  const handleAwardProductSelection = async () => {
    setConfirmButtonLoading(true);
    const productId = selectedProductDetails?.id;
    const url = `/api/v3/initiatives/${initiativeId}/products/${productId}/action/award`;
    const res = await postAsyncRequest(url, {});
    if (res?.data && [200, 201, 204].indexOf(res?.status) > -1) {
      const showAnimation = localStorage.getItem(`showAnimationOnSelection-${initiativeId}`);
      if (showAnimation === null) {
        localStorage.setItem(`showAnimationOnSelection-${initiativeId}`, "true");
      }
      window.location.href = defaultToEmptyString(window.location.href);
    } else {
      setShowError(true);
      setConfirmButtonLoading(false);
    }
  };

  const handleCancelProductSelection = () => {
    setShowAwardedProductDialog(false);
  };

  return (
    <FeatureFlagBooleanContainer flagName={GCOM_3755__vendorSelections}>
      <FeatureFlagBooleanOn>
        <>
          <div className={styles.root}>
            <Box component="section" className={styles.content}>
              <TypographyComponent styling="h4" rootClassName={styles.heading}>
                {t("Select a vendor to gain access to your personalized Selection Summary")}
              </TypographyComponent>
              <TypographyComponent styling="p3" color="iron" rootClassName={styles.info1}>
                {t(
                  "The Selection Summary will provide a complete breakdown of your team’s evaluation activities as well as Gartner insight behind your selection."
                )}
              </TypographyComponent>
              {productsInEval?.length === 0 && (
                <>
                  <TypographyComponent styling="p3" color="iron" boldness="medium" rootClassName={styles.info2}>
                    {t("It looks like there are no active vendors in your eval.")}
                  </TypographyComponent>
                  <Button onClick={navigateToVendorsTab} rootClassName="btn-primary">
                    {t("GO TO VENDOR LIST")}
                  </Button>
                </>
              )}
              {productsInEval && productsInEval?.length > 0 && (
                <FormControl className={styles.dropdown}>
                  <InputLabel shrink={true}>{t("Select vendor")}</InputLabel>
                  <Select
                    fullWidth
                    label={t("Select vendor")}
                    aria-labelledby={"Select vendor"}
                    className={styles.dropdown}
                    notched={true}
                    onChange={(e) => handleVendorSelect(e as SelectChangeEvent<string>)}
                    value={""}
                  >
                    {productsInEval.map((product, index) => {
                      return (
                        <MenuItem key={index} value={product.id}>
                          <div className={styles.productItemContainer} data-product-id={product.id}>
                            <span className={styles.logoWrapper}>
                              <ProductLogo
                                rootClassName={styles.productLogo}
                                logo={product.imageLoc}
                                svgStyle={{ width: "30px" }}
                                name={"product-logo"}
                              />
                            </span>
                            <span>
                              <TypographyComponent styling="p3" boldness="medium">
                                {product.name}
                              </TypographyComponent>
                            </span>
                          </div>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
              <MiniButton isSvgAffected={false} rootClassName={styles.exportBtn} onClick={downloadSamplePDF}>
                <FileTypePdfIcon />
                <TypographyComponent styling="p3" color="defaultCta" boldness="medium">
                  {t("Export a sample summary")}
                </TypographyComponent>
              </MiniButton>
            </Box>
            <img
              alt={t("background triangle")}
              className={classNames(styles.imgWrap, styles.imgRectangle)}
              src={RectangleSVG}
            />
            <img
              alt={t("example report")}
              className={classNames(styles.imgWrap, styles.imgVendors)}
              src={VendorSvgGCOM_3755}
            />
          </div>
          <AwardProductDialog
            awardedProductName={""}
            awardedProductImageLoc={""}
            isLoading={confirmButtonLoading}
            isOpen={showAwardedProductDialog}
            onClose={handleCancelProductSelection}
            onConfirm={handleAwardProductSelection}
            selectionImageLoc={selectedProductDetails?.imageLoc as string}
            selectionName={selectedProductDetails?.name as string}
          />
          <SnackbarBanner
            isOpen={showError}
            isDefaultErrorMessage={true}
            setIsOpen={(val: boolean) => setShowError(val)}
          />
        </>
      </FeatureFlagBooleanOn>
      <FeatureFlagBooleanOff>
        <div className={styles.preSelectionRoot}>
          <Box borderRadius="8px" className={styles.preSelectBox} display="flex">
            <div className={styles.textSection}>
              <TypographyComponent type="h4" rootClassName={styles.padBot24}>
                {t("Choose a vendor to access your selection summary")}
              </TypographyComponent>
              <TypographyComponent type="p3" boldness="medium" rootClassName={styles.padBot16}>
                {t("When you are ready to make your selection, you can choose a vendor from their evaluation page.")}
              </TypographyComponent>
              <div className={styles.preselectCtaBtn}>
                <Button onClick={handleClick} rootClassName="btn-secondary">
                  {t("GO TO SCORECARD OVERVIEW")}
                </Button>
              </div>
              <TypographyComponent type="p3" boldness="medium">
                {t("In your selection summary, you will be able to:")}
              </TypographyComponent>
              <ul className={styles.descriptionList}>
                <li>
                  <TypographyComponent type="p3" rootClassName={styles.listItem}>
                    {t(
                      `Review your decision, supporting evidence, relevant Gartner research, and evaluation details all in one place.`
                    )}
                  </TypographyComponent>
                </li>
                <li>
                  <TypographyComponent type="p3" rootClassName={styles.listItem}>
                    {t("Go beyond the numbers with comments to describe the pros and cons in your own words.")}
                  </TypographyComponent>
                </li>
                <li>
                  <TypographyComponent type="p3" rootClassName={styles.listItem}>
                    {t("Access key information to share with your stakeholders and fast-track the approval process.")}
                  </TypographyComponent>
                </li>
              </ul>
            </div>
            <img
              alt={t("background triangle")}
              className={classNames(styles.imgWrap, styles.imgRectangle)}
              src={RectangleSVG}
            />
            <img alt={t("example report")} className={classNames(styles.imgWrap, styles.imgVendors)} src={VendorsSVG} />
          </Box>
        </div>
      </FeatureFlagBooleanOff>
    </FeatureFlagBooleanContainer>
  );
};

export default PreSelectionView;
