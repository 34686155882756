import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { InitStatus } from "gx-npm-lib";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  Header,
  Paragraph,
  TypographyComponent,
} from "gx-npm-ui";
import EvaluationPhase from "../../../../assets/images/evaluationPhase.svg";
import SelectionPhase from "../../../../assets/images/selectionPhase.svg";
import SurveyVendors from "../../../../assets/images/surveyVendors.svg";
import ScoreTeam from "../../../../assets/images/scoreTeam.svg";
import { upNextCardV2Styles as styles } from "./up-next-card-v2.styles";
import { GCOM_3606__fontUpdate } from "../../../../lib/feature-flags";
import classNames from "classnames";

const initStateMap: Record<
  string,
  {
    altImgText: string;
    illustration: string;
  }
> = {
  [InitStatus.EVAL_STARTED]: {
    altImgText: "selection report example",
    illustration: SelectionPhase,
  },
  [InitStatus.PLANNING]: {
    altImgText: "scorecard example",
    illustration: EvaluationPhase,
  },
};

type UpNextCardV2Props = {
  initStatus: InitStatus;
};

const useStyles = makeStyles(() => styles);
const UpNextCardV2 = ({ initStatus }: UpNextCardV2Props) => {
  const { t } = useTranslation();
  const { altImgText, illustration } = initStateMap[initStatus];
  const classes = useStyles();

  return (
    <section className={classes.container}>
      <div className={classes.columnWrapper}>
        <div className={classes.leftColumn}>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent rootClassName={classNames(classes.GCOM3606Title)} boldness={"semi"} styling={"h4"}>
                {initStatus === InitStatus.PLANNING && t("Up next — Evaluate your vendors")}
                {initStatus === InitStatus.EVAL_STARTED && t("Up next — Vendor selection")}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <Header rootClassName={classes.title} boldness="semi" type="h4">
                {initStatus === InitStatus.PLANNING && t("Up next — Evaluate your vendors")}
                {initStatus === InitStatus.EVAL_STARTED && t("Up next — Vendor selection")}
              </Header>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>

          <div>
            {initStatus === InitStatus.PLANNING && (
              <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                <FeatureFlagBooleanOn>
                  <TypographyComponent color={"iron"} styling={"p3"}>
                    {t("Evaluate vendors using a questionnaire and/or scorecard.")}
                  </TypographyComponent>
                </FeatureFlagBooleanOn>
                <FeatureFlagBooleanOff>
                  <Paragraph rootClassName={classes.descriptionText} type="p3">
                    {t("Evaluate vendors using a questionnaire and/or scorecard.")}
                  </Paragraph>
                </FeatureFlagBooleanOff>
              </FeatureFlagBooleanContainer>
            )}
            {initStatus === InitStatus.EVAL_STARTED && (
              <ul className={classes.GCOM3606PhaseHighlights}>
                <li>
                  <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                    <FeatureFlagBooleanOn>
                      <TypographyComponent rootClassName={classes.phaseHighlightsList} color={"coal"} styling={"p4"}>
                        {t(
                          "Manage your vendor evaluation with a custom scorecard built from your requirements, and scores unique to each vendor."
                        )}
                      </TypographyComponent>
                    </FeatureFlagBooleanOn>
                    <FeatureFlagBooleanOff>
                      <Paragraph type="p4">
                        {t(
                          "Manage your vendor evaluation with a custom scorecard built from your requirements, and scores unique to each vendor."
                        )}
                      </Paragraph>
                    </FeatureFlagBooleanOff>
                  </FeatureFlagBooleanContainer>
                </li>
                <li>
                  <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                    <FeatureFlagBooleanOn>
                      <TypographyComponent color={"coal"} rootClassName={classes.phaseHighlightsList} styling={"p4"}>
                        {t("Invite your team to collaborate on the scorecard and share their feedback.")}
                      </TypographyComponent>
                    </FeatureFlagBooleanOn>
                    <FeatureFlagBooleanOff>
                      <Paragraph type="p4">
                        {t("Invite your team to collaborate on the scorecard and share their feedback.")}
                      </Paragraph>
                    </FeatureFlagBooleanOff>
                  </FeatureFlagBooleanContainer>
                </li>
              </ul>
            )}
          </div>
        </div>
        {initStatus === InitStatus.PLANNING && (
          <div className={classes.cards}>
            <div className={classes.card1}>
              <img alt="" src={SurveyVendors} />
              <div className={classes.cardDescriptionWrapper}>
                <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                  <FeatureFlagBooleanOn>
                    <TypographyComponent
                      boldness={"semi"}
                      styling={"p2"}
                      rootClassName={classes.GCOM3606CardDescription}
                    >
                      {t("Vendor questionnaires")}
                    </TypographyComponent>
                    <TypographyComponent rootClassName={classes.GCOM3606CardDescription} styling="p3">
                      {t(
                        "Request information from vendors and view their responses to evaluate vendors inside the application."
                      )}
                    </TypographyComponent>
                  </FeatureFlagBooleanOn>
                  <FeatureFlagBooleanOff>
                    <Paragraph boldness="semi" type="p2">
                      {t("Vendor questionnaires")}
                    </Paragraph>
                    <Paragraph rootClassName={classes.cardDescription} type="p3">
                      {t(
                        "Request information from vendors and view their responses to evaluate vendors inside the application."
                      )}
                    </Paragraph>
                  </FeatureFlagBooleanOff>
                </FeatureFlagBooleanContainer>
              </div>
            </div>
            <div className={classes.card2}>
              <img alt="" src={ScoreTeam} />

              <div className={classes.cardDescriptionWrapper}>
                <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                  <FeatureFlagBooleanOn>
                    <TypographyComponent boldness={"semi"} styling={"p2"}>
                      {t("Score with your team")}
                    </TypographyComponent>
                    <TypographyComponent rootClassName={classes.GCOM3606CardDescription} styling="p3">
                      {t(
                        "Manage your evaluation with a custom scorecard built from your requirements. Collaborate with your team to compare and score each vendor."
                      )}
                    </TypographyComponent>
                  </FeatureFlagBooleanOn>
                  <FeatureFlagBooleanOff>
                    <Paragraph boldness="semi" type="p2">
                      {t("Score with your team")}
                    </Paragraph>
                    <Paragraph rootClassName={classes.cardDescription} type="p3">
                      {t(
                        "Manage your evaluation with a custom scorecard built from your requirements. Collaborate with your team to compare and score each vendor."
                      )}
                    </Paragraph>
                  </FeatureFlagBooleanOff>
                </FeatureFlagBooleanContainer>
              </div>
            </div>
          </div>
        )}
        {initStatus !== InitStatus.PLANNING && (
          <img alt={altImgText} className={classes.evalStartedImage} src={illustration} />
        )}
      </div>
    </section>
  );
};
export default UpNextCardV2;
