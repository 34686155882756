import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { styles } from "./requirements-info-bar.styles";
import classNames from "classnames";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  MiniButton,
  TooltipV2,
  TypographyComponent,
} from "gx-npm-ui";
import { AdditionCircledIcon } from "gx-npm-icons";
import { useTranslation } from "react-i18next";
import ExportReqButton from "./export-req-button.component";
import ImportReqButtons from "./import-req-button.component";
import { GCOM_3606__fontUpdate } from "../../../lib/feature-flags";

const propTypes = {
  catCount: PropTypes.number,
  reqCount: PropTypes.number,
  isViewOnly: PropTypes.bool,
  addFromLibraryBtnClick: PropTypes.func,
  isProcessing: PropTypes.bool,
  isLoaded: PropTypes.bool,
  onLibraryDrawerOpen: PropTypes.func,
  initiativeId: PropTypes.string,
};

const useStyles = makeStyles(() => styles);

const RequirementsInfoBar = ({
  catCount,
  reqCount,
  isViewOnly,
  addFromLibraryBtnClick,
  isProcessing = false,
  isLoaded = false,
  onLibraryDrawerOpen = () => {},
  initiativeId = "",
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const disabled = isProcessing || !isLoaded;
  return (
    <div className={classNames(classes.infoBar, disabled && "gx-info-bar-disabled")}>
      <div className={classes.infoBarLeft}>
        <div className={classes.infoBarLeftItem}>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent styling={"p3"} rootClassName={classNames(classes.infoBarItemText)}>
                <TypographyComponent element={"span"}> {catCount}</TypographyComponent> {t("categories")}
                <TypographyComponent element={"span"} rootClassName={classNames(classes.requirementsSpanMargin)}>
                  {reqCount}
                </TypographyComponent>{" "}
                {t("requirements")}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <p className={classNames("p3", classes.infoBarItemText)}>
                <span> {catCount}</span> {t("categories")}
                <span className={classNames(classes.requirementsSpanMargin)}>{reqCount}</span> {t("requirements")}
              </p>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </div>
      </div>
      <div className={classes.infoBarRight}>
        <div className={classes.infoBarRightItem}>
          <TooltipV2
            deactivate={!isViewOnly}
            placement="top"
            PopperProps={{ modifiers: { offset: { offset: "0, 12" } } }}
            title={t("This action is only available to evaluation owners and contributors.")}
          >
            <div>
              <MiniButton
                rootClassName={classNames("gx-add-from-req-library-button", "gx-intercom-add-from-req-library-button")}
                onClick={addFromLibraryBtnClick}
                variant="default"
                disabled={isViewOnly || disabled}
              >
                <AdditionCircledIcon />
                {t("Add from library")}
              </MiniButton>
            </div>
          </TooltipV2>
        </div>
        <div className={classes.infoBarRightItem}>
          <div className={classes.infoBarRightItemVerticalLine} />
        </div>
        <ImportReqButtons
          disabled={isViewOnly || disabled}
          isViewOnly={isViewOnly}
          onLibraryDrawerOpen={onLibraryDrawerOpen}
        />
        <div className={classes.exportImportButtonsVerticalLine} />
        <ExportReqButton disabled={isViewOnly || disabled} initiativeId={initiativeId} isViewOnly={isViewOnly} />
      </div>
    </div>
  );
};

RequirementsInfoBar.propTypes = propTypes;

export default RequirementsInfoBar;
