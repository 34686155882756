import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Collapse, makeStyles } from "@material-ui/core";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  MiniButton,
  Paragraph,
  TooltipV2,
  TypographyComponent,
} from "gx-npm-ui";
import EditCategoryWeightDialog from "../../lists/components/categoryWeightDialog";
import ExclamationErrorIcon from "./exclamation-error.icon";
import styles from "./reqs-pre-publish-inline-alert.styles";
import PencilEditIcon from "./pencil-edit.icon";
import { GCOM_3606__fontUpdate } from "../../../../lib/feature-flags";

const propTypes = { initId: PropTypes.string, isShown: PropTypes.bool, isViewOnly: PropTypes.bool };
const useStyles = makeStyles(() => styles);
const ReqsPrePublishInlineAlert = ({ initId = "", isShown = false, isViewOnly = false }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <Fragment>
      <Collapse className={classes.collapseContainer} in={isShown}>
        <div className={classes.alertContainer}>
          <ExclamationErrorIcon />
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent boldness={"medium"} rootClassName={"gx-inline-alert-message"} styling={"p3"}>
                {t("Total category weights must equal 100%.")}
                {` `}
                {t("You will not be able to start the team scorecard until this has been updated.")}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <Paragraph boldness="medium" rootClassName="gx-inline-alert-message" type="p3">
                {t("Total category weights must equal 100%.")}
                {` `}
                {t("You will not be able to start the team scorecard until this has been updated.")}
              </Paragraph>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
          <div className={classes.buttonContainer}>
            <TooltipV2
              deactivate={!isViewOnly}
              placement="top"
              PopperProps={{ modifiers: { offset: { offset: "0, 12px, 264px" } } }}
              title={t("This action is only available to evaluation owners and contributors.")}
            >
              <div>
                <MiniButton
                  disabled={isViewOnly}
                  onClick={() => setIsDialogOpen(true)}
                  rootClassName={classNames("btn-tertiary ")}
                >
                  <PencilEditIcon />
                  {t("Edit category weights")}
                </MiniButton>
              </div>
            </TooltipV2>
          </div>
        </div>
      </Collapse>
      <EditCategoryWeightDialog initiativeId={initId} isOpen={isDialogOpen} onClick={() => setIsDialogOpen(false)} />
    </Fragment>
  );
};

ReqsPrePublishInlineAlert.propTypes = propTypes;
export default ReqsPrePublishInlineAlert;
