import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { handleEvent } from "gx-npm-lib";
import { Button, useFeatureFlag } from "gx-npm-ui";
import { AdditionIcon } from "gx-npm-icons";
import { listButtenAddStyles as styles } from "./styles";
import { colorPalette } from "gx-npm-common-styles";
import { GCOM_3695_ctaButtonColorUpdate } from "../../../lib/feature-flags";

const propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onClick: PropTypes.func,
  rootClassName: PropTypes.string,
};

const useStyles = makeStyles(() => styles);
const ListFooterButtonAdd = ({ disabled = false, label = "", onClick = null, rootClassName = "" }) => {
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);
  const classes = useStyles();
  return (
    <Button
      color="primary"
      disabled={disabled}
      onClick={(e) => handleEvent(onClick, e)}
      rootClassName={classNames("btn-tertiary", "gx-add-btn", rootClassName)}
    >
      <span className={classNames(classes.btnRoot, disabled && "disabled")}>
        <AdditionIcon {...(isFFGCOM3695 && { fillPath: colorPalette.interactions.defaultCta.hex })} />
        <span className={classNames("gx-btn-label", classes.btnLabel)}>{label}</span>
      </span>
    </Button>
  );
};

ListFooterButtonAdd.propTypes = propTypes;
export default ListFooterButtonAdd;
