import classNames from "classnames";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TextLink,
  TypographyComponent,
} from "gx-npm-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { MarketGuideIcon } from "gx-npm-icons";
import classes from "./market-guide-body.styles.module.scss";
import { GCOM_3476_3523__researchRibbonUpdate } from "../../../feature-flags";
import { colorPalette } from "gx-npm-common-styles";

const MarketGuideBody = ({ templateName = "", documentQuery = -1 }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(classes.contentBodyMidSectionLeft)}>
      <h5 className={classNames(classes.contentBodyMidSectionTitleText)}>
        <MarketGuideIcon fillPath={colorPalette.interactions.berry.hex} />
        {t("Market Guide")}
      </h5>
      <FeatureFlagBooleanContainer flagName={GCOM_3476_3523__researchRibbonUpdate}>
        <FeatureFlagBooleanOff>
          <p className={classNames(classes.contentBodyMidSectionDescText)}>
            {t(
              "Market Guides (MGs) deliver a clear definition and framing of the market, market dynamics and an overview of market participants. They deliver leaders who are considering an investment with actionable insights on use cases spurring the market, offerings and market direction."
            )}
          </p>
        </FeatureFlagBooleanOff>
        <FeatureFlagBooleanOn>
          <TypographyComponent
            rootClassName={classes.contentBodyMidSectionDescTextGCOM3523}
            color="quartz"
            styling="p2"
          >
            {t(
              "Market Guides (MGs) deliver a clear definition and framing of the market, market dynamics and an overview of market participants. They deliver leaders who are considering an investment with actionable insights on use cases spurring the market, offerings and market direction."
            )}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
      </FeatureFlagBooleanContainer>

      <div className={classNames(classes.contentLearnMoreLine)} />
      <p className={classNames(classes.contentBodyMidSectionLearnMore)}>{t("Learn more")}</p>
      <div className={classNames(classes.contentLink)}>
        <TextLink
          rootClassName={classNames(classes.contentBodyMidSectionLinkText)}
          href={`https://www.gartner.com/document/${documentQuery}`}
          target="_blank"
          text={t(`Market Guide for ${templateName}`)}
          variant={"dark"}
        />
      </div>
    </div>
  );
};

export default MarketGuideBody;
