import { FC, Fragment, useRef, useState } from "react";
import { RichTooltip, TypographyComponent } from "gx-npm-ui";
import { SvgIcon as MuiSvgIcon, SvgIconProps as MuiSvgIconProps } from "@mui/material";
import classNames from "classnames";
import styles from "./proposal-review-badge.styles.module.scss";
import { useTranslation } from "react-i18next";

// TODO: use shared icon
const ProposalReviewIcon: FC<MuiSvgIconProps> = (props) => (
  <MuiSvgIcon {...props}>
    <path d="M2.53488 3.34168C2.53488 2.8961 2.8961 2.53488 3.34168 2.53488H20.6583C21.1039 2.53488 21.4651 2.8961 21.4651 3.34168V10.4258C21.4651 10.8496 21.8087 11.1932 22.2326 11.1932C22.6564 11.1932 23 10.8496 23 10.4258V3.34168C23 2.04841 21.9516 1 20.6583 1H3.34168C2.04841 1 1 2.04841 1 3.34168V20.6583C1 21.9516 2.04841 23 3.34168 23H10.4258C10.8496 23 11.1932 22.6564 11.1932 22.2326C11.1932 21.8087 10.8496 21.4651 10.4258 21.4651H3.34168C2.8961 21.4651 2.53488 21.1039 2.53488 20.6583V3.34168Z" />
    <path d="M4.93614 7.27743C4.93614 6.85359 5.27973 6.50999 5.70358 6.50999H18.2975C18.7213 6.50999 19.0649 6.85359 19.0649 7.27743C19.0649 7.70128 18.7213 8.04488 18.2975 8.04488H5.70358C5.27973 8.04488 4.93614 7.70128 4.93614 7.27743Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3292 11.2326C13.5144 11.2326 11.2326 13.5144 11.2326 16.3292C11.2326 19.1439 13.5144 21.4258 16.3292 21.4258C17.4598 21.4258 18.5045 21.0576 19.3498 20.4346L21.69 22.7748C21.9897 23.0745 22.4756 23.0745 22.7753 22.7748C23.075 22.4751 23.075 21.9892 22.7753 21.6895L20.435 19.3492C21.0578 18.504 21.4258 17.4595 21.4258 16.3292C21.4258 13.5144 19.1439 11.2326 16.3292 11.2326ZM12.7674 16.3292C12.7674 14.3621 14.3621 12.7674 16.3292 12.7674C18.2962 12.7674 19.8909 14.3621 19.8909 16.3292C19.8909 18.2962 18.2962 19.8909 16.3292 19.8909C14.3621 19.8909 12.7674 18.2962 12.7674 16.3292Z"
    />
    <path d="M5.70358 11.2326C5.27973 11.2326 4.93614 11.5762 4.93614 12C4.93614 12.4238 5.27973 12.7674 5.70358 12.7674H9.63918C10.063 12.7674 10.4066 12.4238 10.4066 12C10.4066 11.5762 10.063 11.2326 9.63918 11.2326H5.70358Z" />
    <path d="M4.93614 16.7226C4.93614 16.2987 5.27973 15.9551 5.70358 15.9551H8.06494C8.48879 15.9551 8.83238 16.2987 8.83238 16.7226C8.83238 17.1464 8.48879 17.49 8.06494 17.49H5.70358C5.27973 17.49 4.93614 17.1464 4.93614 16.7226Z" />
  </MuiSvgIcon>
);

const ProposalReviewBadgeComponent: FC<{ rootClassName?: string; isRowHighlighted?: boolean }> = ({
  rootClassName = "",
  isRowHighlighted = false,
}) => {
  const { t } = useTranslation();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const tooltipRef = useRef<HTMLButtonElement>(null);
  const handleTooltipClose = () => setIsTooltipOpen(false);
  const handleTooltipOpen = () => {
    setIsTooltipOpen(!isTooltipOpen);
  };
  return (
    <Fragment>
      <button
        className={classNames(rootClassName, styles.root, isRowHighlighted && styles.rowHighlight)}
        ref={tooltipRef}
        onClick={handleTooltipOpen}
        onKeyDown={handleTooltipOpen}
        aria-label="tooltip-trigger-btn"
      >
        <RichTooltip
          rootClassName={styles.richTooltipContainer}
          theme={"light"}
          open={isTooltipOpen}
          anchor={tooltipRef.current}
          onClose={handleTooltipClose}
          popperPlacement={"right"}
          title={t("Gartner Proposal Review")}
          content={[
            {
              type: "PARAGRAPH",
              content: t(
                "A Proposal Review is a 30-minute session between you and a Gartner Expert to review a vendor's proposal prior to signature."
              ),
            },
          ]}
          footerLinkText={t("Learn more")}
          footerLinkUrl={
            "https://intercom.help/gartner-buysmart-help-center/en/articles/9971906-proposal-review-coming-soon"
          }
        >
          <ProposalReviewIcon className={styles.iconContainer} />
          <TypographyComponent boldness={"medium"} rootClassName={styles.textContainer} styling={"p5"}>
            {t("Proposal Review available")}
          </TypographyComponent>
        </RichTooltip>
      </button>
    </Fragment>
  );
};

export default ProposalReviewBadgeComponent;
