import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core";
import { scoringTitleStyles as styles } from "./styles";
import { useTranslation } from "react-i18next";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { GCOM_3606__fontUpdate } from "../../../../../lib/feature-flags";

const useStyles = makeStyles(() => styles);
const ScoringLevelTitle = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Fragment>
      <TypographyComponent styling="h3" boldness="medium">
        {t("Set the scoring level")}
      </TypographyComponent>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent styling={"p4"} color={"iron"} rootClassName={classes.descriptionGCOM3606}>
            {t(
              "For quicker evaluations, score at the category level. For more in-depth evaluations, score at the requirement level. You’ll have the opportunity to change this once you’ve started the evaluation."
            )}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <TypographyComponent styling="p4" color="iron" rootClassName={classes.description}>
            {t(
              "For quicker evaluations, score at the category level. For more in-depth evaluations, score at the requirement level. You’ll have the opportunity to change this once you’ve started the evaluation."
            )}
          </TypographyComponent>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
    </Fragment>
  );
};

export default ScoringLevelTitle;
