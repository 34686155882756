import React, { Fragment } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Avatar, makeStyles } from "@material-ui/core";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  Paragraph,
  TooltipV2,
  TypographyComponent,
  UsernameDisplay,
} from "gx-npm-ui";
import styles from "./delete-req-details-dialog-evaluators-list.styles";
import { GCOM_3606__fontUpdate } from "../../../../../lib/feature-flags";

const propTypes = { evaluators: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })) };
const useStyles = makeStyles(() => styles);
const DeleteReqDetailsDialogEvaluatorsList = ({ evaluators = [] }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.listedItems}>
      {!!evaluators.length && (
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent boldness={"medium"} rootClassName={"gx-list-header-paragraph"} styling={"p4"}>
              {t("Team members who have scored")}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <Paragraph boldness="medium" rootClassName="gx-list-header-paragraph" type="p4">
              {t("Team members who have scored")}
            </Paragraph>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      )}
      {evaluators.slice(0, 3).map((evaluator) => (
        <div key={evaluator.name} className={classes.evaluatorContainer}>
          <TooltipV2 PopperProps={{ modifiers: { offset: { offset: "0, 8" } } }} title={evaluator.name}>
            <div>
              <UsernameDisplay isColorInvertedOnHover={true} showName={false} name={evaluator.name} />
            </div>
          </TooltipV2>
        </div>
      ))}
      {evaluators.length > 3 && (
        <div className={classNames(classes.evaluatorContainer, classes.extraEvaluators)}>
          <TooltipV2
            PopperProps={{ modifiers: { offset: { offset: "0, 8" } } }}
            title={evaluators.slice(3).map((extraEval, extraEvalIndex) => (
              <Fragment key={extraEval.name}>
                <span>
                  {extraEval.name}
                  {evaluators.length - 4 !== extraEvalIndex && `,`}
                  <br />
                </span>
              </Fragment>
            ))}
          >
            <Avatar>{`+${evaluators.length - 3}`}</Avatar>
          </TooltipV2>
        </div>
      )}
    </div>
  );
};

DeleteReqDetailsDialogEvaluatorsList.propTypes = propTypes;
export default DeleteReqDetailsDialogEvaluatorsList;
