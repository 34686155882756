import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { InitUserRole } from "gx-npm-lib";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TooltipV2,
  TypographyComponent,
  UsernameDisplay,
} from "gx-npm-ui";
import styles from "./team-members-v2.styles";
import { TeamMemberType } from "../../overview.types";
import { GCOM_3606__fontUpdate } from "../../../../lib/feature-flags";

const maxMemberDisplay = 3;
type TeamMembersV2Props = {
  memberList: TeamMemberType[];
};

const useStyles = makeStyles(() => styles);
const TeamMembersV2 = ({ memberList = [] }: TeamMembersV2Props) => {
  const firstOwner = memberList.find((member) => member.role === InitUserRole.OWNER);
  const otherMembers = memberList.filter((member) => firstOwner && member.email !== firstOwner.email);
  const otherMemberCount = otherMembers.length;
  const additionalMemberCount = otherMemberCount - maxMemberDisplay;
  const displayTeamRow = otherMemberCount > 2;
  const additionalMembersTooTipText = otherMembers.slice(maxMemberDisplay).map((member) => (
    <span key={member.email}>
      {member.hasViewed ? member.fullName : member.email}
      <br />
    </span>
  ));
  const classes = useStyles();
  return (
    <div className={classNames(classes.memberAvatarRoot)}>
      {!!firstOwner && <UsernameDisplay name={firstOwner.fullName} />}
      {otherMemberCount > 0 && (
        <div className={classNames(classes.otherMembers, displayTeamRow && classes.otherMembersRow)}>
          {otherMembers.slice(0, maxMemberDisplay).map((member) => (
            <TooltipV2
              key={member.email}
              PopperProps={{ modifiers: { offset: { offset: otherMemberCount > 2 ? "5, 10" : "-107, 7" } } }}
              rootClassName={classNames(otherMemberCount < 3 && classes.nameTooltipArrow)}
              title={member.fullName ? member.fullName : member.email}
            >
              <div>
                <UsernameDisplay
                  isUnknown={!member.hasViewed}
                  name={!member?.hasViewed ? "Pending" : member.fullName}
                  rootClassName={"gx-scoring-members-username-display"}
                  showName={!displayTeamRow}
                />
              </div>
            </TooltipV2>
          ))}
          {additionalMemberCount > 0 && (
            <TooltipV2 PopperProps={{ modifiers: { offset: { offset: "5, 10" } } }} title={additionalMembersTooTipText}>
              <div>
                <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                  <FeatureFlagBooleanOn>
                    <TypographyComponent
                      rootClassName={classNames(classes.additionalMembersBadge)}
                      styling={"p1"}
                      boldness={"medium"}
                    >{`+${additionalMemberCount}`}</TypographyComponent>
                  </FeatureFlagBooleanOn>
                  <FeatureFlagBooleanOff>
                    <p
                      className={classNames("p1", "medium-bold", classes.additionalMembersBadge)}
                    >{`+${additionalMemberCount}`}</p>
                  </FeatureFlagBooleanOff>
                </FeatureFlagBooleanContainer>
              </div>
            </TooltipV2>
          )}
        </div>
      )}
    </div>
  );
};

export default TeamMembersV2;
