import React from "react";
import PropTypes from "prop-types";
import { makeStyles, FormControlLabel, Radio } from "@material-ui/core";
import { handleEvent } from "gx-npm-lib";
import { scoringOptionStyles as styles } from "./styles";
import { useTranslation } from "react-i18next";
import { TypographyComponent, useFeatureFlag } from "gx-npm-ui";
import { GCOM_3695_ctaButtonColorUpdate } from "../../../../../lib/feature-flags";

const propTypes = {
  count: PropTypes.string,
  isCurrent: PropTypes.bool,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
  textDesc: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  textTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};
const useStyles = makeStyles(() => styles);
const ScoringLevelOption = ({
  count = "",
  isCurrent = false,
  isSelected = false,
  onSelect = null,
  textDesc = "",
  textTitle = "",
}) => {
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);

  const handleChange = () => {
    handleEvent(onSelect);
  };
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <FormControlLabel
        className={classes.formControlLabel}
        control={
          <Radio
            checked={isSelected}
            className={isFFGCOM3695 && classes.radioColorGCOM3695}
            color="primary"
            name={textTitle}
            onChange={handleChange}
          />
        }
        label={
          <TypographyComponent styling="p3" boldness="medium">
            {textTitle}
            {isCurrent && (
              <span className={classes.iron}>
                {` `}
                {t("(current)")}
              </span>
            )}
          </TypographyComponent>
        }
      />
      <div className={classes.description} data-testid="items-to-score">
        <TypographyComponent styling="p4" color="iron">
          {textDesc}
        </TypographyComponent>
        <TypographyComponent styling="p3" rootClassName={classes.itemsCountInfo}>
          <span>{count || `0`}</span>
          {` `}
          {t("items to score")}
        </TypographyComponent>
      </div>
    </div>
  );
};

ScoringLevelOption.propTypes = propTypes;
export default ScoringLevelOption;
