import React, { useContext, useState } from "react";
import classNames from "classnames";
import styles from "./evaluation-comment.module.scss";
import { TextField, useFeatureFlag } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import { postAsyncRequest, putAsyncRequest, UUID } from "gx-npm-lib";
import { SelectionContext } from "../../../../selection.context";
import { Comment } from "../../../../selection.types";
import { CommentType } from "../../../../../../app.constants";
import { GCOM_3695_ctaButtonColorUpdate } from "../../../../../../lib/feature-flags";

type EvaluationCommentProps = { comment: Comment; isViewOnly: boolean };

const EvaluationComment = ({ comment, isViewOnly }: EvaluationCommentProps) => {
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);
  const [value, setValue] = useState(comment);
  const { t } = useTranslation();
  const { initiativeId, awardedProduct, setSelectionEditCount, setRequirementComment } = useContext(SelectionContext);

  const handleCommentUpdate = async (commentValue: {
    id: UUID;
    description: string;
    commentType: CommentType;
    parentId: UUID;
  }) => {
    const endpoint = `api/v3/initiatives/${initiativeId}/products/${awardedProduct?.id}/comments/${commentValue.commentType}/${commentValue.id}`;
    let requestBody;

    if (commentValue.commentType === CommentType.EVAL_SUMMARY) {
      requestBody = { description: commentValue.description };
    }

    if (commentValue.commentType === CommentType.REQUIREMENT_SUMMARY) {
      requestBody = { description: commentValue.description, parentId: commentValue.parentId };
    }

    setSelectionEditCount(1);
    setRequirementComment({
      description: value.description,
      id: commentValue.id,
      parentId: commentValue.parentId,
      commentType: CommentType.REQUIREMENT_SUMMARY,
    });

    const response = await putAsyncRequest(endpoint, requestBody);

    if (response.status !== 200) {
      console.error("Error updating comment", response);
    }

    setSelectionEditCount(-1);
  };

  const handleCommentCreate = async (commentValue: {
    description: string;
    commentType: CommentType;
    parentId: UUID;
  }) => {
    const endpoint = `api/v3/initiatives/${initiativeId}/products/${awardedProduct?.id}/comments/${commentValue.commentType}`;
    let requestBody;

    if (commentValue.commentType === CommentType.EVAL_SUMMARY) {
      requestBody = { description: commentValue.description };
    }

    if (commentValue.commentType === CommentType.REQUIREMENT_SUMMARY) {
      requestBody = { description: commentValue.description, parentId: commentValue.parentId };
    }

    setSelectionEditCount(1);

    const response = await postAsyncRequest(endpoint, requestBody);

    if (response.status === 201) {
      setValue({ ...value, id: response.data.data.id });
    } else {
      console.error("Error creating comment", response);
    }
    setSelectionEditCount(-1);
  };

  const handleCommentChange = async (commentValue: {
    id: UUID;
    description: string;
    commentType: CommentType;
    parentId: UUID;
  }) => {
    if (value.id) {
      //update comment
      await handleCommentUpdate({
        id: value.id,
        description: commentValue.description,
        commentType: commentValue.commentType,
        parentId: commentValue.parentId,
      });
    } else {
      //create comment if record does not exist and comment is added
      if (commentValue.description) {
        await handleCommentCreate({
          description: commentValue.description,
          commentType: commentValue.commentType,
          parentId: commentValue.parentId,
        });
      }
    }
  };

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (event && event.target) {
      const description = (event.target as HTMLInputElement).value;
      setValue({ ...value, description });
    }
  };

  const handleBlur = async (_event: FocusEvent) => {
    //call change method only when comment is modified
    if (value.description !== comment.description) {
      await handleCommentChange({
        id: comment.id,
        description: value.description,
        commentType: value.commentType,
        parentId: comment.parentId,
      });
    }
  };

  return (
    <TextField
      disabled={isViewOnly}
      fullWidth
      multiline
      multilinev2={true}
      onBlur={handleBlur}
      onChange={handleChange}
      placeholder={t("Add comments...")}
      rootClassName={classNames(
        styles.root,
        isFFGCOM3695 && styles.rootGCOM3695,
        styles.inlineTextRoot,
        isViewOnly && styles.viewOnly
      )}
      value={value.description}
    />
  );
};

export default EvaluationComment;
