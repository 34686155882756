import { useContext } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TooltipV2,
  TypographyComponent,
} from "gx-npm-ui";
import styles from "./evaluation-summary.module.scss";
import EvaluationComment from "../evaluation-comment/evaluation-comment.component";
import { SelectionContext } from "../../../../selection.context";
import { InitUserRole, useUserInitAccess } from "gx-npm-lib";
import EvalSummaryTableV2Component from "../../../../../../ui/eval-summary-table-v2/eval-summary-table-v2.component";
import { GCOM_3606__fontUpdate } from "../../../../../../lib/feature-flags";
import { CommentType } from "../../../../../../app.constants";

const EvaluationSummary = () => {
  const { t } = useTranslation();
  const { evalComment, initiativeId, products } = useContext(SelectionContext);
  const { role } = useUserInitAccess(initiativeId);
  const isViewOnly = role !== InitUserRole.OWNER;
  evalComment.commentType = CommentType.EVAL_SUMMARY;
  return (
    <div className={classNames(styles.root)}>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent styling={"p1"} boldness={"semi"}>
            {t("Evaluation summary")}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <TypographyComponent type="p1" boldness="semi">
            {t("Evaluation summary")}
          </TypographyComponent>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
      <TooltipV2
        deactivate={!isViewOnly}
        placement="left"
        PopperProps={{ modifiers: { offset: { offset: "0, -500px" } } }}
        title={t("This action is only available to the evaluation owners.")}
      >
        <div className={classNames(styles.comments)}>
          <EvaluationComment isViewOnly={isViewOnly} comment={evalComment} />
        </div>
      </TooltipV2>
      <EvalSummaryTableV2Component initId={initiativeId} products={products} sourcePage="selection" />
    </div>
  );
};
export default EvaluationSummary;
