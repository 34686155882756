import { StarSmallIcon } from "gx-npm-icons";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TooltipV2,
  TypographyComponent,
} from "gx-npm-ui";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import styles from "./peer-insights-cell.styles.module.scss";
import { GCOM_3606__fontUpdate } from "../../../../../../../lib/feature-flags";

const PeerInsightsCellComponent = ({ rating = 0, reviewCount = 0 }) => {
  const { t } = useTranslation();
  const hasReviews = reviewCount > 0;
  return (
    <div className={styles.root}>
      <TooltipV2
        deactivate={hasReviews}
        placement="top"
        PopperProps={{ modifiers: { offset: { offset: "0, 12px, 264px" } } }}
        title={t("There are no user reviews for this product in this market, so we are unable to provide a rating.")}
      >
        <div aria-label={hasReviews ? "peer review rating" : "no peer reviews"} className={styles.piRating}>
          {hasReviews && (
            <Fragment>
              <StarSmallIcon />
              <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                <FeatureFlagBooleanOn>
                  <TypographyComponent boldness={"semi"} styling={"p1"}>
                    {(Math.floor(rating * 10) / 10 || 0).toFixed(1)}
                  </TypographyComponent>
                  <TypographyComponent boldness={"regular"} rootClassName={styles.piDenominator} styling={"p4"}>
                    {" / 5"}
                  </TypographyComponent>
                </FeatureFlagBooleanOn>
                <FeatureFlagBooleanOff>
                  <TypographyComponent boldness="semi" type="p1">
                    {(Math.floor(rating * 10) / 10 || 0).toFixed(1)}
                  </TypographyComponent>
                  <TypographyComponent boldness="regular" rootClassName={styles.piDenominator} type="p4">
                    {" / 5"}
                  </TypographyComponent>
                </FeatureFlagBooleanOff>
              </FeatureFlagBooleanContainer>
            </Fragment>
          )}
          {!hasReviews && (
            <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
              <FeatureFlagBooleanOn>
                <TypographyComponent boldness={"semi"} rootClassName={styles.piEmpty} styling={"p4"}>
                  {t("No rating")}
                </TypographyComponent>
              </FeatureFlagBooleanOn>
              <FeatureFlagBooleanOff>
                <TypographyComponent boldness="semi" rootClassName={styles.piEmpty} type="p4">
                  {t("No rating")}
                </TypographyComponent>
              </FeatureFlagBooleanOff>
            </FeatureFlagBooleanContainer>
          )}
        </div>
      </TooltipV2>
      <div aria-label="peer review count">
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent boldness={"medium"} color={"iron"} styling={"p4"}>
              {`${reviewCount || "0"} ${t("reviews")}`}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <TypographyComponent boldness="medium" rootClassName={styles.piReview} type="p4">
              {`${reviewCount || "0"} ${t("reviews")}`}
            </TypographyComponent>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      </div>
    </div>
  );
};

export default PeerInsightsCellComponent;
