import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./animation-selection-view.styles";
import classNames from "classnames";
import { Player } from "@lottiefiles/react-lottie-player";
import selectionAnimation from "../../../../assets/lottie/selectionAnimation.json";
import React from "react";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { GCOM_3606__fontUpdate } from "../../../../lib/feature-flags";

const useStyles = makeStyles(() => styles);

type AnimationSelectionViewProps = { onComplete: () => void };
const AnimationSelectionView = ({ onComplete }: AnimationSelectionViewProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleEvent = (event: string) => {
    if (event === "complete") {
      setInterval(onComplete, 1000);
    }
  };

  return (
    <div>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent rootClassName={classNames(classes.animationCopy)}>
            {t("We're gathering your selection summary ...")}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <div className={classNames(classes.animationCopy)}>{t("We're gathering your selection summary ...")}</div>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
      <Player
        autoplay={true}
        onEvent={handleEvent}
        src={selectionAnimation}
        style={{ height: "437px", width: "349px" }}
      />
    </div>
  );
};

export default AnimationSelectionView;
