import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { ScorecardProgress } from "gx-npm-lib";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
  UsernameDisplay,
} from "gx-npm-ui";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./evaluation-popper-view-row.style";
import { GCOM_3606__fontUpdate } from "../../../../../lib/feature-flags";

const propTypes = {
  isOptedOut: PropTypes.bool,
  isProgressPopper: PropTypes.bool,
  isScored: PropTypes.bool,
  name: PropTypes.string,
  progress: PropTypes.string,
  score: PropTypes.number,
  scoreDisplay: PropTypes.string,
};
const useStyles = makeStyles(() => styles);
const EvaluationPopperViewRow = ({
  isOptedOut = false,
  isProgressPopper = false,
  isScored = false,
  name = "",
  progress = "",
  score = 0,
  scoreDisplay = "",
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  let className = "";
  let display = "";
  if (isProgressPopper) {
    if (progress === ScorecardProgress.NOT_STARTED) {
      display = t("Not started");
      className = classes.notStarted;
    } else if (progress === ScorecardProgress.IN_PROGRESS) {
      display = t("In progress");
    } else if (progress === ScorecardProgress.COMPLETE) {
      display = t("Complete");
      className = classes.complete;
    }
  } else {
    const scoreClass = isScored && classes.scoreSpan;
    if (!score) {
      className = classNames(classes.noScore, scoreClass);
      display = scoreDisplay;
    } else if (isOptedOut) {
      className = classNames(classes.optOutScore, scoreClass);
      display = t("Opted out");
    } else {
      className = classNames(scoreClass);
      display = scoreDisplay;
    }
  }
  return (
    <li className={classNames(classes.rowWrapper)}>
      <div className={classNames(classes.columnName)}>
        <UsernameDisplay name={name} />
      </div>
      <div className={classNames(classes.columnValue)}>
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent element={"span"} rootClassName={className}>
              {display}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <span className={className}>{display}</span>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      </div>
    </li>
  );
};

EvaluationPopperViewRow.propTypes = propTypes;
export default EvaluationPopperViewRow;
