import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import ReadOnlyEyeIcon from "./read-only-eye.icon";
import styles from "./read-only-badge.styles.module.scss";
import { GCOM_3606__fontUpdate } from "../../../../lib/feature-flags";

const ReadOnlyBadgeComponent = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.badgeWrapper}>
      <ReadOnlyEyeIcon />
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent boldness={"medium"} color={"grape"} rootClassName={styles.badgeText} styling="p3">
            {t("View only")}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <TypographyComponent boldness="medium" color="grape" rootClassName={styles.badgeText} type="p3">
            {t("View only")}
          </TypographyComponent>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
    </div>
  );
};

export default ReadOnlyBadgeComponent;
