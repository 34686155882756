import React from "react";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TextLink,
  TypographyComponent,
} from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import styles from "./importing-exporting.styles.module.scss";
import ImportingExportingImg from "../../../../../assets/images/quick-start/requirements/importing-exporting.svg";
import { GCOM_3606__fontUpdate } from "../../../../../lib/feature-flags";

const ImportingAndExportingComponent = () => {
  const { t } = useTranslation();

  return (
    <div>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent styling={"h5"} boldness={"semi"}>
            {t("Importing / exporting")}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <TypographyComponent type={"h5"} boldness={"semi"}>
            {t("Importing / exporting")}
          </TypographyComponent>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
      <div className={styles.importExportImageContainer}>
        <img src={ImportingExportingImg} alt={t("Importing / exporting")} />
      </div>

      <div className={styles.description}>
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent rootClassName={styles.heading} styling={"p2"} boldness={"semi"}>
              {t(
                "If your team prefers to work on requirements in a different format, moving content back and forth between an Excel spreadsheet and BuySmart is made easy with the Import and Export features."
              )}
            </TypographyComponent>
            <TypographyComponent rootClassName={styles.additionalInfo} styling={"p2"} boldness={"regular"}>
              {t(
                "Make sure to follow our standardized Excel format by downloading a blank version of the template from the Import Library or Exporting the default list of requirements."
              )}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <TypographyComponent rootClassName={styles.heading} type={"p2"} boldness={"semi"}>
              {t(
                "If your team prefers to work on requirements in a different format, moving content back and forth between an Excel spreadsheet and BuySmart is made easy with the Import and Export features."
              )}
            </TypographyComponent>
            <TypographyComponent rootClassName={styles.additionalInfo} type={"p2"} boldness={"regular"}>
              {t(
                "Make sure to follow our standardized Excel format by downloading a blank version of the template from the Import Library or Exporting the default list of requirements."
              )}
            </TypographyComponent>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
        <div className={styles.helpLink}>
          <TextLink
            text={t("Read more about importing and exporting requirements")}
            target={"_blank"}
            href={
              "https://intercom.help/gartner-buysmart-help-center/en/articles/6260845-establishing-requirements#h_0d53e5058c"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ImportingAndExportingComponent;
