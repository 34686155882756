import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { InitProdState, UUID } from "gx-npm-lib";
import { updateStateSubject } from "gx-npm-messenger-util";
import {
  Button,
  ProductLogo,
  TooltipV2,
  TypographyComponent,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  useFeatureFlag,
} from "gx-npm-ui";
import { KeyboardEvent, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as singleSpa from "single-spa";
import SelectionPhaseSelectedSVG from "../../../../assets/images/selectionPhaseSelected.svg";
import { OverviewContext } from "../../overview.context";
import { selectedBodyV2Styles as styles } from "./selected-body-v2.styles";
import { GCOM_3695_ctaButtonColorUpdate, GCOM_3606__fontUpdate } from "../../../../lib/feature-flags";

const useStyles = makeStyles(() => styles);
const SelectedBodyV2 = () => {
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);
  const classes = useStyles();
  const { t } = useTranslation();
  const { initiativeId = "" } = useParams<{ initiativeId: UUID }>();
  const [isWrap, setIsWrap] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const { products } = useContext(OverviewContext);

  const selectedVendor = products.find((product) => product.state === InitProdState.AWARDED);
  let imageLoc = "";
  let overallScoreDisplay = "";
  let vendorName = "";
  let id: UUID = "";
  if (selectedVendor) {
    imageLoc = selectedVendor.imageLoc;
    overallScoreDisplay = selectedVendor.overallScoreDisplay;
    vendorName = selectedVendor.name;
    id = selectedVendor.id;
  }
  const getRef = useRef<HTMLDivElement | null>(null);
  const noWrapLineHeight = 61;

  useEffect(() => {
    const bodyWrapperRO = new ResizeObserver((elem) => {
      setIsWrap(elem[0].contentRect.height > noWrapLineHeight);
    });
    const curRef = getRef.current;
    if (curRef) {
      bodyWrapperRO.observe(curRef);
      return () => {
        bodyWrapperRO.unobserve(curRef);
      };
    }
  }, []);

  const handleClickNavigate = () => {
    singleSpa.navigateToUrl(`/s/evaluation/${initiativeId}/selection`);
  };

  const handleClickProduct = (pid: UUID) => {
    const url = `/s/evaluation/${initiativeId}/product/${pid}/profile`;
    updateStateSubject("WORKSPACE_HEADER_SOURCE_PAGE", { sourcePage: "overview" });
    singleSpa.navigateToUrl(url);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>, pid: UUID) => {
    if (event.key === "Enter") {
      handleClickProduct(pid);
    }
  };

  return (
    <div className={classes.selectedBodyContainer}>
      <div ref={getRef} className={classes.selectedBodyWrapper}>
        <TooltipV2
          enterDelay={1500}
          enterNextDelay={1500}
          placement="top-start"
          PopperProps={{ modifiers: { offset: { offset: "-55x, 8px" } } }}
          rootClassName={"gx-selected-body-icon-vendor-name-tooltip"}
          title={t("Click to view profile")}
        >
          <div
            aria-label={t("Vendor logo and name")}
            className={classNames(
              classes.selectedBodyLogoTitleV2,
              isFFGCOM3695 && classes.selectedBodyLogoTitleV2GCOM3695,
              isHover && "hover"
            )}
            onBlur={() => setIsHover(false)}
            onClick={() => handleClickProduct(id)}
            onFocus={() => setIsHover(true)}
            onKeyDown={(event) => handleKeyDown(event, id)}
            onMouseOut={() => setIsHover(false)}
            onMouseOver={() => setIsHover(true)}
            role="button"
            tabIndex={0}
          >
            <div>
              <ProductLogo logo={imageLoc} imageWidth="32px" imageHeight="32px" />
            </div>
            <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
              <FeatureFlagBooleanOn>
                <TypographyComponent color={"carbon"} rootClassName={classes.selectedVendorName} styling={"h4"}>
                  {vendorName}
                </TypographyComponent>
              </FeatureFlagBooleanOn>
              <FeatureFlagBooleanOff>
                <TypographyComponent color="carbon" rootClassName={classes.selectedVendorName} type="h4">
                  {vendorName}
                </TypographyComponent>
              </FeatureFlagBooleanOff>
            </FeatureFlagBooleanContainer>
          </div>
        </TooltipV2>

        <div className={classes.scorePill}>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent color={"carbon"} styling={"p4"}>
                {t("Overall score")}
              </TypographyComponent>
              <TypographyComponent rootClassName={classes.selectedVendorScore} styling={"h3"}>
                {overallScoreDisplay} / 100
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <TypographyComponent color="carbon" type="p4">
                {t("Overall score")}
              </TypographyComponent>
              <TypographyComponent rootClassName={classes.selectedVendorScore} type="h3">
                {overallScoreDisplay} / 100
              </TypographyComponent>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </div>
        <img alt="Selected vendor background" className={classes.selectedBackground} src={SelectionPhaseSelectedSVG} />
      </div>
      <Button
        onClick={handleClickNavigate}
        rootClassName={classNames("btn-primary", classes.selectedCTAButton, isWrap && classes.isWrap)}
      >
        {t("View Selection summary")}
      </Button>
    </div>
  );
};
export default SelectedBodyV2;
