import { useContext } from "react";
import { useTranslation } from "react-i18next";
import styles from "./evaluation-details-header.module.scss";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  ProductLogo,
  TypographyComponent,
} from "gx-npm-ui";
import { SelectionContext } from "../../../../../../selection.context";
import { Product } from "../../../../../../selection.types";
import { GCOM_3606__fontUpdate } from "../../../../../../../../lib/feature-flags";

type EvaluationDetailsHeaderProps = {
  awardedProduct: Product;
};
const EvaluationDetailsHeader = ({ awardedProduct }: EvaluationDetailsHeaderProps) => {
  const { requirementCategories, requirementItemCount } = useContext(SelectionContext);

  const { t } = useTranslation();

  return (
    <div className={styles.topContainer}>
      <div className={styles.leftContainer}>
        <div className={styles.productContainer}>
          <div className={styles.productInfo}>
            <ProductLogo
              logo={awardedProduct.imageLoc}
              name={awardedProduct.name}
              imageWidth="32px"
              imageHeight="32px"
            />
            <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
              <FeatureFlagBooleanOn>
                <TypographyComponent boldness={"semi"} styling={"p1"} rootClassName={styles.selectedVendorName}>
                  {awardedProduct.name}
                </TypographyComponent>
              </FeatureFlagBooleanOn>
              <FeatureFlagBooleanOff>
                <TypographyComponent boldness="semi" type="p1" rootClassName={styles.selectedVendorName}>
                  {awardedProduct.name}
                </TypographyComponent>
              </FeatureFlagBooleanOff>
            </FeatureFlagBooleanContainer>
          </div>
          <div className={styles.overallScorePill}>
            <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
              <FeatureFlagBooleanOn>
                <TypographyComponent boldness={"semi"} styling={"p4"} rootClassName={styles.scorePillTitle}>
                  {t("Overall score")}
                </TypographyComponent>
                <TypographyComponent boldness={"semi"} styling={"h4"} rootClassName={styles.overallScore}>
                  {awardedProduct.overallScoreDisplay} / 100
                </TypographyComponent>
              </FeatureFlagBooleanOn>
              <FeatureFlagBooleanOff>
                <TypographyComponent boldness="semi" type="p4" rootClassName={styles.scorePillTitle}>
                  {t("Overall score")}
                </TypographyComponent>
                <TypographyComponent boldness="semi" type="h4" rootClassName={styles.overallScore}>
                  {awardedProduct.overallScoreDisplay} / 100
                </TypographyComponent>
              </FeatureFlagBooleanOff>
            </FeatureFlagBooleanContainer>
          </div>
        </div>
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent styling={"p4"} rootClassName={styles.categoryHeader}>
              {t("Category")}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <TypographyComponent type="p4" rootClassName={styles.categoryHeader}>
              {t("Category")}
            </TypographyComponent>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      </div>
      <div className={styles.categoryStats}>
        <div className={styles.categoryStat}>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent styling={"p3"} rootClassName={styles.categoryStats}>
                {t("Scored against")}{" "}
                <span className={styles.categoryStatBold}>{`${requirementItemCount} requirements`}</span>
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <TypographyComponent type="p3" rootClassName={styles.categoryStats}>
                {t("Scored against")}{" "}
                <span className={styles.categoryStatBold}>{`${requirementItemCount} requirements`}</span>
              </TypographyComponent>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </div>
        <div className={styles.categoryStat}>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent styling={"p3"} rootClassName={styles.categoryStats}>
                {t("across")}{" "}
                <span className={styles.categoryStatBold}>{`${requirementCategories.length} categories`}</span>
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <TypographyComponent type="p3" rootClassName={styles.categoryStats}>
                {t("across")}{" "}
                <span className={styles.categoryStatBold}>{`${requirementCategories.length} categories`}</span>
              </TypographyComponent>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </div>
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent styling={"p3"} rootClassName={styles.categoryScoreHeader}>
              {t("Category score")}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <TypographyComponent type="p3" rootClassName={styles.categoryScoreHeader}>
              {t("Category score")}
            </TypographyComponent>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      </div>
    </div>
  );
};
export default EvaluationDetailsHeader;
