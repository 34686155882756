import React from "react";
import propTypes from "prop-types";
import styles from "./survey-team-list.styles";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  Paragraph,
  TypographyComponent,
} from "gx-npm-ui";
import SurveyTeamListItem from "./survey-team-list-item.component";
import { GCOM_3606__fontUpdate } from "../../../../lib/feature-flags";

const proptypes = {
  recipients: propTypes.array,
  onRemoveTeamMember: propTypes.func,
  onResendInvite: propTypes.func,
  resendEmailSuccess: propTypes.string,
  removeUserSuccess: propTypes.bool,
  addEmailSuccess: propTypes.string,
};

const useStyles = makeStyles(styles);
const SurveyTeamList = ({
  recipients,
  onRemoveTeamMember = () => {},
  onResendInvite = () => {},
  resendEmailSuccess = "",
  addEmailSuccess = "",
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  let teamList = [];

  if (recipients?.length > 0) {
    teamList = recipients;
  }

  return (
    <>
      <div className={classes.recipientList}>
        {teamList.length > 0 &&
          teamList.map((recipient, index) => {
            return (
              <SurveyTeamListItem
                key={index}
                email={recipient.email}
                fullName={recipient.fullName}
                hasViewed={recipient.hasViewed}
                onRemoveTeamMember={onRemoveTeamMember}
                onResendInvite={onResendInvite}
                resendEmailSuccess={resendEmailSuccess}
                addEmailSuccess={addEmailSuccess}
              />
            );
          })}
        {teamList.length === 0 && (
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent rootClassName={classes.noContacts} styling={"p4"}>
                {t("No contacts have been added.")}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <Paragraph rootClassName={classes.noContacts} type="p4">
                {t("No contacts have been added.")}
              </Paragraph>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        )}
      </div>
    </>
  );
};

SurveyTeamList.propTypes = proptypes;
export default SurveyTeamList;
