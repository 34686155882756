import * as singleSpa from "single-spa";

const profileRedirectCallback = (data = { isProfileIncomplete: false }) => {
  if (!data.isProfileIncomplete) {
    return;
  }
  singleSpa.navigateToUrl(`${window.location.href}#/profile`);
};

export default profileRedirectCallback;
