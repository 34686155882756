import { colorPalette, weightPalette } from "gx-npm-common-styles";

const evaluationPopperViewRowStyle = {
  rowWrapper: {
    alignItems: "center",
    display: "flex",
    paddingTop: "12px",
    "& $columnName, $columnValue": {
      display: "inline-flex",
    },
  },
  columnName: {
    "& .gx-user": {
      paddingTop: "0",
      "& .MuiAvatar-root": {
        fontSize: "12px",
        fontVariationSettings: weightPalette.medium.variation,
        fontWeight: weightPalette.medium.amount,
        height: "32px",
        letterSpacing: "0.35px",
        lineHeight: "18px",
        width: "32px",
      },
    },
    "& .gx-ava-owner": {
      color: colorPalette.neutrals.coal.hex,
      fontSize: "12px",
      fontVariationSettings: weightPalette.regular.variation,
      fontWeight: weightPalette.regular.amount,
      letterSpacing: "0.35px",
      lineHeight: "18px",
      maxWidth: "164px",
    },
  },
  columnValue: {
    color: colorPalette.neutrals.coal.hex,
    fontSize: "12px",
    fontVariationSettings: weightPalette.semiBold.variation,
    fontWeight: weightPalette.semiBold.amount,
    letterSpacing: "0.35px",
    marginLeft: "auto",
    marginRight: "0",
    "& $complete": {
      color: colorPalette.status.darkPear.hex,
    },
    "& $notStarted, $optOutScore": {
      fontVariationSettings: weightPalette.regular.variation,
      fontWeight: weightPalette.regular.amount,
    },
    "& noScore, $optOutScore": {
      color: colorPalette.neutrals.iron.hex,
    },
  },
  scoreSpan: {
    paddingRight: "7px",
  },
  complete: {},
  noScore: {},
  notStarted: {},
  optOutScore: {},
};

export default evaluationPopperViewRowStyle;
