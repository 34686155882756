import React from "react";
import PropTypes from "prop-types";
import { FormControlLabel, makeStyles, Radio } from "@material-ui/core";
import { handleEvent } from "gx-npm-lib";
import { scoringOptionStyles as styles } from "./styles";
import { useTranslation } from "react-i18next";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { GCOM_3606__fontUpdate } from "../../../../../lib/feature-flags";

const propTypes = {
  count: PropTypes.string,
  isCurrent: PropTypes.bool,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
  textDesc: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  textTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};
const useStyles = makeStyles(() => styles);
const ScoringLevelOption = ({
  count = "",
  isCurrent = false,
  isSelected = false,
  onSelect = null,
  textDesc = "",
  textTitle = "",
}) => {
  const handleChange = () => {
    handleEvent(onSelect);
  };
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <FormControlLabel
        className={classes.formControlLabel}
        control={
          <Radio
            checked={isSelected}
            className={classes.radioColor}
            color="primary"
            name={textTitle}
            onChange={handleChange}
          />
        }
        label={
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent styling={"p3"} boldness={"medium"}>
                {textTitle}
                {isCurrent && (
                  <TypographyComponent color={"iron"}>
                    {""} {t("(current)")}
                  </TypographyComponent>
                )}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <TypographyComponent styling="p3" boldness="medium">
                {textTitle}
                {isCurrent && (
                  <span className={classes.iron}>
                    {` `}
                    {t("(current)")}
                  </span>
                )}
              </TypographyComponent>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        }
      />
      <div className={classes.description} data-testid="items-to-score">
        <TypographyComponent styling="p4" color="iron">
          {textDesc}
        </TypographyComponent>
        <TypographyComponent styling="p3" rootClassName={classes.itemsCountInfo}>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent element={"span"}>{count || `0`}</TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <span>{count || `0`}</span>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
          {` `}
          {t("items to score")}
        </TypographyComponent>
      </div>
    </div>
  );
};

ScoringLevelOption.propTypes = propTypes;
export default ScoringLevelOption;
