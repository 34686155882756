import { colorPalette } from "gx-npm-common-styles";

const upNextCardV2Styles = {
  /* TODO - remove what is between this once GCOM-2654 is complete */
  root: {
    background: colorPalette.basic.white.hex,
    borderRadius: "8px",
    color: colorPalette.neutrals.iron.hex,
    marginTop: "24px",
    maxWidth: "1400px",
    minWidth: "900px",
    paddingLeft: "40px",
    paddingTop: "28px",
    "& .gx-h": {
      marginBottom: "32px",
    },
  },
  phaseDescription: {
    display: "flex",
    "@media (max-width: 1024px)": {
      gap: "32px",
      flexDirection: "column",
    },
  },
  phaseHighlights: {
    flexBasis: "374px",
    flexShrink: 0,
    margin: 0,
    marginRight: "24px",
    paddingLeft: "20px",
    "@media (max-width: 1024px)": {
      flex: "none",
      width: "600px",
    },
    "& .gx-p": {
      color: colorPalette.neutrals.coal.hex,
      marginBottom: "8px",
    },
  },
  GCOM3606PhaseHighlights: {
    flexBasis: "374px",
    flexShrink: 0,
    margin: 0,
    marginRight: "24px",
    paddingLeft: "20px",
    "@media (max-width: 1024px)": {
      flex: "none",
      width: "600px",
    },
  },
  phaseHighlightsList: {
    marginBottom: "8px",
  },
  phaseImage: {
    alignSelf: "flex-end",
    overflow: "hidden",
    width: "100%",
  },
  /* TODO - remove what is between this once GCOM-2654 is complete */
  container: {
    background: colorPalette.basic.white.hex,
    borderRadius: "8px",
    color: colorPalette.neutrals.iron.hex,
    marginTop: "24px",
    maxWidth: "1400px",
    minWidth: "900px",
  },
  columnWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  leftColumn: {
    paddingTop: "32px",
    paddingLeft: "39px",
    paddingRight: "63px",
  },
  cards: {
    display: "flex",
  },
  card1: {
    width: "450px",
    marginRight: "24px",
    "& .gx-p": {
      paddingLeft: "24px",
    },
    "& img": {
      marginBottom: "43px",
      width: "100%",
    },
  },
  card2: {
    position: "relative" as const,
    width: "450px",
    "& .gx-p": {
      paddingLeft: "24px",
    },
    "& img": {
      marginBottom: "11px",
      width: "100%",
    },
  },
  title: {
    "&.gx-h": {
      marginBottom: "8px",
    },
  },
  GCOM3606Title: {
    marginBottom: "8px",
  },
  evalStartedImage: {
    alignSelf: "flex-end",
    overflow: "hidden",
    width: "100%",
    marginTop: "75px",
  },
  descriptionText: {
    "&.gx-p": {
      color: colorPalette.neutrals.iron.hex,
    },
  },
  cardDescriptionWrapper: {
    paddingRight: "120px",
  },
  cardDescription: {
    "&.gx-p": {
      paddingTop: "4px",
      marginBottom: "40px",
      color: colorPalette.neutrals.iron.hex,
    },
  },
  GCOM3606CardDescription: {
    paddingTop: "4px",
    marginBottom: "40px",
  },
};

export { upNextCardV2Styles };
