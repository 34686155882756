import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import { handleEvent, sumArrayObjects } from "gx-npm-lib";
import {
  Button,
  Dialog,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  InlineAlert,
  TypographyComponent,
  useFeatureFlag,
} from "gx-npm-ui";
import { editDialogStyles as styles } from "./styles";
import { useTranslation } from "react-i18next";
import { EvaluationStateContext } from "../../../../../context";
import ErrorIcon from "../../../drawers/libraryDrawer/importLibraryBody/errorIcon";
import { PercentageIcon } from "gx-npm-icons";
import { colorPalette } from "gx-npm-common-styles";
import { getApiUrl } from "../../../../../context/actions/actionUtils";
import { putRequest } from "../../../../../context/actions/apiRequests";
import { loadRequirementsData } from "../../../../../context/actions/requirementsActions";
import { GCOM_3606__fontUpdate, GCOM_3695_ctaButtonColorUpdate } from "../../../../../lib/feature-flags";

const propTypes = {
  isOpen: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func,
  initiativeId: PropTypes.string,
};

const validWeightAmount = 100;
const determineValidWeight = (weight, list) => {
  return weight === validWeightAmount || (list?.length || 0) === 0;
};

const formatNumber = (num) => {
  return num % 1 === 0 ? num : num.toFixed(1);
};

const useStyles = makeStyles(() => styles);
const EditCategoryWeightDialog = ({ initiativeId = "", isOpen = false, onClick = null }) => {
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);
  const { t } = useTranslation();
  const [isScrolledTop, setIsScrolledTop] = useState(false);
  const [isScrolledBottom, setIsScrolledBottom] = useState(false);
  const [hasScrollbar, setHasScrollbar] = useState(false);
  const classes = useStyles({ isScrolledTop, isScrolledBottom, hasScrollbar });
  const [weightAmount, setWeightAmount] = useState(0);
  const [isValidWeight, setIsValidWeight] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedIdx, setSelectedIdx] = useState(null);
  const [displayedWeights, setDisplayedWeights] = useState([]);
  const scrollableDivRef = useRef(null);
  const [state, dispatch] = useContext(EvaluationStateContext);
  const handleScroll = (e) => {
    const el = e.target;
    setIsScrolledTop(el.scrollTop > 0);
    setIsScrolledBottom(el.scrollTop < el.scrollHeight - el.clientHeight);
  };

  const resetWeights = useCallback(() => {
    const originalCatList = state.requirements.list.map((cat, index) => {
      const catName = cat.name || "Untitled Category";
      return { id: cat.id, index, name: catName, weight: cat.weight };
    });
    setCategoryList(originalCatList);

    const originalWeight = Number.parseFloat(formatNumber(sumArrayObjects(state.requirements.list, "weight")));
    setWeightAmount(originalWeight);

    const originalIsValidWeight = determineValidWeight(originalWeight, state.requirements.list);
    setIsValidWeight(originalIsValidWeight);

    setDisplayedWeights(state.requirements.list.map((cat) => cat.weight.toFixed(1)));
  }, [state.requirements.list]);

  useEffect(() => {
    resetWeights();
    setDisplayedWeights(state.requirements.list.map((cat) => cat.weight.toFixed(1)));
  }, [state.requirements.list, resetWeights]);

  useEffect(() => {
    if (scrollableDivRef.current && (categoryList.length > 5 || (categoryList.length > 4 && !isValidWeight))) {
      const el = scrollableDivRef.current;
      const isScrollbarPresent = el.scrollHeight > el.clientHeight;
      setHasScrollbar(isScrollbarPresent);
    }
  }, [categoryList, isValidWeight, scrollableDivRef]);

  const handleWeightChange = (newWeight, index) => {
    const newWeightAmountRaw = weightAmount + newWeight - categoryList[index].weight;
    const newWeightAmount = Math.round(newWeightAmountRaw * 10) / 10;
    setWeightAmount(newWeightAmount);
    setIsValidWeight(determineValidWeight(newWeightAmount, categoryList));
    const newCategoryList = [...categoryList];
    newCategoryList[index].weight = newWeight;
    setCategoryList(newCategoryList);
  };

  const handleEditClick = (index) => {
    setSelectedIdx(index);
  };

  const handleUpdateWeights = () => {
    handleUpdateCategoryListWeights(categoryList);
    handleEvent(onClick, false);
  };

  const handleUpdateCategoryListWeights = async (list) => {
    let catWeights = [];
    list.forEach((cat, idx) => {
      if (cat.weight !== state.requirements.list[idx].weight) {
        catWeights.push({ weight: cat.weight, id: cat.id });
      }
    });
    const section = "requirements/action/update/category/weights";
    const url = getApiUrl(initiativeId, section, 2);
    const payload = { catWeights };
    const response = await putRequest(url, payload);
    if (response?.status === 200) {
      loadRequirementsData(null, dispatch, { initiativeId, isReloading: true });
    }
  };

  const handleCancelClick = () => {
    resetWeights();
    handleEvent(onClick, false);
  };

  const hasSingleDecimal = (value) => {
    const regex = /^\d+(\.\d)?$/;
    return regex.test(value);
  };

  const body = (
    <div className={classes.editWeightBodyContainer}>
      <div
        className={classNames(classes.editWeightBody)}
        onScroll={handleScroll}
        ref={scrollableDivRef}
        data-testid="scrollableElement"
      >
        <div className={classes.editWeightBodyAlert}>
          {!isValidWeight && (
            <InlineAlert textMessage={t("Total weight must equal 100%")} alertType="error" isShadowed={false} />
          )}
          <div className={classes.editWeightBodyHeader}>
            <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
              <FeatureFlagBooleanOn>
                <TypographyComponent
                  rootClassName={classNames("gx-edit-total-weight-text")}
                  styling={"p1"}
                  boldness={"semi"}
                >
                  {t("Total weight")}
                </TypographyComponent>
              </FeatureFlagBooleanOn>
              <FeatureFlagBooleanOff>
                <p className={classNames("gx-edit-total-weight-text semi-bold p1")}>{t("Total weight")}</p>
              </FeatureFlagBooleanOff>
            </FeatureFlagBooleanContainer>
            <div className={classes.editWeightBodyHeaderRight}>
              {!isValidWeight && <ErrorIcon />}
              <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                <FeatureFlagBooleanOn>
                  <TypographyComponent
                    styling={"h5"}
                    rootClassName={classNames("gx-edit-total-weight-percentage", !isValidWeight && "gx-invalid-weight")}
                  >
                    {weightAmount === validWeightAmount ? String(validWeightAmount) : formatNumber(weightAmount)}
                  </TypographyComponent>
                </FeatureFlagBooleanOn>
                <FeatureFlagBooleanOff>
                  <h5 className={classNames("gx-edit-total-weight-percentage", !isValidWeight && "gx-invalid-weight")}>
                    {weightAmount === validWeightAmount ? String(validWeightAmount) : formatNumber(weightAmount)}
                  </h5>
                </FeatureFlagBooleanOff>
              </FeatureFlagBooleanContainer>
              <PercentageIcon
                fillPath={!isValidWeight ? colorPalette.status.poisonCherry.hex : colorPalette.neutrals.iron.hex}
              />
            </div>
          </div>
        </div>
        <div className={classes.editWeightBodyLine} />
        <div className={classes.editWeightBodyCategories}>
          {categoryList.map((cat, index) => {
            return (
              <button
                key={cat.id}
                className={classNames(classes.editWeightBodyCategory)}
                aria-label="Edit weight category"
              >
                <div
                  className={classNames(classes.editCategoryBox, selectedIdx === index && "gx-edit-weight-selected")}
                >
                  <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                    <FeatureFlagBooleanOn>
                      <TypographyComponent
                        rootClassName={classNames("gx-edit-category-name")}
                        boldness={"medium"}
                        styling={"p2"}
                      >
                        {cat.name}
                      </TypographyComponent>
                    </FeatureFlagBooleanOn>
                    <FeatureFlagBooleanOff>
                      <p className={classNames("gx-edit-category-name p2 medium")}>{cat.name}</p>
                    </FeatureFlagBooleanOff>
                  </FeatureFlagBooleanContainer>
                  <div className={classNames(classes.weightedText, !isValidWeight && "gx-invalid-weight-cat")}>
                    <TextField
                      className={classNames(
                        classes.hideSpinners,
                        classes.hideOutline,
                        isFFGCOM3695 && classes.hideOutlineGCOM3695,
                        !isValidWeight && classes.invalidOutline
                      )}
                      type="number"
                      variant="outlined"
                      InputProps={{
                        endAdornment: selectedIdx !== index && (
                          <div className={classes.percentageIcon}>
                            <PercentageIcon
                              fillPath={
                                !isValidWeight ? colorPalette.status.poisonCherry.hex : colorPalette.neutrals.iron.hex
                              }
                            />
                          </div>
                        ),
                      }}
                      value={displayedWeights[index]}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (hasSingleDecimal(value) || value === "") {
                          const newWeights = [...displayedWeights];
                          newWeights[index] = value;
                          setDisplayedWeights(newWeights);
                        }
                      }}
                      onBlur={() => {
                        let value = parseFloat(displayedWeights[index]);
                        if (isNaN(value)) {
                          value = 0;
                        }
                        handleWeightChange(value, index);

                        const newWeights = [...displayedWeights];
                        newWeights[index] = value.toFixed(1);
                        setDisplayedWeights(newWeights);
                        setSelectedIdx(null);
                      }}
                      onFocus={() => handleEditClick(index)}
                      inputProps={{
                        style: {
                          textAlign: "right",
                        },
                        step: "0.1",
                        min: "0",
                        max: "100",
                      }}
                    />
                  </div>
                </div>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
  return (
    <Dialog
      body={<div>{body}</div>}
      footer={
        <div className={classes.root}>
          <Button rootClassName="btn-tertiary" onClick={handleCancelClick}>
            {t("CANCEL")}
          </Button>
          <Button borderRadius={4} rootClassName="btn-primary" disabled={!isValidWeight} onClick={handleUpdateWeights}>
            {t("SAVE CATEGORY WEIGHTS")}
          </Button>
        </div>
      }
      handleClose={() => handleCancelClick()}
      open={isOpen}
      maxDialogWidth="699px"
      rootClassName="gx-edit-weight-dialog"
      title={t("Edit category weights")}
      variant="overlay"
      isScrollable={true}
      closeIcon={true}
    />
  );
};

EditCategoryWeightDialog.propTypes = propTypes;
export default EditCategoryWeightDialog;
