import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {
  Button,
  ButtonLoader,
  Dialog,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { makeStyles } from "@material-ui/core";
import { bulkOpsDialogClasses as styles } from "./styles";
import { useTranslation } from "react-i18next";
import { GCOM_3606__fontUpdate } from "../../../../lib/feature-flags";

const propTypes = {
  count: PropTypes.number,
  isOpen: PropTypes.bool,
  isProcessing: PropTypes.bool,
  onClickCancel: PropTypes.func,
  onClickConfirm: PropTypes.func,
};
const useStyles = makeStyles(() => styles);
const BulkOperationConfirmationDialog = ({
  count = null,
  isOpen = false,
  isProcessing = false,
  onClickCancel = () => {},
  onClickConfirm = (_action) => {},
}) => {
  const reqCountConjugate = count > 1 ? "s" : "";
  const handleClickConfirm = () => {
    onClickConfirm("move");
  };
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Dialog
      title={t("Move requirements")}
      footer={
        <div className={classes.footerContainer}>
          <Button
            ariaLabel={`button-cancel-move`}
            disabled={isProcessing}
            onClick={onClickCancel}
            rootClassName="btn-tertiary"
          >
            {t("CANCEL")}
          </Button>
          <ButtonLoader
            ariaLabel="button-confirm-move"
            btnClass="btn-primary"
            isLoading={isProcessing}
            onClick={handleClickConfirm}
            rootClassName={classNames(`gx-fs-bulk-move-confirm-btn`, `gx-full-story-bulk-move-confirm-btn`)}
          >
            {t("MOVE")}
          </ButtonLoader>
        </div>
      }
      open={isOpen}
      body={
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent
              className={classNames(classes.descriptionContainer)}
              styling={"p2"}
              role="complementary"
            >
              {t("Are you sure you want to move the")}
              <TypographyComponent element={"span"} className="semi-bold">
                {` ${count} `}
                {t("selected requirement")}
                {reqCountConjugate}
              </TypographyComponent>
              {t("? You can always move the requirements again should you change your mind.")}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <p className={classNames("p2", classes.descriptionContainer)} role="complementary">
              {t("Are you sure you want to move the")}
              <span className="semi-bold">
                {` ${count} `}
                {t("selected requirement")}
                {reqCountConjugate}
              </span>
              {t("? You can always move the requirements again should you change your mind.")}
            </p>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      }
    />
  );
};

BulkOperationConfirmationDialog.propTypes = propTypes;
export { BulkOperationConfirmationDialog };
