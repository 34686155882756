import { colorPalette, weightPalette } from "gx-npm-common-styles";

const colorIronHex = colorPalette.neutrals.iron.hex;
const colorLightBerryHex = colorPalette.interactions.lightBerry.hex;
const colorQuartzHex = colorPalette.neutrals.quartz.hex;
const colorSilverHex = colorPalette.neutrals.silver.hex;
const colorStoneHex = colorPalette.neutrals.stone.hex;
const weightMediumAmount = weightPalette.medium.amount;
const weightMediumVariation = weightPalette.medium.variation;
const weightRegularAmount = weightPalette.regular.amount;
const weightRegularVariation = weightPalette.regular.variation;

const checklistStyles = {
  container: {
    backgroundColor: colorQuartzHex,
    height: "100%",
    left: 76,
    padding: 0,
    width: "100%",
    "& .gx-dnd-item": {
      alignItems: "center",
      display: "flex",
      padding: "0 0 1px 0",
      "& .gx-due-date-picker": {
        width: 160,
        "& input:disabled::placeholder": {
          opacity: 0,
        },
      },
      "&.complete": {
        "& .checklist-title-field, & .checklist-desc-field": {
          "& .gx-checklist-text-area textarea": {
            color: colorIronHex,
            fontVariationSettings: weightRegularVariation,
            fontWeight: weightRegularAmount,
          },
        },
      },
      "&.hover": {
        backgroundColor: colorPalette.neutrals.pearl.hex,
        "& .checklist-owner-avatar-list": {
          "& .MuiButton-label": {
            "& p": {
              color: colorIronHex,
            },
          },
        },
        "& .gx-due-date-picker": {
          "& input:placeholder-shown": {
            color: colorIronHex,
          },
          "& input::placeholder, & input:disabled::placeholder": {
            opacity: 1,
          },
        },
      },
      "&.edit": {
        backgroundColor: colorLightBerryHex,
      },
      "&.hover .gx-checklist-text-area textarea": {
        backgroundColor: colorPalette.neutrals.pearl.hex,
      },
      "&.edit .gx-checklist-text-area textarea": {
        backgroundColor: colorLightBerryHex,
      },
      "&.gx-is-draggable .checklist-text-name-field textarea": {
        marginLeft: "16px",
      },
      "&.gx-is-not-draggable .checklist-text-name-field textarea": {
        marginLeft: "64px",
      },
      "& .gx-dnd-icon": {
        minWidth: "48px",
      },
      "& .checklist-owner-field input": {
        fontSize: 14,
      },
      "& .checklist-owner-avatar-list": {
        padding: 0,
        transition: "none",
        width: "calc(100% - 2px)",
        height: 44,
        margin: "0 1px",
        outline: "1px solid transparent",
        "& .MuiButton-label": {
          display: "flex",
          marginLeft: 12,
          justifyContent: "left",
          "& p": {
            color: "transparent",
            textTransform: "none",
            fontSize: 14,
          },
          "& .avatar-container": {
            marginLeft: "-2px",
            "& .gx-user": {
              padding: 0,
              width: 22,
            },
          },
        },
        "& .MuiTouchRipple-root": {
          display: "none",
        },
        fontSize: 14,
        backgroundColor: "transparent",
        "&:hover": {
          outlineColor: colorStoneHex,
          "& .MuiButton-label": {
            "& p": {
              color: colorIronHex,
            },
          },
        },
      },
      "& .popper-active": {
        outline: "2px solid" + colorPalette.interactions.defaultCta.hex + "!important",
        "& .MuiButton-label": {
          "& p": {
            color: colorIronHex + "!important",
          },
        },
      },

      "&.gx-read-only": {
        "& .gx-checklist-text-area textarea": {
          backgroundColor: "transparent",
        },
        "&.hover": {
          "& .gx-checklist-text-area textarea": {
            outline: 0,
          },
        },
      },
    },
    "& .button-filler": {
      height: "48px",
      width: "48px",
    },
  },
  taskLeft: {
    boxSizing: "border-box",
    float: "left",
    width: "25%",
  },
  itemsHeader: {
    paddingBottom: 10,
    display: "flex",
    backgroundColor: colorPalette.basic.white.hex,
    "& .gx-p": {
      color: colorIronHex,
    },
    paddingTop: 10,
    borderTop: "1px solid " + colorPalette.neutrals.silver.hex,
    "&::before": {
      content: "' '",
      display: "inline-flex",
      width: 100,
    },
    "&::after": {
      content: "' '",
      display: "inline-flex",
      width: 48,
    },
  },
  itemName: {
    "&.gx-p": {
      paddingLeft: 14,
      width: "25%",
    },
    "&.GCOM3606ItemName": {
      paddingLeft: 14,
      width: "25%",
    },
  },
  description: {
    "&.gx-p": {
      flex: 1,
      paddingLeft: 12,
    },
    "&.GCOM3606Description": {
      flex: 1,
      paddingLeft: 12,
    },
  },
  assigneeColumn: {
    display: "flex",
    gap: "8px",
    paddingLeft: 13,
    width: "160px",
  },
  dueDateColumn: {
    display: "flex",
    gap: "8px",
    paddingLeft: 12,
    width: "160px",
    "&.gx-p": {
      paddingLeft: 12,
      marginLeft: "auto",
    },
    "&.GCOM3606DueDateColumn": {
      paddingLeft: 12,
      marginLeft: "auto",
    },
  },
  timeline: {
    float: "right",
    marginTop: 5,
    "& a": {
      color: colorPalette.interactions.blueBerry.hex,
      fontSize: "16px",
      fontVariationSettings: weightMediumVariation,
      fontWeight: weightMediumAmount,
      letterSpacing: "0.25px",
      lineHeight: "24px",
    },
  },
  checklistHeader: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "24px",
    "& .MuiCircularProgress-root": {
      marginTop: "30px",
    },
  },
  noTasksAssignedText: {
    backgroundColor: colorPalette.basic.white.hex,
    boxShadow: `inset 0px 1px 0px ${colorSilverHex}`,
    height: 48,
    "& .gx-p": {
      paddingLeft: 116,
      paddingTop: 14,
      color: colorIronHex,
    },
  },
  noTasksAssignedContainer: {
    backgroundColor: colorPalette.basic.white.hex,
    boxShadow: `inset 0px 1px 0px ${colorSilverHex}`,
    height: 48,
    "& .assigned-text": {
      paddingLeft: 116,
      paddingTop: 14,
    },
  },
  myTasksAndLabelWrapper: {
    display: "flex",
    alignItems: "center",
  },
  addButtonTooltip: {
    width: 240,
  },
  avatarTooltip: {
    left: "1px !important",
    top: "-8px !important",
  },
  dateTooltip: { left: "-8px !important", top: "-8px !important" },
  datePopper: { marginRight: "36px !important" },
};

export default checklistStyles;
