import { InitProdState } from "gx-npm-lib";
import React, { Fragment, useState } from "react";
import { ProductListRowButtonAction } from "../../product-list-row-v3.constants";
import AddProductButtonComponent from "./buttons/add-product-button.component";
import DeleteProductButtonComponent from "./buttons/delete-product-button.component";
import RemoveProductButtonComponent from "./buttons/remove-product-button.component";
import { DeleteEntityDialog } from "../../../../../../../ui/dialogs";
import { DialogWithTextbox, TypographyComponent } from "gx-npm-ui";
import { useTranslation } from "react-i18next";

const ButtonsCellComponent = ({
  addedByUser = false,
  isAdding = false,
  isRemoving = false,
  onClick = (_action: ProductListRowButtonAction) => {},
  state = InitProdState.LISTED,
  name = "",
}) => {
  const { t } = useTranslation();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);

  const handleCloseDialogDelete = (isConfirmed = false) => {
    if (isConfirmed) {
      onClick(ProductListRowButtonAction.DELETE);
    }
    setIsDeleteDialogOpen(false);
  };

  const handleCloseDialogRemove = (isConfirmed = false) => {
    if (isConfirmed) {
      onClick(ProductListRowButtonAction.REMOVE);
    }
    setIsRemoveDialogOpen(false);
  };
  return (
    <Fragment>
      {state === InitProdState.LISTED && (
        <AddProductButtonComponent isAdding={isAdding} onClick={() => onClick(ProductListRowButtonAction.ADD)} />
      )}
      {addedByUser && state === InitProdState.LISTED && (
        <DeleteProductButtonComponent onClick={() => setIsDeleteDialogOpen(true)} />
      )}
      {(state === InitProdState.IN_EVALUATION || state === InitProdState.SCREENED_OUT) && (
        <RemoveProductButtonComponent isRemoving={isRemoving} onClick={() => setIsRemoveDialogOpen(true)} />
      )}
      <DeleteEntityDialog
        isOpen={isDeleteDialogOpen}
        name={name}
        onClick={handleCloseDialogDelete}
        entityName="vendor"
      />
      <DialogWithTextbox
        autoFocus={true}
        confirmPasscode={"REMOVE"}
        isOpen={isRemoveDialogOpen}
        isShowingConfirm={true}
        labelCancelBtn={t("CANCEL")}
        labelConfirmBtn={t("REMOVE VENDOR")}
        labelDescription={
          <Fragment>
            <TypographyComponent styling="p2">
              {t("Are you sure you want to remove")}
              <span className="semi-bold">{` ${name} `}</span>
              {t("from your evaluation?")}
            </TypographyComponent>
            <TypographyComponent styling="p2">
              {t("All associated scores, comments, and questionnaire responses will be permanently deleted.")}
              {` `}
              {t(`Type "REMOVE" to confirm you want to remove this vendor.`)}
            </TypographyComponent>
          </Fragment>
        }
        labelTitle={t("Remove vendor")}
        onClickClose={() => handleCloseDialogRemove(false)}
        onClickConfirm={() => handleCloseDialogRemove(true)}
      />
    </Fragment>
  );
};

export default ButtonsCellComponent;
