import {
  formatDate,
  getAsyncRequest,
  InitState,
  InitUserRole,
  useUserInitAccess,
  useUserState,
  useWebSocket,
  UUID,
} from "gx-npm-lib";
import { FreeTrialBannerComponent, Loader, SnackbarBanner, TypographyComponent } from "gx-npm-ui";
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { WebSocketMessageEvent } from "../../app.constants";
import ActiveViewerAvatarsComponent from "./components/active-viewer-avatars/active-viewer-avatars.component";
import InitResearchRibbonComponent from "./components/init-research-ribbon/init-research-ribbon.component";
import InitStateBannerComponent from "./components/init-state-banner/init-state-banner.component";
import InitTitleTextFieldComponent from "./components/init-title-text-field/init-title-text-field.component";
import ReadOnlyBadgeComponent from "./components/read-only-badge/read-only-badge.component";
import SaveIndicatorComponent from "./components/save-indicator/save-indicator.component";
import ShareButtonComponent from "./components/share-button/share-button.component";
import TabsBannerComponent from "./components/tabs-banner/tabs-banner.component";
import InitAccessRevokedDialogComponent from "./dialogs/init-access-revoked-dialog/init-access-revoked-dialog.component";
import InitDeletedDialogComponent from "./dialogs/init-deleted-dialog/init-deleted-dialog.component";
import { HeaderV2Context } from "./header-v2.context";
import { InitiativeApiResponse } from "./header-v2.types";
import styles from "./header-content-v2.styles.module.scss";

type WsInMsgInitReload = { event: WebSocketMessageEvent; initiativeId: UUID; state: InitState };
const HeaderContentV2Component = () => {
  const { initiativeId = "" } = useParams<{ initiativeId: UUID }>();
  const [hasLoadError, setHasLoadError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const {
    magicQuadResId,
    marketGuideResId,
    setInitName,
    setInitState,
    setInitStatus,
    setMagicQuadResId,
    setMarketGuideResId,
    setPiMarketSeoName,
    setStateUpdateDate,
    setTemplateName,
    templateName,
  } = useContext(HeaderV2Context);
  const { hasLoadedAccess, role } = useUserInitAccess(initiativeId as UUID);
  const { freeTrialDaysRemaining, freeTrialRole } = useUserState();
  const [wsMessage] = useWebSocket<WsInMsgInitReload, unknown>();
  const isDataReady = hasLoadedAccess && !isLoading;

  const fetchInitiativeData = useCallback(async () => {
    const url = `api/v2/initiatives/${initiativeId}/header`;
    const payload: InitiativeApiResponse = await getAsyncRequest(url);
    if (payload?.status !== 200 || typeof payload.data?.data !== "object") {
      setHasLoadError(true);
    } else {
      setInitName(payload.data.data.name || "");
      setInitState(payload.data.data.state || "");
      setInitStatus(payload.data.data.status || "");
      setMagicQuadResId(payload.data.data.magicQuadResId || -1);
      setMarketGuideResId(payload.data.data.marketGuideResId || -1);
      setPiMarketSeoName(payload.data.data.piMarketSeoName || "");
      setStateUpdateDate(payload.data.data.stateUpdateDate || "");
      setTemplateName(payload.data.data.templateName || "");
    }
  }, [
    initiativeId,
    setInitName,
    setInitState,
    setInitStatus,
    setMagicQuadResId,
    setMarketGuideResId,
    setPiMarketSeoName,
    setStateUpdateDate,
    setTemplateName,
  ]);

  useEffect(() => {
    if (!initiativeId) {
      return;
    }
    (async () => {
      setIsLoading(true);
      await fetchInitiativeData();
      setIsLoading(false);
    })();
  }, [fetchInitiativeData, initiativeId]);

  useEffect(() => {
    if (!initiativeId || !wsMessage || wsMessage.initiativeId !== initiativeId) {
      return;
    }
    if (wsMessage.event !== WebSocketMessageEvent.RELOAD_HEADER_DATA) {
      return;
    }
    (async () => {
      await fetchInitiativeData();
    })();
  }, [fetchInitiativeData, initiativeId, wsMessage]);

  useEffect(() => {
    if (!initiativeId || !wsMessage || wsMessage.initiativeId !== initiativeId) {
      return;
    }
    if (wsMessage.event !== WebSocketMessageEvent.UPDATE_INITIATIVE_STATE) {
      return;
    }
    setInitState(wsMessage.state);
    setStateUpdateDate(formatDate(new Date().toISOString(), "YYYY-MM-DD"));
  }, [initiativeId, setInitState, setStateUpdateDate, wsMessage]);

  useEffect(() => {
    setIsReadOnly(role !== InitUserRole.CONTRIBUTOR && role !== InitUserRole.OWNER);
  }, [role]);

  return (
    <Fragment>
      <div className={styles.rootWrapper}>
        <InitStateBannerComponent />
        {freeTrialRole && (
          <FreeTrialBannerComponent daysRemaining={freeTrialDaysRemaining} freeTrialRole={freeTrialRole} />
        )}
        <div className={styles.contentContainer}>
          <div className={styles.topSection}>
            <TypographyComponent color="iron" styling="p3">
              {templateName || ""}
            </TypographyComponent>
            <div className={styles.topRightWrapper}>
              {isDataReady && (
                <Fragment>
                  {isReadOnly ? <ReadOnlyBadgeComponent /> : <SaveIndicatorComponent />}
                  <div className={styles.viewerSeparator} />
                </Fragment>
              )}
              <ActiveViewerAvatarsComponent />
              <ShareButtonComponent />
            </div>
          </div>
          <div className={styles.bottomSection}>
            {!isDataReady && (
              <div className={styles.loaderContainer}>
                <Loader />
              </div>
            )}
            {isDataReady && <InitTitleTextFieldComponent />}
          </div>
        </div>
      </div>
      <TabsBannerComponent />
      <div className={styles.ribbonContainer}>
        {templateName && (magicQuadResId > 0 || marketGuideResId > 0) && <InitResearchRibbonComponent />}
      </div>
      <InitDeletedDialogComponent />
      <InitAccessRevokedDialogComponent />
      <SnackbarBanner isDefaultErrorMessage={true} isOpen={hasLoadError} setIsOpen={setHasLoadError} type="ERROR" />
    </Fragment>
  );
};

export default HeaderContentV2Component;
