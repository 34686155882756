import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  RadioButton,
  TypographyComponent,
} from "gx-npm-ui";
import styles from "./radio-button-option.styles";
import { GCOM_3606__fontUpdate } from "../../lib/feature-flags";

const propTypes = {
  count: PropTypes.number,
  handleChange: PropTypes.func,
  isSelected: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
};
const useStyles = makeStyles(() => styles);
const RadioButtonOption = ({
  count = 0,
  handleChange = (_event) => {},
  isSelected = false,
  label = "",
  value = "",
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div>
      <RadioButton checked={isSelected} label={label} onChange={handleChange} value={value} />
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent rootClassName={classNames(classes.countWrapper)} styling={"p3"} boldness={"semi"}>
            {count}
            <TypographyComponent element={"span"} rootClassName={classNames(classes.countText)}>
              {t(" items to be scored")}
            </TypographyComponent>
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <p className={classNames(classes.countWrapper, "p3", "semi-bold")}>
            {count}
            <span className={classNames(classes.countText)}>{t(" items to be scored")}</span>
          </p>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
    </div>
  );
};

RadioButtonOption.propTypes = propTypes;
export default RadioButtonOption;
