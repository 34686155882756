import { useContext } from "react";
import styles from "./initiative-objective.module.scss";
import { useTranslation } from "react-i18next";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { SelectionContext } from "../../../../selection.context";
import { Box } from "@material-ui/core";
import { ToggleText } from "../../../../../../ui/toggleText";
import { displayBudget } from "../../../../../../lib";
import { GCOM_3606__fontUpdate } from "../../../../../../lib/feature-flags";

const InitiativeObjectives = () => {
  const { t } = useTranslation();

  const { budget: initBudget, objectives } = useContext(SelectionContext);
  const budget = displayBudget(initBudget);

  const budgetDetails = budget && (
    <Box display="flex">
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent styling={"p2"} boldness={"semi"}>
            {t("Budget")}
          </TypographyComponent>
          <TypographyComponent styling={"p2"} rootClassName={styles.budgetValue}>
            {budget}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <TypographyComponent type="p2" boldness="semi">
            {t("Budget")}
          </TypographyComponent>
          <TypographyComponent type="p2" rootClassName={styles.budgetValue}>
            {budget}
          </TypographyComponent>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
    </Box>
  );

  return (
    <div className={styles.root}>
      <Box display="flex" alignItems="center" justifyContent="space-between" className={styles.titleWrapper}>
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent styling={"p2"} boldness={"semi"} rootClassName={styles.objectiveTitle}>
              {t("Objectives")}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <TypographyComponent type="p2" boldness="semi" rootClassName={styles.objectiveTitle}>
              {t("Objectives")}
            </TypographyComponent>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
        {budgetDetails}
      </Box>
      <ToggleText rootClassName={styles.toggleText} text={objectives} />
    </div>
  );
};

export default InitiativeObjectives;
