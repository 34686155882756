import { Avatar, Box } from "@material-ui/core";
import classNames from "classnames";
import { InitUserRole } from "gx-npm-lib";
import { TooltipV2, UsernameDisplay } from "gx-npm-ui";
import { useContext } from "react";
import { SelectionContext } from "../../../../../selection.context";
import { TeamMember } from "../../../../../selection.types";
import styles from "./team-members.module.scss";

const maxMemberDisplay = 3;

const TeamMembers = () => {
  const { teamMembers } = useContext(SelectionContext);
  const firstOwner: TeamMember | undefined = teamMembers.find((member) => member.role === InitUserRole.OWNER);
  const otherMembers = teamMembers.filter((member) => member.hasViewed && member.email !== firstOwner?.email);
  const otherMemberCount = otherMembers.length;
  const additionalMemberCount = otherMemberCount - maxMemberDisplay;
  const displayTeamRow = otherMemberCount > 2;
  const additionalMemberNames: string[] = [];

  const additionalMembersTooTipText = otherMembers.slice(maxMemberDisplay).map((member) => (
    <span key={member.email}>
      {member.fullName}
      <br />
    </span>
  ));

  return (
    <Box display="flex" className={styles.memberAvatarRoot}>
      {!!firstOwner && <UsernameDisplay name={firstOwner.fullName} roleDisplayFlexDirection="column" />}
      {otherMemberCount > 0 && (
        <Box
          display="flex"
          alignItems="flex-end"
          className={classNames(styles.otherMembers, displayTeamRow && styles.otherMembersRow)}
        >
          {otherMembers.map((member, i) => {
            if (i >= maxMemberDisplay) {
              additionalMemberNames.push(member.fullName);
              return;
            }
            return (
              <TooltipV2
                key={member.email}
                placement="bottom"
                PopperProps={{ modifiers: { offset: { offset: "2px, 6px" } } }}
                title={displayTeamRow ? member.fullName : ""}
              >
                <div>
                  <UsernameDisplay name={member.fullName} showName={!displayTeamRow} />
                </div>
              </TooltipV2>
            );
          })}
          {additionalMemberCount > 0 && (
            <TooltipV2
              placement="bottom"
              PopperProps={{ modifiers: { offset: { offset: "2px, 6px" } } }}
              title={additionalMembersTooTipText}
            >
              <div>
                <Avatar className={styles.additionalMembersBadge}>{`+${additionalMemberCount}`}</Avatar>
              </div>
            </TooltipV2>
          )}
        </Box>
      )}
    </Box>
  );
};

export default TeamMembers;
