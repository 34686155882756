import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import {
  Button,
  Dialog,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  Paragraph,
  TypographyComponent,
} from "gx-npm-ui";
import styles from "./revert-changes-dialog.styles";
import { GCOM_3606__fontUpdate } from "../../../../lib/feature-flags";

const propTypes = {
  isOpen: PropTypes.bool,
  onClickCancel: PropTypes.func,
  onClickConfirm: PropTypes.func,
};
const useStyles = makeStyles(() => styles);
const RevertChangesDialog = ({ isOpen = false, onClickCancel = () => {}, onClickConfirm = () => {} }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Dialog
      title={t("Revert all changes")}
      footer={
        <div className={classes.footerContainer}>
          <Button ariaLabel={`button-cancel-revert-changes`} onClick={onClickCancel} rootClassName="btn-tertiary">
            {t("CANCEL")}
          </Button>
          <Button
            rootClassName="primary-destructive-btn"
            ariaLabel={`button-confirm-revert-changes`}
            onClick={onClickConfirm}
          >
            {t("REVERT CHANGES")}
          </Button>
        </div>
      }
      open={isOpen}
      body={
        <div className={classes.descriptionContainer}>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent styling={"p2"} rootClassName={classes.descriptionStart}>
                {t("Are you sure you want to revert all changes to your requirements list?")}
              </TypographyComponent>
              <TypographyComponent styling={"p2"}>
                {t("This action will permanently discard all changes made since the last time the list was published.")}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <Paragraph type="p2" rootClassName={classes.descriptionStart}>
                {t("Are you sure you want to revert all changes to your requirements list?")}
              </Paragraph>
              <Paragraph type="p2">
                {t("This action will permanently discard all changes made since the last time the list was published.")}
              </Paragraph>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </div>
      }
    />
  );
};

RevertChangesDialog.propTypes = propTypes;
export default RevertChangesDialog;
