import React from "react";
import { makeStyles } from "@material-ui/core";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  Paragraph,
  ProductLogo,
  TooltipV2,
  TypographyComponent,
} from "gx-npm-ui";
import { additionalVendorV2Styles as styles } from "./additional-vendors-v2.styles";
import { ProductType } from "../../overview.types";
import { useTranslation } from "react-i18next";
import { GCOM_3606__fontUpdate } from "../../../../lib/feature-flags";

type AdditionalVendorsV2Props = {
  onClickEvalLink: (name: string) => void;
  vendors: ProductType[];
};
const useStyles = makeStyles(() => styles);
const AdditionalVendorsV2 = ({ onClickEvalLink, vendors = [] }: AdditionalVendorsV2Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent boldness={"medium"} styling={"p3"}>
            {`+${vendors.length} `}
            {t("more")}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <Paragraph boldness="medium" type="p3">
            {`+${vendors.length} `}
            {t("more")}
          </Paragraph>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>

      {vendors.map((vendor) => (
        <TooltipV2 key={vendor.id} PopperProps={{ modifiers: { offset: { offset: "0, 12px" } } }} title={vendor.name}>
          <button
            aria-label={`navigate to ${vendor.name} scorecard`}
            className={classes.addBtnLogo}
            onClick={() => onClickEvalLink(vendor.id)}
          >
            <ProductLogo imageHeight="32px" imageWidth="32px" logo={vendor.imageLoc} name={vendor.name} />
          </button>
        </TooltipV2>
      ))}
    </div>
  );
};
export default AdditionalVendorsV2;
