import { makeStyles } from "@material-ui/core/styles";
import VidyardEmbed from "@vidyard/embed-code";
import classNames from "classnames";
import { colorPalette } from "gx-npm-common-styles";
import { InfoLightIcon } from "gx-npm-icons";
import { FreeTrialRole, useCaptureEventsV2, useUserState } from "gx-npm-lib";
import { Button, Loader, MiniButton, QuickStartPopperComponent, TooltipV2, TypographyComponent } from "gx-npm-ui";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./pre-survey-start-view.styles";
import { ClientEvent } from "../../../app.constants";
import CreatingTheQuestionnaireComponent from "../quick-start/creating-the-questionnaire/creating-the-questionnaire.component";
import SendingTheQuestionnaireComponent from "../quick-start/sending-the-questionnaire/sending-the-questionnaire.component";
import ViewingResponsesComponent from "../quick-start/viewing-responses/viewing-responses.component";
import OverviewComponent from "../quick-start/overview/overview.component";

const propTypes = { btnText: PropTypes.string, onButtonClick: PropTypes.func, isDisabled: PropTypes.bool };
const useStyles = makeStyles(() => styles);
const PreSurveyStartView = ({ btnText, onButtonClick = () => {}, isDisabled }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [isSlideInOpen, setIsSlideInOpen] = React.useState(false);
  const videoRef = useRef(null);
  const captureEventsV2 = useCaptureEventsV2();
  const { initiativeId } = useParams();
  const { freeTrialRole } = useUserState();
  const isTrialUser = freeTrialRole === FreeTrialRole.FREE_TRIAL_RECIPIENT;

  const handleSlideInClose = () => {
    captureEventsV2([{ eventType: ClientEvent.INITIATIVE_SURVEY_QUICKSTART_MODAL_CLOSED, metaData }]);
    setIsSlideInOpen(false);
  };

  const clientEvents = [
    ClientEvent.INITIATIVE_SURVEY_QUICKSTART_OVERVIEW_CLICKED,
    ClientEvent.INITIATIVE_SURVEY_QUICKSTART_CREATING_QUESTIONNAIRE_CLICKED,
    ClientEvent.INITIATIVE_SURVEY_QUICKSTART_SENDING_QUESTIONNAIRE_CLICKED,
    ClientEvent.INITIATIVE_SURVEY_QUICKSTART_VIEWING_RESPONSE_CLICKED,
  ];
  const metaData = { initiativeId };
  const quickStartTabsData = [
    { label: "Overview", content: <OverviewComponent /> },
    { label: "Creating the questionnaire", content: <CreatingTheQuestionnaireComponent /> },
    { label: "Sending the questionnaire", content: <SendingTheQuestionnaireComponent /> },
    { label: "Viewing responses", content: <ViewingResponsesComponent /> },
  ];

  const handleEventCapture = (tabIndex) => {
    captureEventsV2([{ eventType: clientEvents[tabIndex], metaData }]);
  };

  const handleClick = () => {
    captureEventsV2([{ eventType: ClientEvent.INITIATIVE_SURVEY_QUICKSTART_MODAL_OPENED, metaData }]);
    setIsSlideInOpen(true);
  };

  useEffect(() => {
    const renderVideo = async () => {
      try {
        const player = await VidyardEmbed.api.renderPlayer({
          uuid: "kaTaDgitDqmMuGEEJygJ87",
          container: videoRef.current,
        });
        player.on("ready", () => {
          setIsLoading(false);
        });
      } catch (error) {
        setIsLoading(false);
      }
    };
    renderVideo();
  }, [videoRef]);

  const tooltipTitle = isTrialUser
    ? t("Vendor Questionnaires are not available in the Gartner BuySmart free trial.")
    : t("This action is only available to evaluation owners.");
  return (
    <div className={classNames(classes.root)}>
      <div className={classNames(classes.descriptionTab)}>
        <h3 className={classNames(classes.headerBox)}>
          <span>{t("Send questions and request documents from vendors using")}</span>
          {` `}
          <span className={classNames(classes.orangeText)}>{t("BuySmart Questionnaire.")}</span>
        </h3>
        <ul className={classNames(classes.bulletList)}>
          <li>
            <TypographyComponent styling="p2">
              {t("Streamline information gathering from each vendor in your evaluation.")}
            </TypographyComponent>
          </li>
          <li>
            <TypographyComponent styling="p2">
              {t("Collect responses and documents in one centralized location.")}{" "}
            </TypographyComponent>
          </li>
          <li>
            <TypographyComponent styling="p2">{t("See real-time updates to vendor responses.")} </TypographyComponent>
          </li>
          <li>
            <TypographyComponent styling="p2">
              {t("Make it easier for your team to use vendor responses when evaluating vendors.")}{" "}
            </TypographyComponent>
          </li>
        </ul>
        <div className={classNames(classes.btnTooltipWrapper)}>
          <TooltipV2
            deactivate={!isDisabled && !isTrialUser}
            PopperProps={{ modifiers: { offset: { offset: "0, -6px" } } }}
            placement="left"
            title={tooltipTitle}
          >
            <div className={classes.btnWrapper}>
              <Button disabled={isDisabled || isTrialUser} onClick={onButtonClick} rootClassName="btn-primary">
                {btnText}
              </Button>
            </div>
          </TooltipV2>
        </div>

        <div className={classNames(classes.tutorialLinkBtn)}>
          <MiniButton onClick={handleClick}>
            <InfoLightIcon fillPath={colorPalette.interactions.blueBerry.hex} />
            {t("How questionnaires work")}
          </MiniButton>
        </div>
      </div>
      <div className={classNames(classes.videoTab, !isLoading && "isLoaded")}>
        <div ref={videoRef} />
        {isLoading && (
          <div className={classes.loaderWrapper}>
            <Loader size={48} />
          </div>
        )}
      </div>
      <QuickStartPopperComponent
        title={t("Vendor questionnaire")}
        isOpen={isSlideInOpen}
        onClose={handleSlideInClose}
        tabsData={quickStartTabsData}
        onTabClick={handleEventCapture}
      />
    </div>
  );
};

PreSurveyStartView.propTypes = propTypes;
export default PreSurveyStartView;
