import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  ButtonLoader,
  Dialog,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  Paragraph,
  TypographyComponent,
} from "gx-npm-ui";
import { makeStyles } from "@material-ui/core/styles";
import { startScorecardDialogStyles } from "./start-scorecard-dialog.styles";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import StartScorecardDialogSelection from "./start-scorecard-dialog-selection.component";
import { GCOM_3606__fontUpdate } from "../../../../lib/feature-flags";

const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onCreateScorecard: PropTypes.func,
  reqCount: PropTypes.number,
  catCount: PropTypes.number,
  selectedLevel: PropTypes.string,
  setSelectedLevel: PropTypes.func,
  isLoading: PropTypes.bool,
};
const useStyles = makeStyles(() => startScorecardDialogStyles);
const StartScorecardDialog = ({
  isLoading = false,
  isOpen = false,
  onClose = () => {},

  onCreateScorecard = () => {},
  catCount,
  reqCount,
  setSelectedLevel = () => {},
  selectedLevel = "",
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleCreateScorecard = () => {
    onCreateScorecard();
  };

  return (
    <Dialog
      rootClassName={classNames(classes.root, "gx-score-level-change-dialog")}
      maxDialogWidth={"600px"}
      open={isOpen}
      ariaLabel={t("button confirm change user")}
      title={t("Start Scorecard")}
      body={
        <div className={classNames(classes.bodyContainer)}>
          <div className={classes.subtitle}>
            <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
              <FeatureFlagBooleanOn>
                <TypographyComponent styling={"p2"} boldness={"semi"}>
                  {t(" Set the scoring level.")}
                </TypographyComponent>
              </FeatureFlagBooleanOn>
              <FeatureFlagBooleanOff>
                <Paragraph type="p2" boldness="semi">
                  {t(" Set the scoring level.")}
                </Paragraph>
              </FeatureFlagBooleanOff>
            </FeatureFlagBooleanContainer>
          </div>
          <div className={classes.description}>
            <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
              <FeatureFlagBooleanOn>
                <TypographyComponent styling={"p4"} boldness={"regular"}>
                  {t(
                    "For quicker evaluations, score at the category level. For more in-depth evaluations, score at the requirement level. You’ll have the opportunity to change this once you’ve started the evaluation."
                  )}
                </TypographyComponent>
              </FeatureFlagBooleanOn>
              <FeatureFlagBooleanOff>
                <Paragraph type="p4" boldness="regular">
                  {t(
                    "For quicker evaluations, score at the category level. For more in-depth evaluations, score at the requirement level. You’ll have the opportunity to change this once you’ve started the evaluation."
                  )}
                </Paragraph>
              </FeatureFlagBooleanOff>
            </FeatureFlagBooleanContainer>
          </div>
          <StartScorecardDialogSelection
            countCats={catCount}
            countReqs={reqCount}
            selectedLevel={selectedLevel}
            setSelectedLevel={setSelectedLevel}
          />
        </div>
      }
      footer={
        <div className={classes.footerContainer}>
          <Button ariaLabel={t("button-role-change")} onClick={onClose} rootClassName="btn-tertiary">
            {t("CANCEL")}
          </Button>
          <ButtonLoader
            btnClass="btn-primary"
            isLoading={isLoading}
            ariaLabel={t("button confirm change score level")}
            onClick={handleCreateScorecard}
            rootClassName="primary-destructive-btn"
          >
            {t("START SCORECARD")}
          </ButtonLoader>
        </div>
      }
    />
  );
};

StartScorecardDialog.propTypes = propTypes;
export { StartScorecardDialog };
