import React from "react";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TextLink,
  TypographyComponent,
} from "gx-npm-ui";
import styles from "./assigning-scorers.styles.module.scss";
import AssigningScorersImg from "../../../../../../../assets/images/quick-start/scorecard/assigning-scorers.svg";
import { useTranslation } from "react-i18next";
import { GCOM_3606__fontUpdate } from "../../../../../../../lib/feature-flags";

const AssigningScorersComponent = () => {
  const { t } = useTranslation();

  return (
    <div>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent styling={"h5"} boldness={"semi"}>
            {t("Assigning scorers")}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <TypographyComponent type={"h5"} boldness={"semi"}>
            {t("Assigning scorers")}
          </TypographyComponent>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
      <img src={AssigningScorersImg} alt={t("Assigning scorers")} className={styles.assigningScorersImg} />
      <div className={styles.description}>
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent rootClassName={styles.heading} styling={"p2"} boldness={"semi"}>
              {t("Two modes are available for assigning scorers:")}
            </TypographyComponent>
            <TypographyComponent rootClassName={styles.additionalInfo} styling={"p2"} boldness={"regular"}>
              {t(
                "Automatic Scoring: Team members with the role of Owner or Contributor (current and future) will be given access to score all requirement categories."
              )}
            </TypographyComponent>
            <TypographyComponent rootClassName={styles.additionalInfo} styling={"p2"} boldness={"regular"}>
              {t(
                "Manual Scoring: Owners can manually choose which team members will be able to score specific categories."
              )}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <TypographyComponent rootClassName={styles.heading} type={"p2"} boldness={"semi"}>
              {t("Two modes are available for assigning scorers:")}
            </TypographyComponent>
            <TypographyComponent rootClassName={styles.additionalInfo} type={"p2"} boldness={"regular"}>
              {t(
                "Automatic Scoring: Team members with the role of Owner or Contributor (current and future) will be given access to score all requirement categories."
              )}
            </TypographyComponent>
            <TypographyComponent rootClassName={styles.additionalInfo} type={"p2"} boldness={"regular"}>
              {t(
                "Manual Scoring: Owners can manually choose which team members will be able to score specific categories."
              )}
            </TypographyComponent>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
        <div className={styles.helpLink}>
          <TextLink
            text={t("Read more about scoring assignments")}
            target={"_blank"}
            href={
              "https://intercom.help/gartner-buysmart-help-center/en/articles/6276044-how-scoring-works#h_da9ee05998"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default AssigningScorersComponent;
