import { colorPalette, weightPalette } from "gx-npm-common-styles";

const colorWhite = colorPalette.basic.white.hex;
const variationSemiBold = weightPalette.semiBold.variation;
const weightSemiBold = weightPalette.semiBold.amount;

const statusCardV2Styles = {
  statusCardRoot: {
    borderRadius: "8px",
    backgroundColor: colorWhite,
    display: "inline-block",
    height: "401px",
    minWidth: "308px",
    padding: "28px 0 0 32px",
    position: "relative" as const,
    "& .gx-planning-polygon-checklist": {
      clipPath: "polygon(0 100%, 100% 94%, 100% 100%, 0% 100%)",
      width: "100%",
      backgroundColor: colorPalette.interactions.lightBerry.hex,
      position: "absolute" as const,
      height: "100%",
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
      bottom: 0,
      left: 0,
    },
    "& .gx-planning-polygon-requirements": {
      clipPath: "polygon(0 94%, 100% 86%, 100% 100%, 0% 100%)",
      width: "100%",
      backgroundColor: colorPalette.interactions.lightBerry.hex,
      position: "absolute" as const,
      height: "100%",
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
      bottom: 0,
      left: 0,
    },
    "& .gx-planning-polygon-vendors": {
      clipPath: "polygon(0 86%, 100% 74%, 100% 100%, 0% 100%)",
      width: "100%",
      backgroundColor: colorPalette.interactions.lightBerry.hex,
      position: "absolute" as const,
      height: "100%",
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
      bottom: 0,
      left: 0,
    },
    "& .gx-planning-status-btn": {
      color: colorWhite,
      marginTop: "169px",
    },
    "& .gx-planning-status-btn-outlined": {
      marginTop: 55,
    },
    "& .gx-planning-image-vendors": {
      "& img": {
        bottom: "60px",
        zIndex: 100,
      },
    },
    "& button": {
      zIndex: 1000,
    },
    "& img": {
      bottom: "93px",
      position: "absolute" as const,
      right: 0,
    },
    "@media (max-width:1239px)": {
      width: "calc(50% - 12px)",
    },
    "@media (min-width:1240px)": {
      width: "calc(33.33% - 16px)",
    },

    "&:not(:first-child)": {
      "@media (min-width:1240px)": {
        marginLeft: "24px",
      },
    },
    "&:first-child": {
      "@media (max-width:1239px)": {
        marginRight: "24px",
        marginBottom: "24px",
      },
    },
  },
  statusCardRootV2: {
    padding: "32px 40px",
    height: 287,
    minWidth: "unset",
    width: "calc(33.33% - 16px)",

    "@media (max-width:1239px)": {
      width: "calc(33.33% - 16px)",
      padding: "32px 32px",
    },

    "&:first-child": {
      "@media (max-width:1239px)": {
        marginRight: "unset",
        marginBottom: "unset",
      },
    },
    "&:not(:first-child)": {
      marginLeft: 24,
      "@media (max-width:1239px)": {
        marginLeft: 24,
      },
    },
  },
  "& .gx-planning-polygon-checklist": {
    clipPath: "polygon(0 100%, 100% 85%, 100% 100%, 0% 100%)",
    width: "100%",
    backgroundColor: colorPalette.interactions.lightBerry.hex,
    position: "absolute" as const,
    height: "100%",
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    bottom: 0,
    left: 0,
  },
  planningDataDisplay: {
    display: "flex",
    flexDirection: "column" as const,
    height: "100%",
    paddingBottom: "28px",
    "& .gx-planning-data-card-title": {
      paddingBottom: "28px",
    },
  },
  statusCardSubtitle: {
    fontSize: "14px",
    fontVariationSettings: variationSemiBold,
    fontWeight: weightSemiBold,
    letterSpacing: "0.25px",
    lineHeight: "21px",
    marginBottom: "auto",
  },
  notActiveStatus: {
    "&$statusCardRoot": {
      height: "144px",
    },
  },
};

export { statusCardV2Styles };
