import { useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { SelectionContext } from "../../../../selection.context";
import styles from "./evaluation-details.module.scss";
import EvaluationDetailsHeader from "./components/evaluation-details-header/evaluation-details-header.component";
import RequirementCategories from "./components/requirement-categories/requirement-categories.component";
import { GCOM_3606__fontUpdate } from "../../../../../../lib/feature-flags";

const EvaluationDetails = () => {
  const { t } = useTranslation();
  const { requirementCategories, awardedProduct } = useContext(SelectionContext);

  return (
    <div className={styles.root}>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent boldness={"semi"} styling={"p1"}>
            {t("Evaluation details")}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <TypographyComponent boldness="semi" type="p1">
            {t("Evaluation details")}
          </TypographyComponent>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
      {awardedProduct && <EvaluationDetailsHeader awardedProduct={awardedProduct} />}
      <>{requirementCategories && <RequirementCategories requirementCategories={requirementCategories} />}</>
    </div>
  );
};

export default EvaluationDetails;
