import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { FileTypeXlsIcon, FileTypeXlsxIcon } from "gx-npm-icons";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  PopoverMenu,
  TooltipV2,
  TypographyComponent,
  UsernameDisplay,
} from "gx-npm-ui";
import { fileTypes } from "../lib";
import { timeFromDateFormat } from "gx-npm-lib";
import { libraryListRowStyles as styles } from "./styles";
import { GCOM_3606__fontUpdate } from "../../../../../lib/feature-flags";

const propTypes = {
  fileName: PropTypes.string,
  fileType: PropTypes.string,
  id: PropTypes.string,
  onMenuClick: PropTypes.func,
  reqCount: PropTypes.number,
  uploadBy: PropTypes.string,
  uploadDate: PropTypes.string,
};
const menuItems = [
  { index: 0, name: "Add file to requirements", event: "ADD" },
  { index: 1, name: "Download", event: "DOWNLOAD" },
  { index: 2, name: "Delete", event: "DELETE" },
];
const useStyles = makeStyles(() => styles);
const ImportLibraryListRow = ({
  fileName = "",
  fileType = "",
  id = "",
  onMenuClick = (_event, _id, _fileName) => {},
  reqCount = 0,
  uploadBy = "",
  uploadDate = "",
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const classes = useStyles();
  const handleClick = (event) => {
    onMenuClick(menuItems[event].event, id, fileName);
  };

  return (
    <div
      className={classNames(classes.libListRowWrap, isHovered && "hover")}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={classes.libListRowIcon}>
        {[fileTypes.xls, fileTypes.xlsx].indexOf(fileType) > -1 && (
          <TooltipV2
            placement="top"
            PopperProps={{ modifiers: { offset: { offset: "3px, 10px" } } }}
            title={`.${fileType.toLocaleLowerCase()} file`}
          >
            <div>
              {fileType === fileTypes.xls && <FileTypeXlsIcon />}
              {fileType === fileTypes.xlsx && <FileTypeXlsxIcon />}
            </div>
          </TooltipV2>
        )}
      </div>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent
            boldness={"semi"}
            styling={"p3"}
            color={"carbon"}
            rootClassName={classNames(classes.libListRowTitleGCOM3606)}
          >
            {fileName}
          </TypographyComponent>
          <TypographyComponent
            styling={"p4"}
            color={"iron"}
            rootClassName={classNames(classes.libListRowCountGCOM3606)}
          >
            <TypographyComponent boldness={"semi"}>{reqCount}</TypographyComponent>
            <TypographyComponent>{` requirements`}</TypographyComponent>
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <p className={classNames("p3", "semi-bold", classes.libListRowTitle)}>{fileName}</p>
          <p className={classNames("p4", classes.libListRowCount)}>
            <span className="semi-bold">{reqCount}</span>
            <span>{` requirements`}</span>
          </p>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
      <div className={classes.libListRowAvatarDate}>
        <TooltipV2 title={uploadBy} placement="top">
          <div>
            <UsernameDisplay isColorInvertedOnHover={true} name={uploadBy} showName={false} />
          </div>
        </TooltipV2>

        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent styling={"p4"}>{timeFromDateFormat(uploadDate)}</TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <p className="p4">{timeFromDateFormat(uploadDate)}</p>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      </div>
      <div className={classes.libListRowPopover}>
        <PopoverMenu
          iconType="kebab"
          showSelectedOption={false}
          useIconButton={true}
          menuItems={menuItems}
          onClick={handleClick}
        />
      </div>
    </div>
  );
};

ImportLibraryListRow.propTypes = propTypes;
export default ImportLibraryListRow;
