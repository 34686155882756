import classNames from "classnames";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  Paragraph,
  ProductLogo,
  TypographyComponent,
} from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import statusCompletedIcon from "../../../../../../assets/images/statusCompleted.svg";
import { ProductsAddCustomOption } from "../../../../products.types";
import styles from "./products-add-dialog-body-option-v2.styles.module.scss";
import { GCOM_3606__fontUpdate } from "../../../../../../lib/feature-flags";

const ProductsAddDialogBodyOptionV2Component = ({
  imageLoc,
  isInEvaluation,
  isLogoDisplayed,
  message,
  piProductName,
}: ProductsAddCustomOption) => {
  const { t } = useTranslation();

  return (
    <div className={styles.optionsWrapper}>
      {isLogoDisplayed && (
        <div className={styles.logo}>
          <ProductLogo imageHeight="24px" imageWidth="24px" logo={imageLoc} name={piProductName} />
        </div>
      )}
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent
            rootClassName={classNames(isLogoDisplayed && styles.title, isInEvaluation && styles.alreadyAdded)}
            element={"p"}
          >
            {piProductName || message}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <Paragraph rootClassName={classNames(isLogoDisplayed && styles.title, isInEvaluation && styles.alreadyAdded)}>
            {piProductName || message}
          </Paragraph>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>

      {isInEvaluation && (
        <div className={styles.addedVendor}>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent styling={"p4"}>{t("Added")}</TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <Paragraph type="p4">{t("Added")}</Paragraph>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
          <div className={styles.imageContainer}>
            <img src={statusCompletedIcon} alt={t("vendor added icon")} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductsAddDialogBodyOptionV2Component;
