import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { InitProdState } from "gx-npm-lib";
import {
  Button,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  MiniButton,
  TooltipV2,
  TypographyComponent,
  useFeatureFlag,
} from "gx-npm-ui";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { surveyStatuses } from "../../../lib";
import Manage from "./people.svg";
import Send from "./send.svg";
import ManageSurvey from "./peopleSurvey.svg";
import SendSurvey from "./sendSurvey.svg";
import styles from "./vendors-surveylist-view.styles";
import VendorIconAndTextLink from "./vendor-icon-and-text-link/vendor-icon-and-text-link.component";
import { GCOM_3606__fontUpdate, GCOM_3695_ctaButtonColorUpdate } from "../../../lib/feature-flags";

const useStyles = makeStyles(() => styles);
const propTypes = {
  initiativeId: PropTypes.string,
  isDisabled: PropTypes.bool,
  onClickAction: PropTypes.func,
  vendors: PropTypes.arrayOf(
    PropTypes.shape({
      hasRecipients: PropTypes.bool,
      id: PropTypes.string,
      imageLoc: PropTypes.string,
      name: PropTypes.string,
      surveyStatus: PropTypes.oneOf(Object.values(surveyStatuses)),
    })
  ),
};
const VendorsSurveyListView = ({
  isDisabled = false,
  onClickAction = (_data = { action: "", id: "" }) => {},
  vendors = [],
  initiativeId = "",
}) => {
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.surveyList}>
      <div className={classes.surveyListHeader}>
        <div className={classes.productNameCol}>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent styling={"p4"} boldness={"medium"}>
                {t("Vendor")}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <TypographyComponent type="p4" boldness="medium">
                {t("Vendor")}
              </TypographyComponent>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </div>
        <div className={classNames(classes.surveyDataTitle, classes.headerAdjustment)}>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent rootClassName={"gx-survey-list-access-header"} styling={"p4"} boldness={"medium"}>
                {t("Access")}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <TypographyComponent rootClassName={"gx-survey-list-access-header"} type="p4" boldness="medium">
                {t("Access")}
              </TypographyComponent>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </div>
        <div className={classes.surveyDataTitle}>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent rootClassName={"gx-survey-list-status-header"} styling={"p4"} boldness={"medium"}>
                {t("Status")}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <TypographyComponent rootClassName={"gx-survey-list-status-header"} type="p4" boldness="medium">
                {t("Status")}
              </TypographyComponent>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </div>
      </div>

      {vendors.map((item, index) => {
        const { hasRecipients, id, imageLoc, name, surveyStatus } = item;
        const isScreenedOut = item.productState === InitProdState.SCREENED_OUT;
        return (
          <div
            key={id}
            className={classNames(
              classes.surveyListItem,
              isScreenedOut && classes.surveyListItemScreenedOut,
              index === vendors.length - 1 && "gx-last-survey-product"
            )}
          >
            <div className={classes.productNameCol}>
              <VendorIconAndTextLink
                initiativeId={initiativeId}
                id={id}
                imageLoc={imageLoc}
                name={name}
                isScreenedOut={isScreenedOut}
              />
            </div>
            <div className={classes.surveyData}>
              <TooltipV2
                deactivate={!isDisabled}
                placement="left"
                title={t("This action is only available to evaluation owners.")}
              >
                <div>
                  <MiniButton
                    disabled={isDisabled}
                    onClick={() => onClickAction({ action: "manage", id, imageLoc, name })}
                  >
                    <img
                      alt=""
                      src={hasRecipients ? (isFFGCOM3695 ? ManageSurvey : Manage) : isFFGCOM3695 ? SendSurvey : Send}
                    />
                    {` ${hasRecipients ? t("Manage recipients") : t("Send questionnaire")}`}
                  </MiniButton>
                </div>
              </TooltipV2>
            </div>
            <div className={classes.surveyData}>
              {surveyStatus === surveyStatuses.NONE && (
                <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                  <FeatureFlagBooleanOn>
                    <TypographyComponent boldness={"medium"} styling={"p3"}>
                      &mdash;
                    </TypographyComponent>
                  </FeatureFlagBooleanOn>
                  <FeatureFlagBooleanOff>
                    <TypographyComponent boldness="medium" type="p3">
                      &mdash;
                    </TypographyComponent>
                  </FeatureFlagBooleanOff>
                </FeatureFlagBooleanContainer>
              )}
              {surveyStatus === surveyStatuses.NOT_STARTED && (
                <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                  <FeatureFlagBooleanOn>
                    <TypographyComponent boldness={"medium"} styling={"p3"}>
                      {t("Not started")}
                    </TypographyComponent>
                  </FeatureFlagBooleanOn>
                  <FeatureFlagBooleanOff>
                    <TypographyComponent boldness="medium" type="p3">
                      {t("Not started")}
                    </TypographyComponent>
                  </FeatureFlagBooleanOff>
                </FeatureFlagBooleanContainer>
              )}
              {surveyStatus === surveyStatuses.IN_PROGRESS && (
                <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                  <FeatureFlagBooleanOn>
                    <TypographyComponent boldness={"medium"} styling={"p3"}>
                      {t("In progress")}
                    </TypographyComponent>
                  </FeatureFlagBooleanOn>
                  <FeatureFlagBooleanOff>
                    <TypographyComponent boldness="medium" type="p3">
                      {t("In progress")}
                    </TypographyComponent>
                  </FeatureFlagBooleanOff>
                </FeatureFlagBooleanContainer>
              )}
              {surveyStatus === surveyStatuses.SUBMITTED && (
                <Button
                  onClick={() => onClickAction({ action: "view", id })}
                  rootClassName="btn-secondary gx-view-responses-btn"
                >
                  {t("View responses")}
                </Button>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

VendorsSurveyListView.propTypes = propTypes;
export default VendorsSurveyListView;
