import React from "react";
import { useTranslation } from "react-i18next";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import styles from "./adding-scores.styles.module.scss";
import AddingScoresImg from "../../../../../../../assets/images/quick-start/scorecard/adding-scores.svg";
import { GCOM_3606__fontUpdate } from "../../../../../../../lib/feature-flags";

const AddingScoresComponent = () => {
  const { t } = useTranslation();

  return (
    <>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent styling={"h5"} boldness={"semi"}>
            {t("Adding scores")}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <TypographyComponent type={"h5"} boldness={"semi"}>
            {t("Adding scores")}
          </TypographyComponent>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
      <img src={AddingScoresImg} alt={t("Adding scores")} className={styles.addingScoresImg} />
      <div className={styles.description}>
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent rootClassName={styles.heading} styling={"p2"} boldness={"semi"}>
              {t(
                "Every team member who has been assigned to score will have access to their own version of the scoresheet - available in the ‘My Scoresheet’ tab of the vendor workspace."
              )}
            </TypographyComponent>
            <TypographyComponent rootClassName={styles.additionalInfo} styling={"p2"} boldness={"regular"}>
              {t(
                "Comments and key takeaways on the scoresheet are private, but can be seen by anyone who exports the scorecard."
              )}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <TypographyComponent rootClassName={styles.heading} type={"p2"} boldness={"semi"}>
              {t(
                "Every team member who has been assigned to score will have access to their own version of the scoresheet - available in the ‘My Scoresheet’ tab of the vendor workspace."
              )}
            </TypographyComponent>
            <TypographyComponent rootClassName={styles.additionalInfo} type={"p2"} boldness={"regular"}>
              {t(
                "Comments and key takeaways on the scoresheet are private, but can be seen by anyone who exports the scorecard."
              )}
            </TypographyComponent>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      </div>
    </>
  );
};

export default AddingScoresComponent;
