import {
  Button,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  Paragraph,
  TooltipV2,
  TypographyComponent,
  UsernameDisplay,
  useFeatureFlag,
} from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { GCOM_3695_ctaButtonColorUpdate, GCOM_3606__fontUpdate } from "../../../lib/feature-flags";

type ListAvatarType = {
  disabled: boolean;
  rootClassName: string;
  isSelected: boolean;
  onClick: (event: MouseEvent) => void;
  avatarList: AvatarType[];
  width: string;
  setEdit?: (edit: boolean) => void;
  toolTipRootClassName?: string;
};

type AvatarType = {
  email: string;
  fullName: string;
  hasViewed: boolean;
};

export const ListAvatar = (props: ListAvatarType) => {
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);
  const MAX_AVATAR = 5;
  const { t } = useTranslation();
  const [isSelected, setIsSelected] = useState(false);
  const [enableTooltip, setEnableTooltip] = useState(true);

  const getRemainingTitles = () => {
    return props.avatarList
      .slice(MAX_AVATAR, props.avatarList.length)
      .map((avatar) => <div>{avatar.fullName || avatar.email}</div>);
  };

  const getTitle = () => {
    if (props.disabled) {
      return t("This action is only available to evaluation owners and contributors.");
    }

    return !isSelected && enableTooltip
      ? props.avatarList.length > 0
        ? t("Edit assignments")
        : t("Assign team member")
      : "";
  };

  useEffect(() => {
    if (isSelected && !props.isSelected) {
      (document.activeElement as HTMLElement).blur();
      props.setEdit?.(false);
      setIsSelected(false);
    }
  }, [isSelected, props]);

  return (
    <TooltipV2
      title={getTitle()}
      deactivate={false}
      placement={"top"}
      enterDelay={500}
      enterNextDelay={500}
      rootClassName={props.toolTipRootClassName}
    >
      <div style={{ width: props.width }}>
        <Button
          disabled={props.disabled}
          rootClassName={classNames(
            props.rootClassName,
            isSelected && classNames("popper-active", isFFGCOM3695 && "popper-activeGCOM3695")
          )}
          onClick={(event) => {
            event.target.skipRefElement = true;
            props.onClick(event);
            setIsSelected(true);
            setTimeout(() => props.setEdit?.(true));
          }}
        >
          {props.avatarList.length > 0
            ? props.avatarList.slice(0, MAX_AVATAR).map((avatar) => {
                return (
                  <TooltipV2
                    title={avatar.hasViewed ? avatar.fullName : avatar.email}
                    key={"avatar--" + avatar.email}
                    placement="top"
                  >
                    <span
                      className={"avatar-container"}
                      onMouseOver={() => setEnableTooltip(false)}
                      onFocus={() => setEnableTooltip(false)}
                      onMouseOut={() => setEnableTooltip(true)}
                      onBlur={() => setEnableTooltip(true)}
                    >
                      <UsernameDisplay
                        isSmallerIcon={true}
                        showName={false}
                        isUnknown={!avatar.hasViewed}
                        name={avatar.fullName}
                      />
                    </span>
                  </TooltipV2>
                );
              })
            : !props.disabled && (
                <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                  <FeatureFlagBooleanOn>
                    <TypographyComponent styling={"p4"}>{t("Assign")}</TypographyComponent>
                  </FeatureFlagBooleanOn>
                  <FeatureFlagBooleanOff>
                    <Paragraph type={"p4"}>{t("Assign")}</Paragraph>
                  </FeatureFlagBooleanOff>
                </FeatureFlagBooleanContainer>
              )}
          {props.avatarList.length > MAX_AVATAR && (
            <TooltipV2 title={getRemainingTitles()} placement="top">
              <span
                className={"avatar-container"}
                onMouseOver={() => setEnableTooltip(false)}
                onFocus={() => setEnableTooltip(false)}
                onMouseOut={() => setEnableTooltip(true)}
                onBlur={() => setEnableTooltip(true)}
              >
                <UsernameDisplay
                  isSmallerIcon={true}
                  showName={false}
                  isUnknown={false}
                  invertColor={true}
                  isGreyScaled={true}
                  name={`+ ${(props.avatarList.length - MAX_AVATAR).toString()}`}
                />
              </span>
            </TooltipV2>
          )}
        </Button>
      </div>
    </TooltipV2>
  );
};

export default ListAvatar;
