import React, { Fragment, useContext, useEffect, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import * as singleSpa from "single-spa";
import { useParams } from "react-router-dom";
import { GroupAddIcon } from "gx-npm-icons";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  MiniButton,
  Paragraph,
  TooltipV2,
  TypographyComponent,
  useFeatureFlag,
  XDatePicker,
} from "gx-npm-ui";
import { putAsyncRequest, UUID } from "gx-npm-lib";
import { updateStateSubject } from "gx-npm-messenger-util";
import { planningContentSectionV2Styles as styles } from "./planning-content-section-v2.styles";
import { makeStyles } from "@material-ui/core";
import { OverviewContext } from "../../overview.context";
import BudgetTextFieldV2 from "./budget-text-field-v2.component";
import ObjectivesTextFieldV2 from "./objectives-text-field-v2.component";
import TeamMembersV2 from "./team-members-v2.component";
import { updateEditCount } from "./planning-card-lib";
import {
  GCOM_3477_3679__layoutChanges,
  GCOM_3477_3681__overviewSection,
  GCOM_3695_ctaButtonColorUpdate,
  GCOM_3606__fontUpdate,
} from "../../../../lib/feature-flags";

type PlanningContentSectionV2Props = {
  initiativeId: UUID;
  isViewOnly: boolean;
};

const useStyles = makeStyles(() => styles);
const PlanningContentSectionV2 = ({ initiativeId, isViewOnly = false }: PlanningContentSectionV2Props) => {
  const { teamMembers, vendorSelectionDate, setIsSnackbarOpen, setOverviewEditCount, setVendorSelectionDate } =
    useContext(OverviewContext);
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);
  const [currentTab, setCurrentTab] = useState("");
  const { tab } = useParams();
  const updateUrl = `api/v3/initiatives/${initiativeId}`;
  const DEFAULT_DATE = "1970-01-01";
  const isGCOM3681FF = useFeatureFlag(GCOM_3477_3679__layoutChanges);
  const { t } = useTranslation();

  useEffect(() => {
    if (!tab) {
      return;
    }
    setCurrentTab(tab);
  }, [tab]);

  const handleDatePickerChange = async (value: string) => {
    try {
      updateEditCount(setOverviewEditCount, 1);
      const putResponse = await putAsyncRequest(updateUrl, { vendorSelectionDate: value });
      if (putResponse.status !== 200) {
        throw new Error();
      }
    } catch (err) {
      setIsSnackbarOpen(true);
    } finally {
      updateEditCount(setOverviewEditCount, -1);
    }
  };

  const handleClickShare = () => {
    updateStateSubject("EVAL_HEADER_SOURCE_PAGE", { sourcePage: "eval overview" });
    singleSpa.navigateToUrl(`/s/evaluation/${initiativeId}/${currentTab}/team-management`);
  };

  const classes = useStyles();
  return (
    <Fragment>
      <div className={classNames(classes.planContentRoot)}>
        <div className={classNames(classes.planContent, classes.planContentObjectives)}>
          <div className={classNames(isGCOM3681FF ? classes.GCOM3681ObjectivesWrapper : classes.objectivesWrapper)}>
            <div className={classNames(classes.planTitle)}>{t("Objectives")}</div>
            <ObjectivesTextFieldV2 disabled={isViewOnly} initiativeId={initiativeId} />
          </div>
          <div className={classNames(classes.budgetAndDatePickerWrapper)}>
            <div className={classNames(classes.planBudgetSpacer)}>
              <div className={classNames(classes.planTitle)}>{t("Budget")}</div>
              <div>
                <BudgetTextFieldV2 disabled={isViewOnly} initiativeId={initiativeId} />
              </div>
            </div>
            <div className={classNames(classes.datePickerWrapper)}>
              <div className={classNames(isGCOM3681FF ? classes.planTitle : !vendorSelectionDate && classes.planTitle)}>
                {t("Vendor selection date")}
              </div>
              <div
                className={classNames(
                  classes.calendar,
                  isGCOM3681FF && classes.calendarGCOM3681FF,
                  isFFGCOM3695 && classes.calendarGCOM3695
                )}
              >
                <XDatePicker
                  showClearButton={true}
                  value={vendorSelectionDate === DEFAULT_DATE ? null : vendorSelectionDate}
                  placeholder={"Set Date"}
                  disabled={isViewOnly}
                  toolTipPlacement={"bottom"}
                  onChange={(value: { isValid: () => never; toISOString: () => never }) => {
                    const formattedDate = value && value.isValid() ? value.toISOString() : DEFAULT_DATE;
                    setVendorSelectionDate(formattedDate);
                    handleDatePickerChange(formattedDate);
                  }}
                  toolTipText={t("This action is only available to evaluation owners.")}
                  showToolTip={isViewOnly}
                />
              </div>
            </div>
          </div>
        </div>
        <FeatureFlagBooleanContainer flagName={GCOM_3477_3681__overviewSection}>
          <FeatureFlagBooleanOff>
            <div className={classNames(classes.planContent, classes.planContentTeam)}>
              <div className={classNames(classes.teamManagementTitle)}>
                <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                  <FeatureFlagBooleanOn>
                    <TypographyComponent boldness={"semi"} styling={"p3"}>
                      {t("Team")}
                    </TypographyComponent>
                  </FeatureFlagBooleanOn>
                  <FeatureFlagBooleanOff>
                    <Paragraph boldness="semi" type="p3">
                      {t("Team")}
                    </Paragraph>
                  </FeatureFlagBooleanOff>
                </FeatureFlagBooleanContainer>

                <TooltipV2
                  PopperProps={{
                    modifiers: { offset: { offset: "5, 5" } },
                  }}
                  deactivate={!isViewOnly}
                  placement="left-end"
                  title={t("This action is only available to evaluation owners.")}
                >
                  <div>
                    <MiniButton disabled={isViewOnly} onClick={handleClickShare}>
                      <GroupAddIcon />
                      {t("Share")}
                    </MiniButton>
                  </div>
                </TooltipV2>
              </div>
              <TeamMembersV2 memberList={teamMembers} />
            </div>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      </div>
    </Fragment>
  );
};
export default PlanningContentSectionV2;
