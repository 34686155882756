import { colorPalette, weightPalette } from "gx-npm-common-styles";

const SurveyHeaderStyles = {
  surveyHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .gx-tutorial-link": {
      marginLeft: "22px",
      marginBottom: "22px",
      paddingLeft: "22px",
      borderLeft: "1px solid " + colorPalette.neutrals.stone.hex,
      "& img": {
        marginRight: "8px",
      },
    },
  },
  surveyHeaderTitle: {
    display: "flex",
    alignItems: "center",
    "& h3": {
      marginBottom: "24px",
    },
  },
  setupButton: {
    marginBottom: "22px",
  },
  exportButtonVerticalLine: {
    borderRight: `1px solid ${colorPalette.neutrals.stone.hex}`,
    height: "30px",
    marginLeft: 24,
    marginRight: 24,
  },
  setupExportDropdown: { marginBottom: "0px" },
  surveyExportButton: {
    display: "flex",
    alignItems: "baseline",
    "& .gx-questionnaire-setup-button .gx-popover-menu-selected": {
      width: "auto",
      "& .no-current-selection": {
        fontSize: "14px",
        fontWeight: weightPalette.semiBold.amount,
        lineHeight: "21px",
        letterSpacing: "0.25px",
        textAlign: "right",
        color: colorPalette.neutrals.carbon.hex,
        fontVariationSettings: weightPalette.semiBold.variation,
      },
    },
  },
  surveyExportButtonGCOM3606: {
    display: "flex",
    alignItems: "baseline",
    "& .gx-questionnaire-setup-button .gx-popover-menu-selected": {
      width: "auto",
    },
  },
  surveyExportButtonDisable: {
    color: `${colorPalette.neutrals.iron.hex} !important`,
    cursor: "auto",
    pointerEvents: "none",
  },
  popoverItemClass: {
    borderBottom: `1px solid ${colorPalette.neutrals.silver.hex}`,
    marginBottom: 8,
    paddingBottom: 8,
  },
  popover: {
    left: 12,
    boxShadow: `0px 3px 3px 1px rgba(20, 19, 18, 0.1)`,
    "& > ul": {
      padding: "8px 0",
      "& > li": {
        "&:hover": {
          backgroundColor: colorPalette.interactions.lightBerry.hex,
        },
        "& > a": {
          height: 28,
          lineHeight: "normal",
        },
      },
    },
  },
};

export default SurveyHeaderStyles;
