import { Collapse } from "@material-ui/core";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { TableCarouselBodyCol } from "../../../../ui/tableCarousel/body";
import EvaluationPopper from "./evaluation-popper/evaluation-popper.component";
import { evalPopperTypes } from "./evaluation-popper/evaluation-popper.type";
import { ScoringLevel } from "gx-npm-lib";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
  useFeatureFlag,
} from "gx-npm-ui";
import { GCOM_3606__fontUpdate } from "../../../../lib/feature-flags";

const propTypes = {
  catId: PropTypes.string,
  catName: PropTypes.string,
  initId: PropTypes.string,
  isCarouselPopperOpen: PropTypes.bool,
  isRequirementExpanded: PropTypes.bool,
  isScreenedOut: PropTypes.bool,
  onOpenPopper: PropTypes.func,
  prodImageLoc: PropTypes.string,
  prodInitId: PropTypes.string,
  prodName: PropTypes.string,
  requirementItems: PropTypes.array,
  rootClassName: PropTypes.string,
  scoreCategory: PropTypes.number,
  scoreCategoryDisplay: PropTypes.string,
  scoreMapRequirementItems: PropTypes.object,
  scoringLevel: PropTypes.oneOf(["", ...Object.values(ScoringLevel)]),
};
const ProductScoreCarouselColumn = ({
  catId = "",
  catName = "",
  initId = "",
  isCarouselPopperOpen = false,
  isRequirementExpanded = false,
  isScreenedOut = false,
  onOpenPopper = (_isOpen) => {},
  prodImageLoc = "",
  prodInitId = "",
  prodName = "",
  requirementItems = [],
  rootClassName = "",
  scoreCategory = 0,
  scoreCategoryDisplay = "",
  scoreMapRequirementItems = {},
  scoringLevel = ScoringLevel.REQUIREMENT,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [hoveredCellId, setHoveredCellId] = useState("");
  const [isCatCellHovered, setIsCatCellHovered] = useState(false);
  const [isCatPopperOpen, setIsCatPopperOpen] = useState(false);
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [isScored, setIsScored] = useState(false);
  const [itemId, setItemId] = useState("");
  const [popperSubTitle, setPopperSubTitle] = useState("");
  const [popperTitle, setPopperTitle] = useState("");
  const [popperType, setPopperType] = useState("");
  const isGCOM3606FF = useFeatureFlag(GCOM_3606__fontUpdate);
  const handleClosePopper = () => {
    onOpenPopper(false);
    setIsCatPopperOpen(false);
    setIsPopperOpen(false);
    setIsScored(false);
    setAnchorEl(null);
    setItemId("");
    setPopperType("");
    setPopperSubTitle("");
    setPopperTitle("");
  };

  const handleHoverEnterItemCell = (cellId) => {
    if (!isCarouselPopperOpen) {
      setHoveredCellId(cellId);
    }
  };

  const handleHoverExitItemCell = () => {
    setHoveredCellId("");
  };

  const handleHoverEnterCategoryCell = () => {
    setIsCatCellHovered(!isCarouselPopperOpen);
  };
  const handleHoverExitCategoryCell = () => {
    setIsCatCellHovered(false);
  };

  const handleKeyDownCategoryPopper = (event) => {
    if (event.key === "Enter") {
      handleOpenCategoryPopper(event);
    }
  };

  const handleOpenCategoryPopper = (event) => {
    if (isCarouselPopperOpen) {
      return;
    }
    let display = "";
    let type = evalPopperTypes.CATEGORY_PROGRESS;
    if (scoringLevel === ScoringLevel.CATEGORY) {
      type = evalPopperTypes.CATEGORY_SCORE;
      display =
        !isNaN(Number(scoreCategoryDisplay)) && scoreCategoryDisplay.indexOf(".") === -1
          ? `${scoreCategoryDisplay}.0`
          : scoreCategoryDisplay;
      setIsScored(scoreCategory > 0);
    }
    setIsCatPopperOpen(true);
    setPopperTitle(catName);
    setPopperSubTitle(display);
    setPopperType(type);
    handleOpenPopper(event?.currentTarget);
  };

  const handleKeyDownItemPopper = (id, name, event) => {
    if (event.key === "Enter") {
      handleOpenItemPopper(id, name, event);
    }
  };

  const handleOpenItemPopper = (id, name, event) => {
    if (isCarouselPopperOpen || scoringLevel !== ScoringLevel.REQUIREMENT) {
      return;
    }
    setPopperType(evalPopperTypes.ITEM_SCORE);
    setPopperTitle(name);
    setItemId(id);
    const { itemScore, itemScoreDisplay } = scoreMapRequirementItems?.[id] || {};
    setIsScored(itemScore > 0);
    setPopperSubTitle(itemScoreDisplay);
    handleOpenPopper(event?.currentTarget);
  };

  const handleOpenPopper = (element) => {
    onOpenPopper(true);
    setIsPopperOpen(true);
    setAnchorEl(element);
  };

  const cursorClassName = !isCarouselPopperOpen && "gx-cursor-pointer";
  const wrapperDivClassName = classNames(
    "gx-req-cat-col",
    "gx-req-root-category-score",
    "gx-set-element-height",
    isCatPopperOpen && "selected",
    isCatCellHovered && !isCatPopperOpen && "hovered",
    isRequirementExpanded && "expanded",
    cursorClassName
  );
  const getLiClassName = (id) => {
    return classNames(
      "gx-req-item-col",
      isGCOM3606FF ? "gx-req-item-td-GCOM3606" : "gx-req-item-td",
      "gx-set-element-height",
      scoringLevel === ScoringLevel.REQUIREMENT && cursorClassName,
      id === itemId && "selected",
      id === hoveredCellId && id !== itemId && "hovered"
    );
  };
  return (
    <TableCarouselBodyCol rootClass={classNames(rootClassName, isScreenedOut && "gx-screened-out-bk-gnd")}>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <div
            className={wrapperDivClassName}
            onBlur={handleHoverExitCategoryCell}
            onClick={handleOpenCategoryPopper}
            onFocus={handleHoverEnterCategoryCell}
            onKeyDown={handleKeyDownCategoryPopper}
            onMouseEnter={handleHoverEnterCategoryCell}
            onMouseOut={handleHoverExitCategoryCell}
            role="button"
            tabIndex={0}
          >
            <TypographyComponent boldness={"semi"}>{scoreCategoryDisplay}</TypographyComponent>
          </div>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <div
            className={wrapperDivClassName}
            onBlur={handleHoverExitCategoryCell}
            onClick={handleOpenCategoryPopper}
            onFocus={handleHoverEnterCategoryCell}
            onKeyDown={handleKeyDownCategoryPopper}
            onMouseEnter={handleHoverEnterCategoryCell}
            onMouseOut={handleHoverExitCategoryCell}
            role="button"
            tabIndex={0}
          >
            {scoreCategoryDisplay}
          </div>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
      {requirementItems?.length > 0 && (
        <Collapse className="gx-req-item-score-container" in={isRequirementExpanded}>
          <ul className="gx-req-item-list-td">
            {requirementItems.map((item) => (
              <li key={item.id} className={getLiClassName(item.id)}>
                <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                  <FeatureFlagBooleanOn>
                    <div
                      aria-disabled={scoringLevel !== ScoringLevel.REQUIREMENT}
                      className="gx-req-item-col-child"
                      onBlur={handleHoverExitItemCell}
                      onClick={(event) => handleOpenItemPopper(item.id, item.name, event)}
                      onFocus={() => handleHoverEnterItemCell(item.id)}
                      onKeyDown={(event) => handleKeyDownItemPopper(item.id, item.name, event)}
                      onMouseEnter={() => handleHoverEnterItemCell(item.id)}
                      onMouseOut={handleHoverExitItemCell}
                      role="button"
                      tabIndex={0}
                    >
                      <TypographyComponent styling={"p1"} boldness={"medium"} color={"coal"}>
                        {scoreMapRequirementItems?.[item.id]?.itemScoreDisplay}
                      </TypographyComponent>
                    </div>
                  </FeatureFlagBooleanOn>
                  <FeatureFlagBooleanOff>
                    <div
                      aria-disabled={scoringLevel !== ScoringLevel.REQUIREMENT}
                      className="gx-req-item-col-child"
                      onBlur={handleHoverExitItemCell}
                      onClick={(event) => handleOpenItemPopper(item.id, item.name, event)}
                      onFocus={() => handleHoverEnterItemCell(item.id)}
                      onKeyDown={(event) => handleKeyDownItemPopper(item.id, item.name, event)}
                      onMouseEnter={() => handleHoverEnterItemCell(item.id)}
                      onMouseOut={handleHoverExitItemCell}
                      role="button"
                      tabIndex={0}
                    >
                      {scoreMapRequirementItems?.[item.id]?.itemScoreDisplay}
                    </div>
                  </FeatureFlagBooleanOff>
                </FeatureFlagBooleanContainer>
              </li>
            ))}
          </ul>
        </Collapse>
      )}
      <EvaluationPopper
        anchorEl={anchorEl}
        initId={initId}
        isOpen={isPopperOpen}
        isScored={isScored}
        onClose={handleClosePopper}
        prodImageLoc={prodImageLoc}
        prodInitId={prodInitId}
        prodName={prodName}
        reqCatId={catId}
        reqItemId={itemId}
        subTitle={popperSubTitle}
        title={popperTitle}
        type={popperType}
      />
    </TableCarouselBodyCol>
  );
};

ProductScoreCarouselColumn.propTypes = propTypes;
export default ProductScoreCarouselColumn;
