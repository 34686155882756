import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { ScorecardProgress, UUID } from "gx-npm-lib";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  Header,
  Paragraph,
  TextLink,
  TypographyComponent,
} from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import { ProductType } from "../../overview.types";
import TopVendorV2 from "./top-vendor-v2.component";
import { topVendorsV2Styles as styles } from "./top-vendors-v2.styles";
import { GCOM_3606__fontUpdate } from "../../../../lib/feature-flags";

type TopVendorsV2Props = {
  isViewOnly: boolean;
  onClickEvalLink: (pid: UUID, destination: string) => void;
  vendors: ProductType[];
};
const useStyles = makeStyles(() => styles);
const TopVendorsV2 = ({
  isViewOnly = false,
  onClickEvalLink = (_pid, _dest) => {},
  vendors = [],
}: TopVendorsV2Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      {vendors.map((product, prodIdx) => {
        const isGrey = prodIdx % 2 === 0;
        const isLast = vendors.length - 1 === prodIdx;
        const isStarted = product.scorecardProgress !== ScorecardProgress.NOT_STARTED;
        const textLink = isViewOnly ? t("View scoresheet") : t("Score vendor");
        return (
          <div className={classNames(classes.vendor, `vendor-cnt-${vendors.length}`)} key={product.id}>
            <div className={classNames(classes.logoTitle, isLast && "last-vendor")}>
              <TopVendorV2
                id={product.id}
                imageLoc={product.imageLoc}
                name={product.name}
                onClickEvalLink={onClickEvalLink}
              />
            </div>
            <div className={classNames(classes.vendorScore, isGrey && "grey-background")}>
              <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                <FeatureFlagBooleanOn>
                  <TypographyComponent styling={"h5"}>
                    {!isStarted && t("No score")}
                    {isStarted && `${product.overallScoreDisplay} / 100`}
                  </TypographyComponent>
                  <TypographyComponent boldness={"regular"} styling={"p3"}>
                    {product.scorecardProgress === ScorecardProgress.NOT_STARTED && t("Not started")}
                    {product.scorecardProgress === ScorecardProgress.IN_PROGRESS && t("In progress")}
                    {product.scorecardProgress === ScorecardProgress.COMPLETE && t("Complete")}
                  </TypographyComponent>
                </FeatureFlagBooleanOn>
                <FeatureFlagBooleanOff>
                  <Header type="h5">
                    {!isStarted && t("No score")}
                    {isStarted && `${product.overallScoreDisplay} / 100`}
                  </Header>
                  <Paragraph boldness="regular" type="p3">
                    {product.scorecardProgress === ScorecardProgress.NOT_STARTED && t("Not started")}
                    {product.scorecardProgress === ScorecardProgress.IN_PROGRESS && t("In progress")}
                    {product.scorecardProgress === ScorecardProgress.COMPLETE && t("Complete")}
                  </Paragraph>
                </FeatureFlagBooleanOff>
              </FeatureFlagBooleanContainer>

              <TextLink onClick={() => onClickEvalLink(product.id, "scoresheet")} text={textLink} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TopVendorsV2;
