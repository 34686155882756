import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import ExportSpinnerIcon from "./spinnerIcon.icon";
import { processSteps } from "../lib";
import { processIndicatorStyles as styles } from "./styles";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { GCOM_3606__fontUpdate } from "../../../../../lib/feature-flags";
import { colorPalette } from "gx-npm-common-styles";

const propTypes = {
  fileName: PropTypes.string,
  processStep: PropTypes.oneOf(Object.values(processSteps)),
};
const useStyles = makeStyles(() => styles);
const ProcessUploadIndicator = ({ fileName = "", processStep = processSteps.none }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.indicatorContainer} role="progressbar" aria-label={`${t("Step")}-${processStep}`}>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent boldness={"semi"} styling={"p3"} element={"p"}>
            {`${t("Step")} ${processStep} ${t("of 2")}`}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <p className="p3 semi-bold">{`${t("Step")} ${processStep} ${t("of 2")}`}</p>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent rootClassName={classNames(classes.dividerGCOM3606)} styling={"p3"}>
            {`|`}
          </TypographyComponent>
          <TypographyComponent boldness={"semi"} styling={"p3"}>
            {processStep === processSteps.upload && t("Uploading")}
            {processStep !== processSteps.upload && t("Validating")}
            {` ${fileName} ...`}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <p className={classNames("p3", classes.divider)}>{`|`}</p>
          <p className="p3 semi-bold">
            {processStep === processSteps.upload && t("Uploading")}
            {processStep !== processSteps.upload && t("Validating")}
            {` ${fileName} ...`}
          </p>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
      <span className={classes.spinIcon}>
        <ExportSpinnerIcon fillPath={colorPalette.interactions.defaultCta.hex} />
      </span>
    </div>
  );
};

ProcessUploadIndicator.propTypes = propTypes;
export default ProcessUploadIndicator;
