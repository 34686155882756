import { useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  Paragraph,
  TypographyComponent,
} from "gx-npm-ui";
import { SelectionContext } from "../../../../../selection.context";
import ChecklistStatusDisplay from "./checklist-status-display.component";
import styles from "./details-checklist-section.module.scss";
import { GCOM_3606__fontUpdate } from "../../../../../../../lib/feature-flags";

const DetailsChecklistSection = () => {
  const { t } = useTranslation();
  const { checklistPhases } = useContext(SelectionContext);

  return (
    <div className={styles.checklistContainer}>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent boldness={"regular"} rootClassName={styles.checklistTitle} styling={"p4"}>
            {t("Status")}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <Paragraph boldness="regular" rootClassName={styles.checklistTitle} type="p4">
            {t("Status")}
          </Paragraph>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
      {Array.isArray(checklistPhases) &&
        checklistPhases.map((phase) => {
          return (
            phase?.title && (
              <div key={phase.id} className={styles.checklistSection}>
                <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                  <FeatureFlagBooleanOn>
                    <TypographyComponent boldness={"semi"} rootClassName={styles.checklistSectionName} styling={"p3"}>
                      {phase.title}
                    </TypographyComponent>
                  </FeatureFlagBooleanOn>
                  <FeatureFlagBooleanOff>
                    <Paragraph boldness="semi" rootClassName={styles.checklistSectionName} type="p3">
                      {phase.title}
                    </Paragraph>
                  </FeatureFlagBooleanOff>
                </FeatureFlagBooleanContainer>
                <div className={styles.checklistSectionStatus}>
                  <ChecklistStatusDisplay status={phase.status} />
                </div>
              </div>
            )
          );
        })}
    </div>
  );
};
export default DetailsChecklistSection;
