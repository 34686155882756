import { colorPalette, weightPalette } from "gx-npm-common-styles";

const colorCarbon = colorPalette.neutrals.carbon.hex;
const colorBlueBerryHex = colorPalette.interactions.blueBerry.hex;
const defaultCta = colorPalette.interactions.defaultCta.hex;

const colorCoal = colorPalette.neutrals.coal.hex;
const colorStone = colorPalette.neutrals.stone.hex;
const colorIron = colorPalette.neutrals.iron.hex;
const weightRegularAmount = weightPalette.regular.amount;
const weightRegularVariation = weightPalette.regular.variation;
const budgetTextFieldV2Styles = {
  formControl: {},
  container: {
    display: "flex",
    position: "relative" as const,
    left: -12,
    "& .MuiOutlinedInput-root": {
      borderRadius: `0`,
    },
    "& .MuiOutlinedInput-input": {
      paddingLeft: 11,
    },
    "& .MuiInputBase-inputAdornedStart": {
      paddingLeft: 0,
    },
    "& .gx-objectives-field .gx-text-field-container .MuiInputBase-multiline": {
      height: "auto",
      padding: "9px 12px 8px 0px",
    },
    "& .gx-budget-field": {
      "& input": {
        fontSize: "14px",
        letterSpacing: "0.25px",
        lineHeight: "21px",
        margin: "0",
        outline: "0",
      },
    },
    "& .gx-objectives-field": {
      "& textarea": {
        fontSize: "14px",
        letterSpacing: "0.25px",
        lineHeight: "21px",
        margin: "0",
        outline: "0",
        paddingLeft: "12px",
      },
    },
    "& .gx-objectives-field, .gx-budget-field": {
      "& .gx-text-field-container": {
        "& div:first-of-type.Mui-disabled fieldset": { border: "none" },
      },
    },
    "& .gx-budget-field .gx-text-field-container": {
      height: 37,
    },
    "&.MuiInputAdornment-root": {
      minHeight: 40,
    },
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: 12,
    },
    "&:hover .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0px",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: colorStone,
      borderWidth: "1px",
    },
    "& .Mui-focused.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: colorBlueBerryHex,
      borderWidth: "2px",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "& .MuiOutlinedInput-multiline": {
      paddingLeft: 0,
    },
    "& .MuiInputAdornment-positionStart": {
      marginRight: "0px",
    },
    "& .gx-text-field-container input": {
      textFillColor: colorCarbon,
    },
    "& .gx-text-field-container input.Mui-disabled": {
      textFillColor: colorCarbon,
    },
    "& input:first-of-type": {
      "&::placeholder": {
        textFillColor: colorIron,
        fontWeight: weightRegularAmount,
        fontVariationSettings: weightRegularVariation,
      },
    },
  },
  containerGOM3695: {
    "& .Mui-focused.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: defaultCta,
    },
  },
  grayText: {
    color: colorCoal,
    "&::placeholder": {
      color: colorIron,
      opacity: 1,
    },
  },
  "@global": {
    ".gx-budget-tooltip": {
      "& .MuiTooltip-arrow": {
        top: "60px !important",
        left: "128px !important",
        "&::before": {},
      },
    },
  },

  containerGCOM3476: {
    "& .gx-budget-field": {
      "& input": {
        cursor: "pointer",
      },
    },
    "& .gx-text-field-container input": {
      textFillColor: colorCoal,
    },
  },
};

export { budgetTextFieldV2Styles };
