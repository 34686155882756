import React, { useContext, useRef, useState } from "react";
import classNames from "classnames";
import styles from "./team-management-card.styles.module.scss";
import { useTranslation } from "react-i18next";
import { Button, TypographyComponent, TooltipV2, RichTooltip, useFeatureFlag } from "gx-npm-ui";
import TeamMemberList from "../team-member-list/team-member-list.component";
import { OverviewContext } from "../../overview.context";
import { GroupAddIcon, LightBulbOffIcon } from "gx-npm-icons";
import { navigateToUrl } from "single-spa";
import { useParams } from "react-router-dom";
import { InitStatus, InitUserRole, useUserInitAccess, UUID } from "gx-npm-lib";
import { colorPalette } from "gx-npm-common-styles";
import { GCOM_3695_ctaButtonColorUpdate } from "../../../../lib/feature-flags";

const TeamManagementCard = () => {
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);
  const { t } = useTranslation();
  const { initiativeId } = useParams();
  const { hasLoadedAccess, role } = useUserInitAccess(initiativeId as UUID);
  const { teamMembers, status } = useContext(OverviewContext);
  const refBulbIcon = useRef();
  const [isTooltipOpen, setTooltipOpen] = useState(false);

  const isViewOnly = hasLoadedAccess && role !== InitUserRole.OWNER;
  const buttonClass = status === InitStatus.PLANNING ? "btn-primary" : "btn-secondary";
  const iconFillColor =
    status === InitStatus.PLANNING
      ? isViewOnly
        ? colorPalette.neutrals.coal.hex
        : colorPalette.basic.white.hex
      : isFFGCOM3695
        ? colorPalette.interactions.defaultCta.hex
        : colorPalette.interactions.blueBerry.hex;
  return (
    <div className={classNames(styles.teamManagementCard)}>
      <div className={classNames(styles.teamManagementHeader)}>
        <h4 className={classNames(styles.teamManagementTitle)}>{t("Team")}</h4>

        <RichTooltip
          theme={"dark"}
          content={[
            {
              type: "PARAGRAPH",
              content: [t("Build a more inclusive buying team. Consider adding 3+ collaborators to your evaluation.")],
            },
            {
              type: "LIST_ITEMS",
              content: [
                t("Sponsor"),
                t("Operations"),
                t("Functional SME"),
                t("Finance"),
                t("Technical SME"),
                t("Legal"),
                t("Procurement/Vendor Management"),
              ],
            },
          ]}
          footerLinkText={t("Learn why")}
          footerLinkUrl={"https://www.gartner.com/document/4232099"}
          anchor={refBulbIcon.current}
          open={isTooltipOpen}
          onClose={() => setTooltipOpen(false)}
        >
          <span
            role="button"
            aria-label="bulb-icon-wrapper"
            tabIndex={0}
            ref={refBulbIcon as never}
            onClick={(event) => {
              event.stopPropagation();
              setTooltipOpen((prevState) => !prevState);
            }}
            onKeyDown={(event) => {
              event.stopPropagation();
              setTooltipOpen((prevState) => !prevState);
            }}
            className={classNames(styles.bulbIconWrapper, isFFGCOM3695 && styles.bulbIconWrapperGCOM3695)}
          >
            <LightBulbOffIcon
              fillColor={
                isTooltipOpen ? colorPalette.interactions.selectedCta.hex : colorPalette.interactions.defaultCta.hex
              }
            />
          </span>
        </RichTooltip>
      </div>
      <TypographyComponent rootClassName={classNames(styles.teamManagementSubHeading)} color="iron" styling="p3">
        {t("Stay connected to your team.")}
      </TypographyComponent>
      <div className={classNames(styles.teamMemberList)}>
        <TeamMemberList memberList={teamMembers} />
      </div>
      <div>
        <TooltipV2
          PopperProps={{
            modifiers: { offset: { offset: "5, 5" } },
          }}
          deactivate={!isViewOnly}
          placement="top"
          title={t("This action is only available to evaluation owners.")}
        >
          <div className={styles.tooltipButtonContainer}>
            <Button
              ariaLabel={t("Add team member to the initiative")}
              onClick={() => navigateToUrl(`/s/evaluation/${initiativeId}/overview/team-management`)}
              disabled={isViewOnly}
              rootClassName={classNames(buttonClass)}
            >
              <GroupAddIcon fillPath={iconFillColor} />
              {t("TEAM MEMBERS")}
            </Button>
          </div>
        </TooltipV2>
      </div>
    </div>
  );
};

export default TeamManagementCard;
