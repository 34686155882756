import { KeyboardEvent, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  ProductLogo,
  TooltipV2,
  TypographyComponent,
  useFeatureFlag,
} from "gx-npm-ui";
import { updateStateSubject } from "gx-npm-messenger-util";
import { SelectionContext } from "../../../../selection.context";
import * as singleSpa from "single-spa";
import classNames from "classnames";
import styles from "./selected-vendor.module.scss";
import { SelectedVendorIcon } from "gx-npm-icons";
import { GCOM_3606__fontUpdate, GCOM_3695_ctaButtonColorUpdate } from "../../../../../../lib/feature-flags";

const SelectedVendor = () => {
  const { t } = useTranslation();
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);
  const { initiativeId, awardedProduct } = useContext(SelectionContext);

  const { id, name, imageLoc, overallScoreDisplay } = awardedProduct;
  const navigateToVendorProfile = () => {
    singleSpa.navigateToUrl(`/s/evaluation/${initiativeId}/product/${id}/profile`);
    updateStateSubject("WORKSPACE_HEADER_SOURCE_PAGE", { sourcePage: "selection" });
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      navigateToVendorProfile();
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent styling={"p4"} boldness={"semi"} rootClassName={styles.selectedVendorTitle}>
              {t("Selected vendor")}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <TypographyComponent type="p4" boldness="semi" rootClassName={styles.selectedVendorTitle}>
              {t("Selected vendor")}
            </TypographyComponent>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      </div>
      <div className={classNames(styles.productContainer)}>
        <TooltipV2
          title={t("Click to view profile")}
          placement="top-start"
          PopperProps={{ modifiers: { offset: { offset: "-50, 12" } } }}
          enterDelay={1500}
          enterNextDelay={1500}
          rootClassName={"gx-selected-vendor-tooltip"}
        >
          <div
            aria-label={t("Vendor logo and name")}
            className={styles.productInfo}
            onClick={navigateToVendorProfile}
            onKeyDown={handleKeyDown}
            role="button"
            tabIndex={0}
          >
            <div className={styles.selectedVendorLogo}>
              <ProductLogo logo={imageLoc} name={name} imageWidth="48px" imageHeight="48px" />
            </div>
            <div className={classNames(styles.selectedVendorName, isFFGCOM3695 && styles.selectedVendorNameGCOM3695)}>
              <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                <FeatureFlagBooleanOn>
                  <TypographyComponent styling={"p1"} boldness={"semi"}>
                    {name}
                  </TypographyComponent>
                </FeatureFlagBooleanOn>
                <FeatureFlagBooleanOff>
                  <TypographyComponent type="p1" boldness="semi">
                    {name}
                  </TypographyComponent>
                </FeatureFlagBooleanOff>
              </FeatureFlagBooleanContainer>
            </div>
          </div>
        </TooltipV2>
        <div className={styles.overallScorePill}>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent boldness={"semi"} styling={"p4"} rootClassName={styles.scorePillTitle}>
                {t("Overall score")}
              </TypographyComponent>
              <TypographyComponent
                styling={"h4"}
                boldness={"semi"}
                rootClassName={styles.overallScore}
              >{`${overallScoreDisplay} / 100`}</TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <TypographyComponent boldness="semi" type="p4" rootClassName={styles.scorePillTitle}>
                {t("Overall score")}
              </TypographyComponent>
              <TypographyComponent
                type="h4"
                boldness="semi"
                rootClassName={styles.overallScore}
              >{`${overallScoreDisplay} / 100`}</TypographyComponent>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </div>
      </div>
      <div className={styles.selectedTag}>
        <SelectedVendorIcon />
      </div>
    </div>
  );
};

export default SelectedVendor;
