import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { planningCardV2Styles as styles } from "./planning-card-v2.styles";
import { UUID } from "gx-npm-lib";
import PlanningContentSectionV2 from "./planning-content-section-v2.component";
import { useTranslation } from "react-i18next";
import {
  GCOM_3477_3679__layoutChanges,
  GCOM_3477_3681__overviewSection,
  GCOM_3606__fontUpdate,
} from "../../../../lib/feature-flags";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
  useFeatureFlag,
} from "gx-npm-ui";

type PlanningCardV2Props = {
  initiativeId: UUID;
  isViewOnly: boolean;
};
const useStyles = makeStyles(() => styles);
const PlanningCardV2 = ({ initiativeId, isViewOnly = false }: PlanningCardV2Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isGCOM3476FFOn = useFeatureFlag(GCOM_3477_3679__layoutChanges);
  const isGCOM3681FF = useFeatureFlag(GCOM_3477_3681__overviewSection);
  return (
    <div
      className={classNames(
        classes.planningCardWrapper,
        isGCOM3681FF && classes.planningCardWrapperGCOM3681,
        isGCOM3476FFOn && classes.planningCardWrapperMarginRemoved
      )}
    >
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent rootClassName={classNames(classes.headerLargeTitle)} styling={"h4"}>
            {t("Evaluation details")}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <h4 className={classNames(classes.headerLargeTitle)}>{t("Evaluation details")}</h4>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
      <PlanningContentSectionV2 isViewOnly={isViewOnly} initiativeId={initiativeId} />
    </div>
  );
};
export default PlanningCardV2;
