import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import { requirementDrawerTitleStyles as styles } from "./styles";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { GCOM_3606__fontUpdate } from "../../../../lib/feature-flags";

const propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

const useStyles = makeStyles(() => styles);
const RequirementDrawerTitle = ({ title = "", subTitle = "" }) => {
  const classes = useStyles();

  return (
    <section className={classes.titleContainer}>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent styling={"h3"} className={classes.title}>
            {title}
          </TypographyComponent>
          <TypographyComponent styling={"p3"} className={classNames(classes.subtitle)}>
            {subTitle}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <h3 className={classes.title}>{title}</h3>
          <p className={classNames("p3", classes.subtitle)}>{subTitle}</p>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
    </section>
  );
};

RequirementDrawerTitle.propTypes = propTypes;
export default RequirementDrawerTitle;
