import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { handleEvent } from "gx-npm-lib";
import { Button, FeatureFlagBooleanContainer, FeatureFlagBooleanOff, useFeatureFlag } from "gx-npm-ui";
import { statusCardV2Styles as styles } from "./planning-status-card-v2.styles";
import { GCOM_3477_3679__layoutChanges } from "../../../../lib/feature-flags";

type PlanningStatusCardV2Props = {
  btnTitle: string;
  cardName: string;
  displayNumber: React.ReactNode;
  imageSrc: string;
  onNavigation: (name: string) => void;
  subTitle: string;
  title: string;
};
const useStyles = makeStyles(() => styles);
const PlanningStatusCardV2 = ({
  btnTitle,
  cardName,
  displayNumber,
  imageSrc,
  onNavigation,
  subTitle,
  title,
}: PlanningStatusCardV2Props) => {
  const handleNavigate = () => {
    handleEvent(onNavigation);
  };
  const classes = useStyles();
  const isGCOM3476FFOn = useFeatureFlag(GCOM_3477_3679__layoutChanges);

  return (
    <div className={classNames(classes.statusCardRoot, isGCOM3476FFOn && classes.statusCardRootV2)}>
      <div className={classNames(classes.planningDataDisplay)}>
        <h4 className={classNames("gx-planning-data-card-title")}>{title}</h4>
        {displayNumber}
        <span className={classNames(classes.statusCardSubtitle)}>{subTitle}</span>
        <div>
          <Button
            onClick={handleNavigate}
            rootClassName={classNames(
              "gx-planning-status-btn",
              isGCOM3476FFOn ? "btn-secondary gx-planning-status-btn-outlined" : "btn-primary"
            )}
          >
            {btnTitle}
          </Button>
        </div>
      </div>
      <FeatureFlagBooleanContainer flagName={GCOM_3477_3679__layoutChanges}>
        <FeatureFlagBooleanOff>
          <div className={classNames(`gx-planning-image-${cardName}`)}>
            {!!imageSrc && <img alt="" src={imageSrc} />}
          </div>
          <div className={classNames(`gx-planning-polygon-${cardName}`)} />
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
    </div>
  );
};
export default PlanningStatusCardV2;
