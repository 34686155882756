// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BWTnSuP7Bxp9TOiUvRJJ{align-items:end;gap:24px}.BWTnSuP7Bxp9TOiUvRJJ .ROR0QRLzi2QdnBOzvr9r{background-color:var(--color-neutrals-silver);color:var(--color-neutrals-coal);font-size:12px;font-variation-settings:"wght" var(--weight-semi-bold);font-weight:var(--weight-semi-bold);height:42px;line-height:18px;width:42px;margin-left:-7px}@media(max-width: 1024px){.BWTnSuP7Bxp9TOiUvRJJ{align-items:flex-start;flex-direction:column}}.dYYwMEAemuEmTLJqAgni{gap:0;justify-content:space-between}.dYYwMEAemuEmTLJqAgni .gx-user{margin-left:-7px}.dYYwMEAemuEmTLJqAgni .C_p_hEKW_tMfKCWGdhoN{gap:0;justify-content:flex-start}`, "",{"version":3,"sources":["webpack://./src/sections/selection-tab-v2/components/selection-report/components/initiative-details-card/components/team-members.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,QAAA,CAEA,4CACE,6CAAA,CACA,gCAAA,CACA,cAAA,CACA,sDAAA,CACA,mCAAA,CACA,WAAA,CACA,gBAAA,CACA,UAAA,CACA,gBAAA,CAGF,0BAhBF,sBAiBI,sBAAA,CACA,qBAAA,CAAA,CAIJ,sBACE,KAAA,CACA,6BAAA,CAEA,+BACE,gBAAA,CAGF,4CACE,KAAA,CACA,0BAAA","sourcesContent":[".memberAvatarRoot {\n  align-items: end;\n  gap: 24px;\n\n  & .additionalMembersBadge {\n    background-color: var(--color-neutrals-silver);\n    color: var(--color-neutrals-coal);\n    font-size: 12px;\n    font-variation-settings: \"wght\" var(--weight-semi-bold);\n    font-weight: var(--weight-semi-bold);\n    height: 42px;\n    line-height: 18px;\n    width: 42px;\n    margin-left: -7px;\n  }\n\n  @media (max-width: 1024px) {\n    align-items: flex-start;\n    flex-direction: column;\n  }\n}\n\n.otherMembers {\n  gap: 0;\n  justify-content: space-between;\n\n  & :global(.gx-user) {\n    margin-left: -7px;\n  }\n\n  & .otherMembersRow {\n    gap: 0;\n    justify-content: flex-start;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"memberAvatarRoot": `BWTnSuP7Bxp9TOiUvRJJ`,
	"additionalMembersBadge": `ROR0QRLzi2QdnBOzvr9r`,
	"otherMembers": `dYYwMEAemuEmTLJqAgni`,
	"otherMembersRow": `C_p_hEKW_tMfKCWGdhoN`
};
export default ___CSS_LOADER_EXPORT___;
