import React from "react";
import { colorPalette } from "gx-npm-common-styles";

const SpinnerIcon = () => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0V3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12H0C0 18.6274 5.37258 24 12 24Z"
        fill={colorPalette.interactions.blueBerry.hex}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default SpinnerIcon;
