import { colorPalette } from "gx-npm-common-styles";

const editDialogStyles = {
  fontUpdate: {
    fontFamily: `"Graphik", "Roboto", "Helvetica Neue" !important`,
  },
  root: {
    float: "right",
    "& .btn-tertiary": {
      marginRight: "24px",
    },
    "& .gx-edit-weight": {
      width: "627px",
    },
  },
  editWeightBodyAlert: {
    "& .inline-alert": {
      width: "636px",
      marginBottom: "24px",
      marginTop: "0px",
      "& p": {
        color: colorPalette.status.poisonCherry.hex,
      },
    },
  },
  editWeightBodyContainer: {
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: -36,
      width: 699,
      right: 0,
      height: "10px",
      background: "linear-gradient(rgba(0,0,0,0.1), transparent)",
      zIndex: 1,
      display: (props) => (props.isScrolledTop ? "block" : "none"),
    },
    "&::after": {
      width: 699,
      content: '""',
      position: "absolute",
      bottom: 0,
      left: -36,
      right: 0,
      height: "10px",
      background: "linear-gradient(transparent, rgba(0,0,0,0.1))",
      zIndex: 1,
      display: (props) => (props.isScrolledBottom ? "block" : "none"),
    },
  },
  editWeightBody: {
    overflowX: "hidden",
    overflowY: "auto",
    width: "663px",
    height: "424px",
    maxHeight: "50vh",
    paddingTop: "16px",
    "& .gx-edit-total-weight-text": {
      marginLeft: "16px",
    },
    "& .gx-edit-total-weight-percentage": {
      marginRight: "8px",
      marginLeft: "12px",
    },
    "& .weightedText": {
      marginRight: 21,
    },
    "& .gx-invalid-weight": {
      color: colorPalette.status.poisonCherry.hex,
      display: "flex",
      alignItems: "center",
      "& svg": {
        position: "absolute",
        right: "120px",
      },
    },
    "&::-webkit-scrollbar": {
      width: "8px",
      paddingLeft: 8,
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "4px",
      backgroundColor: colorPalette.neutrals.stone.hex,
    },
  },
  editWeightBodyHeaderRight: {
    display: "flex",
    alignItems: "center",
    paddingRight: (props) => (props.hasScrollbar ? "8px" : "15px"),
  },
  editWeightBodyHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginRight: 30,
    "& h5": {
      display: "flex",
      alignItems: "center",
    },
  },
  editWeightBodyLine: {
    width: "636px",
    height: "1px",
    backgroundColor: colorPalette.neutrals.stone.hex,
    marginTop: "12px",
  },
  editCategoryBox: {
    width: "636px",
    height: "64px",
    display: "flex",
    alignItems: "center",
    backgroundColor: colorPalette.basic.white.hex,
    "&:hover": {
      backgroundColor: colorPalette.neutrals.pearl.hex,
    },
    "& .gx-edit-category-name": {
      width: 500,
      marginLeft: "16px",
      fontWeight: "500",
    },
    "& .weightedText": {
      marginRight: 10,
    },
    "& .gx-invalid-weight-cat": {
      "& .gx-number-input": {
        "& .MuiInputBase-input": {
          color: colorPalette.status.poisonCherry.hex,
        },
        "& .MuiTypography-root": {
          color: colorPalette.status.poisonCherry.hex,
        },
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "4px",
      marginTop: "0px !important",
      marginBottom: "0px !important",
    },
  },
  editWeightBodyCategories: {
    "& .gx-edit-weight-selected": {
      backgroundColor: colorPalette.neutrals.pearl.hex,
    },
  },
  percentageIcon: {
    marginLeft: "8px",
    width: "24px",
    height: "24px",
  },
  editWeightBodyCategory: {
    display: "block",
    width: "100%",
    padding: 0,
    border: "none",
    background: "none",
    textAlign: "left",
  },
  hideSpinners: {
    '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
      "-webkit-appearance": "none",
      margin: 0,
    },
    '& input[type="number"]::-moz-outer-spin-button, & input[type="number"]::-moz-inner-spin-button': {
      "-moz-appearance": "none",
      margin: 0,
    },
  },
  hideOutline: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: colorPalette.basic.white.hex,
      "& fieldset": {
        borderColor: "transparent", // Hide outline by default
      },
      "&:hover fieldset": {
        borderColor: colorPalette.neutrals.carbon.hex, // Show outline on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: colorPalette.interactions.defaultCta.hex, // Show darker outline on focus
      },
    },
  },

  invalidOutline: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: colorPalette.basic.white.hex,
      "& fieldset": {
        borderColor: "transparent", // Hide outline by default
      },
      "&:hover fieldset": {
        borderColor: colorPalette.status.poisonCherry.hex, // Poison Cherry outline on hover for invalid weight
      },
      "&.Mui-focused fieldset": {
        borderColor: colorPalette.status.poisonCherry.hex, // Poison Cherry outline on focus for invalid weight
      },
      "& input": {
        color: colorPalette.status.poisonCherry.hex, // Poison Cherry text color for invalid weight
      },
    },
  },
  weightedText: {
    "& .MuiInputBase-root": {
      width: "111px",
      height: "48px",
    },
    marginRight: (props) => (props.isScrolledBottom || props.isScrolledTop ? "-1px" : "0px"),
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 9,
    },
    "& .gx-edit-weight-text-field": {
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: colorPalette.neutrals.carbon.hex + " !important",
      },
      borderRadius: 4,
      backgroundColor: colorPalette.basic.white.hex,
      "& .MuiInputBase-input": {
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "0.25px",
      },
      "&:hover": {
        backgroundColor: colorPalette.basic.white.hex,
      },
    },
  },
  isValidWeight: {},
};

export { editDialogStyles };
