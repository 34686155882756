import classNames from "classnames";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { SELECTED_PRIORITY_WEIGHTS } from "../../../products.constants";
import { ProductsContext } from "../../../products.context";
import styles from "./product-list-header-v3.styles.module.scss";
import { GCOM_3606__fontUpdate } from "../../../../../lib/feature-flags";

const ProductListHeaderV3Component = () => {
  const { t } = useTranslation();
  const { isBaseTemplate, isMqBasedTemplate, priorities } = useContext(ProductsContext);
  const hasPrioritiesSelected = priorities.some((priority) => SELECTED_PRIORITY_WEIGHTS.includes(priority.weight));
  const hasFourColumns = !isBaseTemplate && isMqBasedTemplate && hasPrioritiesSelected;
  return (
    <div className={styles.headerWrapper}>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent boldness={"medium"} color={"iron"} rootClassName={styles.startColumn} styling={"p4"}>
            {t("Vendor")}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <TypographyComponent boldness="medium" color="iron" rootClassName={styles.startColumn} type="p4">
            {t("Vendor")}
          </TypographyComponent>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
      <div className={classNames(styles.middleColumns, hasFourColumns && styles.smallMiddleColumns)}>
        {isMqBasedTemplate && (
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent rootClassName={styles.column}>
                <TypographyComponent
                  element={"span"}
                  rootClassName={styles.smallMqText}
                  boldness={"medium"}
                  color={"iron"}
                  styling={"p4"}
                >
                  {t("MQ position")}
                </TypographyComponent>
                <TypographyComponent
                  element={"span"}
                  rootClassName={styles.normalMqText}
                  boldness={"medium"}
                  color={"iron"}
                  styling={"p4"}
                >
                  {t("Magic Quadrant position")}
                </TypographyComponent>
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <TypographyComponent boldness="medium" color="iron" rootClassName={styles.column} type="p4">
                <span className={styles.smallMqText}>{t("MQ position")}</span>
                <span className={styles.normalMqText}>{t("Magic Quadrant position")}</span>
              </TypographyComponent>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        )}
        {!isBaseTemplate && (
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent color={"iron"} boldness={"medium"} rootClassName={styles.column} styling={"p4"}>
                {t("Peer Insights")}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <TypographyComponent color="iron" boldness="medium" rootClassName={styles.column} type="p4">
                {t("Peer Insights")}
              </TypographyComponent>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        )}
        {hasPrioritiesSelected && (
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent color={"iron"} boldness={"medium"} rootClassName={styles.column} styling={"p4"}>
                {t("Vendor fit")}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <TypographyComponent color="iron" boldness="medium" rootClassName={styles.column} type="p4">
                {t("Vendor fit")}
              </TypographyComponent>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        )}
      </div>
    </div>
  );
};

export default ProductListHeaderV3Component;
