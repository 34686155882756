import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Collapse } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { handleEvent } from "gx-npm-lib";
import {
  Checkbox,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
  useFeatureFlag,
} from "gx-npm-ui";
import { EvaluationStateContext } from "../../../../context";
import { operations } from "../../../../context/actions/operationTypes";
import { actionRequirementsTransaction } from "../../../../context/actions/requirementsActions";
import { ListItem, ListItemsContainer } from "../../../../ui/dragAndDropList/body";
import DeleteReqDetailsDialog from "../../dialogs/delete-req-details-dialog/delete-req-details-dialog.component";
import { triggerFSBulkOpEvent } from "../../requirements.lib";
import useScrollListener from "./use-scroll-listener";
import { listContentStyles as styles } from "./styles";
import RequirementsListRowView from "./requirements-list-row-view";
import { GCOM_3606__fontUpdate, GCOM_3695_ctaButtonColorUpdate } from "../../../../lib/feature-flags";

const propTypes = {
  addIndex: PropTypes.number,
  highlightedItemsIds: PropTypes.arrayOf(PropTypes.string),
  index: PropTypes.number,
  initiativeId: PropTypes.string,
  isAboveReqCountThreshold: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isViewOnly: PropTypes.bool,
  newReqIndex: PropTypes.number,
  setAddIndex: PropTypes.func,
  selectedReq: PropTypes.func,
  selectedBulkOpIds: PropTypes.array,
  selectedAllCheckbox: PropTypes.func,
  selectedAllCheckboxId: PropTypes.array,
};
const useStyles = makeStyles(() => styles);
const RequirementListContent = ({
  addIndex = -1,
  highlightedItemsIds = [],
  index = -1,
  initiativeId = "",
  isAboveReqCountThreshold = false,
  isExpanded = false,
  isViewOnly = false,
  newReqIndex = -1,
  setAddIndex = null,
  selectedReq = (_checked, _reqId) => {},
  selectedBulkOpIds = [],
  selectedAllCheckbox = (_checked, _catId) => {},
  selectedAllCheckboxId = [],
}) => {
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);
  const { t } = useTranslation();
  const [areMenusOpen, setAreMenusOpen] = useState([]);
  const [autoFocusIndex, setAutoFocusIndex] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSelecting, setIsSelecting] = useState(false);
  const [state, dispatch] = useContext(EvaluationStateContext);
  const scrollModulo = useScrollListener();

  useEffect(() => {
    const isOpen = isOpenPopover(areMenusOpen);
    setIsMenuOpen(isOpen);
  }, [areMenusOpen]);

  useEffect(() => {
    setIsSelecting(selectedBulkOpIds.length > 0);
  }, [selectedBulkOpIds]);

  const clearAutoFocus = () => {
    setAutoFocusIndex(null);
  };

  const editEvent = (key, value, idx) => {
    actionRequirementsTransaction(state, dispatch, {
      childId: state.requirements.list[index]?.itemList?.[idx]?.id,
      childIndex: idx,
      initiativeId,
      key,
      operation: operations.childEdit,
      parentId: state.requirements.list[index]?.id,
      parentIndex: index,
      value,
    });
  };

  const handleAutoSave = (key, value, idx) => {
    clearAutoFocus();
    editEvent(key, value, idx);
  };

  const handleDeleteClosed = (confirmedDelete = false) => {
    if (confirmedDelete) {
      setIsDeleting(true);
    } else {
      setDeleteId(null);
      setDeleteIndex(null);
    }
    setIsDeleteDialogOpen(false);
  };

  const handleDragFinish = (_items, result) => {
    const parentIndex = index;
    const indexDest = result?.destination?.index;
    const indexSource = result?.source?.index;
    clearAutoFocus();
    actionRequirementsTransaction(state, dispatch, {
      childId: state.requirements.list[parentIndex].itemList[indexSource]?.id,
      indexDest,
      indexSource,
      initiativeId,
      operation: operations.childReorder,
      parentId: state.requirements.list[parentIndex].id,
      parentIndex,
    });
  };

  const handleFadeExit = (fadeIn = false, fadeOut = false) => {
    if (fadeIn) {
      setAutoFocusIndex(state.requirements.list[addIndex]?.itemList?.length);
      handleEvent(setAddIndex, -1);
    } else if (fadeOut) {
      const indexId = state.requirements.list?.[index]?.itemList?.[deleteIndex]?.id;
      if (indexId === deleteId) {
        actionRequirementsTransaction(state, dispatch, {
          childId: state.requirements.list[index]?.itemList?.[deleteIndex]?.id,
          childIndex: deleteIndex,
          initiativeId,
          operation: operations.childDelete,
          parentId: state.requirements.list[index]?.id,
          parentIndex: index,
        });
      }
      setDeleteId(null);
      setDeleteIndex(null);
      setIsDeleting(false);
    }
  };

  const handlePopoverAutoSave = (key, value, idx) => {
    clearAutoFocus();
    editEvent(key, value, idx);
  };

  const handlePopoverOpen = (isOpen, idx) => {
    setAreMenusOpen((prev) => {
      const curr = Array.isArray(prev) ? [...prev] : [];
      curr[idx] = isOpen;
      return curr;
    });
  };

  const isOpenPopover = (openArray) => {
    let isOpen = false;
    const length = openArray?.length || 0;
    for (let idx = 0; idx < length; idx++) {
      if (openArray?.[idx]) {
        isOpen = true;
        break;
      }
    }
    return isOpen;
  };

  const openDeleteDialog = (reqId, reqIdx) => {
    clearAutoFocus();
    setDeleteId(reqId);
    setDeleteIndex(reqIdx);
    setIsDeleteDialogOpen(true);
  };

  const handleChange = (event) => {
    const { value, checked } = event.target;
    selectedReq(checked, value);
    triggerFSBulkOpEvent("item");
  };

  const handleCheckboxAllChange = (event) => {
    const catId = state.requirements.list?.[index].id;
    const { checked } = event.target;

    for (let i = 0; i < state.requirements.list[index].itemList.length; i++) {
      const reqId = state.requirements.list[index].itemList[i].id;
      selectedReq(checked, reqId);
    }

    selectedAllCheckbox(checked, catId);
    triggerFSBulkOpEvent("category");
  };

  const handleDragComplete = () => {
    setIsDragging(false);
  };

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const allCheckedAriaLabel = `Select all in the category ${state.requirements.list?.[index]?.name} for bulk operation`;
  const isAllChecked = selectedAllCheckboxId.includes(state.requirements.list?.[index]?.id);
  const isListPopulated = state.requirements.list?.[index]?.itemList?.length > 0;
  const classes = useStyles();
  return (
    <div className={classNames(classes.root)}>
      <ListItemsContainer
        draggable={isListPopulated}
        itemList={state.requirements.list?.[index]?.itemList}
        onDragComplete={handleDragComplete}
        onDragFinish={handleDragFinish}
        onDragStart={handleDragStart}
        phaseIndex={index}
      >
        {isListPopulated && (
          <Collapse in={isSelecting}>
            <div className={classNames(classes.selectionBox, isFFGCOM3695 && classes.selectionBoxGCOM3695)}>
              <Checkbox
                checked={isAllChecked}
                inputProps={{ "aria-label": allCheckedAriaLabel }}
                label="Select all"
                onChange={handleCheckboxAllChange}
                rootClassName="select-all"
              />
            </div>
          </Collapse>
        )}
        {isListPopulated &&
          state.requirements.list[index].itemList.map((req, idx) => {
            return (
              <RequirementsListRowView
                key={req.id}
                addIndex={addIndex}
                autoFocusIndex={autoFocusIndex}
                deleteId={deleteId}
                description={req.description}
                id={req.id}
                indexCat={index}
                indexReq={idx}
                isAboveReqCountThreshold={isAboveReqCountThreshold}
                isDeleting={isDeleting}
                isDragging={isDragging}
                isExpanded={isExpanded}
                isModified={req.isModified}
                isMenuOpen={isMenuOpen}
                isViewOnly={isViewOnly}
                itemListLength={state.requirements.list[index].itemList.length}
                listHighlightedItemIds={highlightedItemsIds}
                listIsMenuOpen={areMenusOpen}
                listSelectedBulkOpIds={selectedBulkOpIds}
                name={req.name}
                newReqIndex={newReqIndex}
                onAutoSave={handleAutoSave}
                onCheckboxChange={handleChange}
                onClearAutoFocus={clearAutoFocus}
                onFadeExit={handleFadeExit}
                onOpenDeleteDialog={openDeleteDialog}
                onPopoverAutoSave={handlePopoverAutoSave}
                onPopoverOpen={handlePopoverOpen}
                priority={req.priority}
                scrollModulo={scrollModulo}
              />
            );
          })}
        {!isListPopulated && (
          <ListItem draggable={false}>
            <div className={classNames("gx-empty-row", classes.emptyContent)}>
              <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                <FeatureFlagBooleanOn>
                  <TypographyComponent styling={"p3"}>
                    {t("There are no requirements in this category.")}
                  </TypographyComponent>
                </FeatureFlagBooleanOn>
                <FeatureFlagBooleanOff>
                  <p className="p3">{t("There are no requirements in this category.")}</p>
                </FeatureFlagBooleanOff>
              </FeatureFlagBooleanContainer>
            </div>
          </ListItem>
        )}
      </ListItemsContainer>
      <DeleteReqDetailsDialog
        initId={initiativeId}
        isOpen={isDeleteDialogOpen}
        onClose={handleDeleteClosed}
        reqItemIds={[state.requirements.list?.[index]?.itemList?.[deleteIndex]?.id]}
        reqState={state.requirements.state}
      />
    </div>
  );
};

RequirementListContent.propTypes = propTypes;
export { RequirementListContent };
