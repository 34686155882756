import { useTranslation } from "react-i18next";
import styles from "./product-profile.module.scss";
import { AwardedProductOverview } from "../../../../../../selection.types";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import Reviews from "../reviews/reviews.component";
import MagicQuadrant from "../magic-quadrant/magic-quadrant.component";
import { GCOM_3606__fontUpdate } from "../../../../../../../../lib/feature-flags";

type ProductProfileProps = {
  productOverview: AwardedProductOverview;
};
const ProductProfile = ({ productOverview }: ProductProfileProps) => {
  const { t } = useTranslation();
  const reviews = productOverview.productReviews;
  const mq = productOverview.mq;

  const isReviewAvailable = reviews.mostFavorableReview.id !== 0 || reviews.mostCriticalReview.id !== 0;
  const isMQAvailable = mq.qdPosition !== "";

  return (
    <section>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent styling={"p1"} boldness={"semi"} rootClassName="gx-gartner-insights-title">
            {t("Gartner insights")}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <TypographyComponent type="p1" boldness="semi" rootClassName="gx-gartner-insights-title">
            {t("Gartner insights")}
          </TypographyComponent>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
      <div className={styles.marketInfo}>
        {isReviewAvailable && <Reviews isExpanded={!isMQAvailable} productReviews={reviews} />}
        {isMQAvailable && <MagicQuadrant isExpanded={!isReviewAvailable} mq={mq} />}
      </div>
    </section>
  );
};

export default ProductProfile;
