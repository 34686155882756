import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React, { MouseEvent, useEffect, useState } from "react";
import classNames from "classnames";
import { MiniButton } from "../miniButton";
import { CalendarIcon, CloseIcon } from "gx-npm-icons";
import { ClickAwayListener, createTheme, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TooltipV2 } from "../tooltip-v2";
import { xDateRangePickerStyles, xDateRangePickerStylesGCOM3695 } from "./xDatePickers.styles";
import { IconButton } from "../iconButton";
import {
  add,
  differenceInDays,
  differenceInMonths,
  differenceInWeeks,
  differenceInYears,
  formatDuration,
} from "date-fns";
import { PickersDay, pickersLayoutClasses } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import xDatePickerClasses from "./xDatePickers.styles.module.scss";
import { colorPalette } from "gx-npm-common-styles";
import { GCOM_3606__fontUpdate, GCOM_3695_ctaButtonColorUpdate } from "../feature-flags";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  useFeatureFlag,
} from "../featureFlags";
import { TypographyComponent } from "../typography/typography.component";

export type xDateRangePickerPropTypes = {
  disabled?: boolean;
  toolTipText?: string;
  showToolTip: boolean;
  toolTipPlacement?: string;
  datePickerFormat?: string;
  actionButtonVariant?: string;
  showClearButton: boolean;
  clearButtonText?: string;
  initialValue?: { start: string; end: string };
  rootClassName?: string;
  placeholder?: string;
  toolTipRootClassName?: string;
  onChange: (date: { start: string; end: string }) => void;
};

type RenderDayProps = {
  updateStartDate?: React.Dispatch<React.SetStateAction<string>>;
  updateEndDate?: React.Dispatch<React.SetStateAction<string>>;
  day: TDate | undefined;
  onDaySelect: (arg0: TDate) => void;
};

type TDate = {
  $d?: Date;
  format: (value: string) => string;
};

const XDateRangePicker = ({
  disabled = false,
  toolTipText = "Add Date",
  toolTipRootClassName = "",
  showToolTip = true,
  toolTipPlacement = "top",
  datePickerFormat = "MMMM DD, YYYY",
  actionButtonVariant = "default",
  initialValue = { start: "", end: "" },
  showClearButton = true,
  clearButtonText = "Clear date",
  rootClassName = "",
  placeholder = "Add Date",
  onChange,
}: xDateRangePickerPropTypes) => {
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);
  const xDateRangePickerTheme = createTheme(isFFGCOM3695 ? xDateRangePickerStylesGCOM3695 : xDateRangePickerStyles);
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState(initialValue.start || "");
  const [endDate, setEndDate] = useState(initialValue.end || "");
  useEffect(() => {
    if (initialValue && initialValue.start && initialValue.end) {
      setStartDate(initialValue.start);
      setEndDate(initialValue.end);
    }
  }, [initialValue]);
  const getMonthDay = (date: string): string => {
    return new Date(date).toLocaleString("en-us", {
      timeZone: "UTC",
      month: "short",
      day: "numeric",
    });
  };

  const formatTDate = (selectedDate: Date | undefined): string => {
    /**
     * This converts MUI picker format as yyyy-mm-dd
     */
    if (!selectedDate) {
      return "";
    }
    const year = selectedDate.getFullYear();
    let month: string | number = selectedDate.getMonth() + 1;
    let day: string | number = selectedDate.getDate();
    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;
    return year + "-" + month + "-" + day;
  };

  const clearPicker = () => {
    setStartDate(() => "");
    setEndDate(() => "");
    if (onChange) {
      onChange({
        start: "",
        end: "",
      });
    }
  };

  const RangeActionBar = (): React.JSX.Element => {
    return (
      <div className={classNames(xDatePickerClasses.xActionBar, xDatePickerClasses.xDateRangePickerActionBar)}>
        {showClearButton && (
          <MiniButton
            onClick={clearPicker}
            disabled={!(startDate || endDate)}
            rootClassName={classNames(
              (startDate || endDate) &&
                classNames(
                  xDatePickerClasses.actionBarMiniBtnEnabled,
                  isFFGCOM3695 && xDatePickerClasses.actionBarMiniBtnEnabledGCOM3695
                )
            )}
            variant={actionButtonVariant}
            ariaLabel={placeholder}
          >
            <CloseIcon />
            {clearButtonText}
          </MiniButton>
        )}
      </div>
    );
  };

  const formatDateRange = () => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const startDateMonth = start.toLocaleString("en-us", {
      timeZone: "UTC",
      month: "short",
    });
    const startDateDay = start.toLocaleString("en-us", {
      timeZone: "UTC",
      day: "numeric",
    });
    const startDateYear = start.toLocaleString("en-us", {
      timeZone: "UTC",
      year: "numeric",
    });
    const endDateMonth = end.toLocaleString("en-us", {
      timeZone: "UTC",
      month: "short",
    });
    const endDateDay = end.toLocaleString("en-us", {
      timeZone: "UTC",
      day: "numeric",
    });
    const endDateYear = end.toLocaleString("en-us", {
      timeZone: "UTC",
      year: "numeric",
    });
    const datePrefix = `${startDateMonth} ${startDateDay}`;
    const dateSuffix = `${endDateDay}, ${endDateYear}`;
    if (startDateMonth === endDateMonth && startDateYear === endDateYear) {
      return `${datePrefix} - ${dateSuffix}`;
    } else if (startDateMonth !== endDateMonth && startDateYear === endDateYear) {
      return `${datePrefix} - ${endDateMonth} ${dateSuffix}`;
    } else {
      return `${datePrefix}, ${startDateYear} - ${endDateMonth} ${dateSuffix}`;
    }
  };

  const getDateRangeDuration = () => {
    if (startDate === null || endDate === null || endDate === "") {
      return null;
    }
    let duration;
    const start = new Date(startDate);
    const end = new Date(endDate);
    const includeEndDate = add(end, { days: 1 });

    const days = differenceInDays(includeEndDate, start);
    const weeks = differenceInWeeks(includeEndDate, start);
    const months = differenceInMonths(includeEndDate, start);
    const years = differenceInYears(includeEndDate, start);

    if (years >= 1) {
      duration = formatDuration({
        years,
        months: months - years * 12,
      });
    } else if (months >= 1) {
      duration = formatDuration({ months });
    } else {
      duration = formatDuration({
        weeks,
        days: days - weeks * 7,
      });
    }
    return duration;
  };

  const RangeField = () => {
    return (
      <div>
        <TooltipV2
          enterNextDelay={500}
          title={toolTipText}
          placement={toolTipPlacement}
          deactivate={!showToolTip || isOpen}
          children={
            <div className={classNames(xDatePickerClasses.xDateContainer)}>
              <div data-testid={"date-range-open"}>
                <IconButton
                  hover={isOpen}
                  className={classNames(xDatePickerClasses.toggleBtn)}
                  onClick={() => {
                    setIsOpen?.(() => true);
                  }}
                  disabled={disabled}
                >
                  {<CalendarIcon />}
                </IconButton>
              </div>
              <div
                role="link"
                tabIndex={0}
                className={classNames(
                  xDatePickerClasses.dateDisplay,
                  !(startDate && endDate) && xDatePickerClasses.placeholder
                )}
                data-testid={"date-range"}
                onClick={() => setIsOpen?.(() => !disabled)}
                onKeyDown={() => {}}
              >
                {startDate && endDate ? (
                  formatDateRange()
                ) : (
                  <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                    <FeatureFlagBooleanOn>
                      <TypographyComponent styling={"span"}>{placeholder || "Add date range"}</TypographyComponent>
                    </FeatureFlagBooleanOn>
                    <FeatureFlagBooleanOff>
                      <span>{placeholder || "Add date range"}</span>
                    </FeatureFlagBooleanOff>
                  </FeatureFlagBooleanContainer>
                )}
                {getDateRangeDuration() && (
                  <div className={classNames(xDatePickerClasses.duration)}>{getDateRangeDuration()}</div>
                )}
              </div>
            </div>
          }
          rootClassName={toolTipRootClassName}
        />
      </div>
    );
  };

  const RangeToolBar = () => {
    return (
      <div className={classNames(xDatePickerClasses.xToolBar)}>
        <div className={classNames(xDatePickerClasses.xToolBarTitle)}>
          <p className="p1">Select date range</p>
        </div>
        <div className={classNames(xDatePickerClasses.xToolBarRange)}>{`${
          startDate ? getMonthDay(startDate) : "Start"
        } - ${endDate ? getMonthDay(endDate) : "End"}`}</div>
      </div>
    );
  };

  const checkIsDayDisabled = (day: TDate | undefined) => {
    if (!startDate) {
      return false;
    }
    const nodeDate = formatTDate(day?.$d);
    return startDate && !endDate && nodeDate < startDate;
  };

  const convertTimestampToDate = (timestamp: number): string => {
    return timestamp ? formatTDate(new Date(timestamp)) : "";
  };

  const RenderDay = (props: RenderDayProps) => {
    const { updateStartDate, updateEndDate } = props;
    const additionalProps = {
      disabled: checkIsDayDisabled(props.day),
      onMouseEnter: (event: MouseEvent) => {
        /**
         * Add hover classes if the start is selected.
         */
        if (startDate && !endDate) {
          const allDays = document.querySelectorAll("button[data-timestamp]");
          allDays.forEach((element) => {
            if (
              element &&
              convertTimestampToDate(Number(element.getAttribute("data-timestamp"))) > startDate &&
              convertTimestampToDate(Number(element.getAttribute("data-timestamp"))) <
                convertTimestampToDate(Number(event.currentTarget.getAttribute("data-timestamp")))
            ) {
              element.classList.add("MuiPickersDay-range-hover");
            } else {
              element.classList.remove("MuiPickersDay-range-hover");
            }
          });
        }
      },
    };
    const onDaySelect = (data: TDate) => {
      if (startDate && endDate) {
        /**
         * Cleanup both, set selected to startDate
         */
        updateEndDate?.(() => "");
        updateStartDate?.(() => formatTDate(data.$d));
        props.onDaySelect(data);
      } else if (startDate && !endDate) {
        /**
         * Set endDate and sync startDate with firstDateSelection
         * Cleanup firstDateSelection after work is done
         */
        const formattedDate = formatTDate(data.$d);
        if (formattedDate === startDate) {
          return;
        }
        updateStartDate?.(() => startDate);
        updateEndDate?.(() => formattedDate);
        if (onChange) {
          onChange({
            start: startDate,
            end: formatTDate(data.$d),
          });
        }
      } else {
        /**
         * It's a start. Do not update states,
         * only local variable (firstDateSelection) needs to be updated
         */
        updateStartDate?.(() => formatTDate(data.$d));
        props.onDaySelect(data);
      }
    };

    const isSelected = () => {
      const currentDate = formatTDate(props.day?.$d);
      if (currentDate === startDate) {
        return "Mui-selected-start";
      }
      if (currentDate === endDate) {
        return "Mui-selected-end";
      }
    };

    const isInRange = () => {
      if (!endDate) {
        return "";
      }
      const dateString = formatTDate(props.day?.$d);
      if (startDate < dateString && dateString < endDate) {
        return "MuiPickersDay-range";
      }
    };

    const allProps: RenderDayProps = { ...props, ...additionalProps, onDaySelect };
    delete allProps.updateStartDate;
    delete allProps.updateEndDate;
    return (
      <PickersDay
        outsideCurrentMonth={false}
        isFirstVisibleCell={false}
        isLastVisibleCell={false}
        className={classNames(isSelected(), isInRange())}
        {...allProps}
      />
    );
  };

  const formatWeekDay = (_day: string, weekday?: { format: (value: string) => string }) => `${weekday?.format("dd")}`;

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <div className={classNames(rootClassName)}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ThemeProvider theme={xDateRangePickerTheme}>
            <RangeField />
            <DatePicker
              disabled={disabled}
              format={datePickerFormat}
              slotProps={{
                textField: {
                  onClick: () => setIsOpen(true),
                  sx: {
                    "&": {
                      display: "flex",
                    },
                    input: {
                      height: 0,
                      padding: 0,
                      fontSize: 0,
                    },
                    fieldset: {
                      display: "none",
                    },
                  },
                  InputProps: {
                    endAdornment: null,
                  },
                },
                layout: {
                  sx: {
                    [`.${pickersLayoutClasses.contentWrapper}`]: {
                      borderLeft: "2px solid " + colorPalette.neutrals.silver.hex,
                      borderRight: "2px solid " + colorPalette.neutrals.silver.hex,
                      borderTop: "2px solid " + colorPalette.neutrals.silver.hex,
                      gridColumn: 1,
                      "& .MuiIconButton-edgeEnd": {
                        position: "absolute",
                        left: 4,
                        top: 104,
                        width: 48,
                        height: 48,
                      },
                      "& .MuiIconButton-edgeStart": {
                        position: "absolute",
                        right: 4,
                        top: 104,
                        width: 48,
                        height: 48,
                      },
                      "& .MuiButtonBase-root.MuiIconButton-root": {
                        borderRadius: 1,
                      },
                      "& .MuiButtonBase-root.MuiIconButton-root:hover": {
                        backgroundColor: colorPalette.interactions.mildBerry.hex + "99",
                      },
                    },
                  },
                },
                calendarHeader: {
                  sx: {
                    "&": {
                      padding: 0,
                      textAlign: "center",
                      display: "block",
                    },
                    "& .MuiPickersCalendarHeader-labelContainer": {
                      display: "block",
                      "& .MuiPickersCalendarHeader-label": {
                        margin: 0,
                      },
                    },
                  },
                },
                day: {
                  updateStartDate: setStartDate,
                  updateEndDate: setEndDate,
                } as RenderDayProps,
              }}
              slots={{
                actionBar: showClearButton ? RangeActionBar : undefined,
                toolbar: RangeToolBar,
                day: RenderDay,
              }}
              views={["day"]}
              open={isOpen}
              value={startDate ? dayjs(startDate) : null}
              dayOfWeekFormatter={formatWeekDay}
            />
          </ThemeProvider>
        </LocalizationProvider>
      </div>
    </ClickAwayListener>
  );
};

const XDateRangePickerInstance = React.memo(XDateRangePicker, (prevProps, nextProps) => {
  return (
    prevProps.initialValue === nextProps.initialValue &&
    prevProps.disabled === nextProps.disabled &&
    prevProps.showClearButton === nextProps.showClearButton &&
    prevProps.showToolTip === nextProps.showToolTip &&
    prevProps.actionButtonVariant === nextProps.actionButtonVariant &&
    prevProps.clearButtonText === nextProps.clearButtonText &&
    prevProps.datePickerFormat === nextProps.datePickerFormat &&
    prevProps.placeholder === nextProps.placeholder &&
    prevProps.toolTipText === nextProps.toolTipText &&
    prevProps.toolTipPlacement === nextProps.toolTipPlacement &&
    prevProps.rootClassName === nextProps.rootClassName
  );
});

export default XDateRangePickerInstance;
