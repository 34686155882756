import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  ButtonLoader,
  Dialog,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  Paragraph,
  TypographyComponent,
} from "gx-npm-ui";
import styles from "./requirements-invalid-dialog.styles";
import { GCOM_3606__fontUpdate } from "../../../../lib/feature-flags";

const propTypes = { isOpen: PropTypes.bool, onCancel: PropTypes.func, onConfirm: PropTypes.func };
const useStyles = makeStyles(() => styles);
const RequirementsInvalidDialog = ({ isOpen = false, onCancel = () => {}, onConfirm = () => {} }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Dialog
      body={
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent styling={"p3"} boldness={"medium"}>
              {t("Before you can start the scorecard, your total requirement category weights must equal 100%.")}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <Paragraph type="p3" boldness="medium">
              {t("Before you can start the scorecard, your total requirement category weights must equal 100%.")}
            </Paragraph>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      }
      footer={
        <div className={classes.buttons}>
          <Button onClick={onCancel} rootClassName="btn-tertiary">
            {t("CANCEL")}
          </Button>
          <ButtonLoader btnClass="btn-primary" onClick={onConfirm}>
            {t("GO TO REQUIREMENTS")}
          </ButtonLoader>
        </div>
      }
      open={isOpen}
      title={t("Unable to start scorecard")}
    />
  );
};

RequirementsInvalidDialog.propTypes = propTypes;
export default RequirementsInvalidDialog;
