import * as videoPlayer from "@vidyard/embed-code";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  Loader,
  Paragraph,
  TypographyComponent,
} from "gx-npm-ui";
import { TimerIcon } from "gx-npm-icons";
import styles from "./overview.styles.module.scss";
import { GCOM_3606__fontUpdate } from "../../../../../../../lib/feature-flags";

const OverviewComponent = () => {
  const videoRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      try {
        const player = await videoPlayer.api.renderPlayer({
          uuid: "Y9Eb5noG8PKebuDhu1ujM4",
          container: videoRef?.current,
        });
        player.on("ready", () => {
          setIsLoading(false);
        });
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, [videoRef]);

  return (
    <div>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent styling={"h5"} boldness={"semi"}>
            {t("Overview")}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <TypographyComponent type={"h5"} boldness={"semi"}>
            {t("Overview")}
          </TypographyComponent>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
      <div className={styles.mediaContainer}>
        <div ref={videoRef} className={styles.media}>
          {isLoading && (
            <div aria-label={"loader"} className={classNames(styles.loader)}>
              <Loader size={48} />
            </div>
          )}
        </div>
        <div className={styles.mediaDesc}>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent styling={"p3"} boldness={"medium"} rootClassName={styles.mediaContent}>
                {t("Watch a quick overview of how scorecards work in BuySmart")}
              </TypographyComponent>
              <TypographyComponent rootClassName={styles.mediaTime} styling={"p3"} boldness={"regular"}>
                <TimerIcon className={styles.timerIcon} />
                1:40
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <TypographyComponent type={"p3"} boldness={"medium"} rootClassName={styles.mediaContent}>
                {t("Watch a quick overview of how scorecards work in BuySmart")}
              </TypographyComponent>
              <Paragraph rootClassName={styles.mediaTime} type={"p3"} boldness={"regular"}>
                <TimerIcon className={styles.timerIcon} />
                1:40
              </Paragraph>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </div>
      </div>
      <div className={styles.description}>
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent rootClassName={styles.heading} styling={"p2"} boldness={"semi"}>
              {t(
                "The scorecard allows you and your team to collaboratively score vendors against all of your evaluation requirements."
              )}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <TypographyComponent rootClassName={styles.heading} type={"p2"} boldness={"semi"}>
              {t(
                "The scorecard allows you and your team to collaboratively score vendors against all of your evaluation requirements."
              )}
            </TypographyComponent>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      </div>
    </div>
  );
};

export default OverviewComponent;
