import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Drawer, makeStyles } from "@material-ui/core";
import { ArrowBackIcon, BuySmartLogoOrangeStacked } from "gx-npm-icons";
import { handleEvent } from "gx-npm-lib";
import { slideInOverlayStyles as styles } from "./styles";
import { colorPalette } from "gx-npm-common-styles";
import { GCOM_3606__fontUpdate } from "../feature-flags";
import { FeatureFlagBooleanContainer, FeatureFlagBooleanOff, FeatureFlagBooleanOn } from "../featureFlags";
import { TypographyComponent } from "../typography/typography.component";

const propTypes = {
  children: PropTypes.any,
  darth: PropTypes.bool,
  headerLogo: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  isOpen: PropTypes.bool,
  isOverlayNavBar: PropTypes.bool,
  onClose: PropTypes.func,
  rootClassName: PropTypes.string,
  textBackBtn: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  customHeader: PropTypes.any,
  textTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};
const useStyles = makeStyles(() => styles);

/**
 *
 * @param {{
 *   children?: any,
 *   darth?: boolean,
 *   headerLogo?,
 *   isOpen?: boolean,
 *   isOverlayNavBar?: boolean,
 *   onClose?: Function,
 *   rootClassName?: string,
 *   textBackBtn?: string,
 *   textTitle?: string,
 *   customHeader?: ReactNode,
 * }} props
 * @var props.children children components and elements
 * @var props.darth flag to determine if darth theme is applied
 * @var props.headerLogo logo to appear in header
 * @var props.isOpen flag to determine if drawer is open
 * @var props.isOverlayNavBar flag to determine if the overlay has a nav bar.
 * @var props.onClose handler for when drawer closes
 * @var props.rootClassName className set to the root of the overlay
 * @var props.textBackBtn text to get next to "BACK TO" in header
 * @var props.textTitle title text to appear in header
 * @var props.customHeader title text to appear in header in place of BACK TO
 * @var props.customHeaderText title text to appear in header
 */
const SlideInOverlay = ({
  children = null,
  darth = false,
  headerLogo = null,
  isOpen = false,
  isOverlayNavBar = false,
  onClose = null,
  rootClassName = "",
  textBackBtn = "",
  textTitle = "",
  customHeader,
}) => {
  const classes = useStyles();
  return (
    <Drawer
      anchor={"right"}
      classes={{ root: classes.root, paper: classes.paper }}
      className={classNames(rootClassName)}
      onClose={() => handleEvent(onClose)}
      open={isOpen}
      transitionDuration={1000}
    >
      <section className={classNames("gx-slidein-header", classes.containerHeader, darth && classes.darth)}>
        {customHeader || (
          <div
            className={classNames(classes.headerBtn, darth && classes.darth)}
            onClick={() => handleEvent(onClose)}
            onKeyDown={(e) => e.key === "Enter" && handleEvent(onClose)}
            role="button"
            tabIndex={0}
            aria-label={"close"}
          >
            <ArrowBackIcon fillPath={darth ? colorPalette.basic.white.hex : colorPalette.interactions.defaultCta.hex} />
            <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
              <FeatureFlagBooleanOn>
                <TypographyComponent
                  element={"span"}
                  boldness={"bold"}
                  rootClassName={classNames(classes.btnText, classes.btnStartText)}
                  color={"defaultCta"}
                >
                  {textBackBtn ? `BACK TO` : `BACK`}
                </TypographyComponent>
                <TypographyComponent
                  element={"span"}
                  boldness={"bold"}
                  rootClassName={classNames(classes.btnText)}
                  color={"defaultCta"}
                >
                  {textBackBtn}
                </TypographyComponent>
              </FeatureFlagBooleanOn>
              <FeatureFlagBooleanOff>
                <span className={classNames("bold", classes.btnText, classes.btnStartText)}>
                  {textBackBtn ? `BACK TO` : `BACK`}
                </span>
                <span className={classNames("bold", classes.btnText)}>{textBackBtn}</span>
              </FeatureFlagBooleanOff>
            </FeatureFlagBooleanContainer>
          </div>
        )}
        {textTitle && (
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent
                styling={"p1"}
                boldness={"semi"}
                color={"iron"}
                rootClassName={classNames(classes.headerTitleGCOM3606)}
              >
                {textTitle}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <p className={classNames("p1", "semi-bold", classes.headerTitle)}>{textTitle}</p>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        )}
        <div className={classes.headerLogo}>{darth ? headerLogo : <BuySmartLogoOrangeStacked />}</div>
      </section>
      <section
        className={classNames(
          "gx-slidein-body",
          darth && classes.darth,
          classes.containerBody,
          isOverlayNavBar && classes.statusOverlayNav
        )}
      >
        {children}
      </section>
    </Drawer>
  );
};

SlideInOverlay.propTypes = propTypes;
export { SlideInOverlay };
