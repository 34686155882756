import { useTranslation } from "react-i18next";
import styles from "./technology-type.module.scss";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  Paragraph,
  TypographyComponent,
} from "gx-npm-ui";
import { GCOM_3606__fontUpdate } from "../../../../../../../../lib/feature-flags";

type TechnologyTypeProps = {
  templateName: string;
  templateDescription: string;
};
const TechnologyType = ({ templateName, templateDescription }: TechnologyTypeProps) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.root}>
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent styling={"p3"} rootClassName={styles.title}>
              {t("Gartner technology type")}
            </TypographyComponent>
            <TypographyComponent styling={"p1"} boldness={"semi"} rootClassName={styles.name}>
              {templateName}
            </TypographyComponent>
            <TypographyComponent styling={"p3"} rootClassName={styles.description}>
              {templateDescription}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <Paragraph type="p3" rootClassName={styles.title}>
              {t("Gartner technology type")}
            </Paragraph>
            <Paragraph type="p1" boldness="semi" rootClassName={styles.name}>
              {templateName}
            </Paragraph>
            <Paragraph type="p3" rootClassName={styles.description}>
              {templateDescription}
            </Paragraph>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      </div>
    </>
  );
};

export default TechnologyType;
