import { useTranslation } from "react-i18next";
import { Button, ButtonLoader, Dialog, IconButton, ProductLogo, TypographyComponent } from "gx-npm-ui";
import { ArrowRight, MultiplyIcon, SelectedVendorIcon, SelectedVendorIconSmall } from "gx-npm-icons";
import styles from "./award-product-dialog.styles.module.scss";

type ActionDialogProps = {
  awardedProductName: string;
  awardedProductImageLoc: string;
  isLoading: boolean;
  isOpen: boolean;
  selectionImageLoc: string;
  selectionName: string;
  onClose?: () => void;
  onConfirm?: () => void;
};

const AwardProductDialog = ({
  awardedProductName,
  awardedProductImageLoc,
  isOpen = false,
  isLoading = false,
  selectionImageLoc,
  selectionName,
  onClose,
  onConfirm,
}: ActionDialogProps) => {
  const { t } = useTranslation();
  const labelTitle = awardedProductName ? t("Change your selected vendor?") : t("Confirm vendor selection");
  return (
    <Dialog
      maxDialogWidth="698px"
      open={isOpen}
      title={
        <div>
          {labelTitle}
          <IconButton onClick={onClose}>
            <MultiplyIcon title="Cancel" />
          </IconButton>
        </div>
      }
      body={
        <>
          {awardedProductName && (
            <div className={styles.rootContainer}>
              <section className={styles.vendorsSection}>
                <div className={styles.leftItems}>
                  <div className={styles.oldVendorIconContainer}>
                    <ProductLogo imageHeight="56px" imageWidth="56px" logo={awardedProductImageLoc} />
                  </div>
                  <TypographyComponent styling={"p3"}>{t("Current selection")}</TypographyComponent>
                </div>
                <div className={styles.midItems}>
                  <ArrowRight />
                </div>
                <div className={styles.rightItems}>
                  <SelectedVendorIcon />
                  <ProductLogo logo={selectionImageLoc} imageWidth="70px" imageHeight="70px" />
                </div>
              </section>
              <TypographyComponent styling="p2">
                {t("You can change your selection later, if needed.")}
              </TypographyComponent>
            </div>
          )}
          {!awardedProductName && (
            <div className={styles.confirmVendor}>
              <div className={styles.selectedVendorIcons}>
                <SelectedVendorIconSmall />
                <ProductLogo logo={selectionImageLoc} imageWidth="56px" imageHeight="56px" />
              </div>
              <TypographyComponent styling={"h5"} rootClassName={styles.selectedVendorName}>
                {selectionName}
              </TypographyComponent>
              <TypographyComponent styling="p2">
                {t("You can change your selection later, if needed.")}
              </TypographyComponent>
            </div>
          )}
        </>
      }
      footer={
        <div className={styles.footer}>
          <Button onClick={onClose}>{t("CANCEL")}</Button>
          <ButtonLoader btnClass="btn-primary" handleButtonClick={onConfirm} isLoading={isLoading}>
            {t("CONFIRM AND VIEW SUMMARY")}
          </ButtonLoader>
        </div>
      }
    />
  );
};
export default AwardProductDialog;
