import React from "react";
import PropTypes from "prop-types";
import { Paragraph, Loader } from "gx-npm-ui";
import { makeStyles } from "@material-ui/core";
import { gartnerLibraryBodyStyles as styles } from "./styles";
import { useTranslation } from "react-i18next";
import { gLibraryReqLevel } from "../../../../../lib";

const propTypes = {
  defaultReqCount: PropTypes.number,
  additionalReqCount: PropTypes.number,
  handleDrawerSelection: PropTypes.func,
  isLoading: PropTypes.bool,
};

const useStyles = makeStyles(() => styles);

const GartnerLibraryMenu = ({
  defaultReqCount = 0,
  additionalReqCount = 0,
  handleDrawerSelection = () => {},
  isLoading = false,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const additionalReqCountLabel = additionalReqCount !== 1 ? t("requirements") : t("requirement");
  const defaultReqCountLabel = defaultReqCount !== 1 ? t("requirements") : t("requirement");

  const paraDescType = "p4";
  const paraReqBoldness = "regular";
  const paraTitleBoldness = "semi";
  const paraTitleType = "p3";
  return (
    <>
      {isLoading && <Loader size={48} variant="indeterminate" />}
      {!isLoading && (
        <div aria-label="gartner library actions" className={classes.container}>
          <ul className={classes.list}>
            <li>
              <div
                onClick={() => handleDrawerSelection(gLibraryReqLevel.DEFAULT)}
                onKeyDown={(e) => e.key === "Enter" && handleDrawerSelection(gLibraryReqLevel.DEFAULT)}
                tabIndex={0}
                className="gx-req-btn-wrapper"
                role="button"
              >
                <div className={classes.listItemLeft}>
                  <Paragraph boldness={paraTitleBoldness} rootClassName={classes.title} type={paraTitleType}>
                    {t("Default requirements")}
                  </Paragraph>
                  <Paragraph rootClassName={classes.description} type={paraDescType}>
                    {t(
                      "To help you kick start your evaluation, the technology template you selected automatically includes this set of requirements."
                    )}
                  </Paragraph>
                </div>
                <div
                  aria-label={`${defaultReqCount} ${defaultReqCountLabel} for ${t("Default requirements")}`}
                  className={classes.listItemRight}
                >
                  <Paragraph boldness={paraReqBoldness} type={paraDescType}>
                    <span className={classes.reqCount}>{defaultReqCount}</span> {defaultReqCountLabel}
                  </Paragraph>
                </div>
              </div>
            </li>
            <li>
              <div
                onClick={() => handleDrawerSelection(gLibraryReqLevel.ADDITIONAL)}
                onKeyDown={(e) => e.key === "Enter" && handleDrawerSelection(gLibraryReqLevel.ADDITIONAL)}
                tabIndex={0}
                className="gx-req-btn-wrapper"
                role="button"
              >
                <div className={classes.listItemLeft}>
                  <Paragraph boldness={paraTitleBoldness} rootClassName={classes.title} type={paraTitleType}>
                    {t("Additional requirements")}
                  </Paragraph>
                  <Paragraph rootClassName={classes.description} type={paraDescType}>
                    {t("Choose from a list of more detailed requirements, ideal for more thorough evaluations.")}
                  </Paragraph>
                </div>
                <div
                  aria-label={`${additionalReqCount} ${additionalReqCountLabel} for ${t("Additional requirements")}`}
                  className={classes.listItemRight}
                >
                  <Paragraph boldness={paraReqBoldness} type={paraDescType}>
                    <span className={classes.reqCount}>{additionalReqCount}</span> {additionalReqCountLabel}
                  </Paragraph>
                </div>
              </div>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

GartnerLibraryMenu.propTypes = propTypes;
export default GartnerLibraryMenu;
