import { makeStyles } from "@material-ui/core/styles";
import { UUID } from "gx-npm-lib";
import {
  Button,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as singleSpa from "single-spa";
import { evaluationHeaderV2Styles as styles } from "./evaluation-header-v2.styles";
import { GCOM_3606__fontUpdate } from "../../../../lib/feature-flags";

type EvaluationHeaderV2Props = { hasVendors: boolean };
const useStyles = makeStyles(() => styles);
const EvaluationHeaderV2 = ({ hasVendors = false }: EvaluationHeaderV2Props) => {
  const { t } = useTranslation();
  const { initiativeId = "" } = useParams<{ initiativeId: UUID }>();

  const classes = useStyles();
  const handleClick = () => {
    singleSpa.navigateToUrl(`/s/evaluation/${initiativeId}/scorecard`);
  };

  return (
    <div className={classes.root}>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent styling={"h4"}>{t("Top vendors in evaluation")}</TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <TypographyComponent type="h4">{t("Top vendors in evaluation")}</TypographyComponent>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
      {hasVendors && (
        <Button onClick={handleClick} rootClassName="btn-primary">
          {t("VIEW SCORECARD")}
        </Button>
      )}
    </div>
  );
};

export default EvaluationHeaderV2;
