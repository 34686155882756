import classNames from "classnames";
import { InitProdState } from "gx-npm-lib";
import {
  AddCircledDarkIcon,
  DeleteTrashcanIcon,
  RemoveCircleIcon,
  RevertIcon,
  SelectionRibbonIcon,
} from "gx-npm-icons";
import { PopoverMenu, TypographyComponent } from "gx-npm-ui";
import React, { useEffect, useState } from "react";
import { AvailableActions } from "../../../../../lib";
import { useTranslation } from "react-i18next";
import styles from "./evaluation-status-column.styles.module.scss";

type EvaluationStatusMenuItem = {
  index: number;
  menuListIcon?: React.ReactElement;
  name: string;
  rootClassName?: string;
  type: string;
};

type propTypes = {
  prodInitId: string;
  state: string;
  key: string;
};

const EvaluationStatusColumn = ({ prodInitId, state }: propTypes) => {
  const { t } = useTranslation();
  const [menuItems, setMenuItems] = useState<EvaluationStatusMenuItem[]>([]);

  useEffect(() => {
    if (state === InitProdState.AWARDED) {
      setMenuItems([
        {
          index: 0,
          name: t("Revert vendor selection"),
          type: AvailableActions.REVERT_AWARD,
          menuListIcon: <RevertIcon />,
          rootClassName: styles.revertVendorOption,
        },
        {
          index: 1,
          name: t("Remove from evaluation"),
          type: AvailableActions.REMOVE_PRODUCT_FROM_EVAL,
          menuListIcon: <DeleteTrashcanIcon />,
          rootClassName: styles.removeVendorOption,
        },
      ]);
    }

    if (state === InitProdState.IN_EVALUATION) {
      setMenuItems([
        {
          index: 0,
          name: t("Screen out vendor"),
          type: AvailableActions.SCREEN_OUT_PRODUCT,
          menuListIcon: <RemoveCircleIcon />,
          rootClassName: styles.screenOutOption,
        },
        {
          index: 1,
          name: t("Select this vendor"),
          type: AvailableActions.CHOOSE_PRODUCT,
          menuListIcon: <SelectionRibbonIcon />,
          rootClassName: styles.selectVendorOption,
        },
        {
          index: 2,
          name: t("Remove from evaluation"),
          type: AvailableActions.REMOVE_PRODUCT_FROM_EVAL,
          menuListIcon: <DeleteTrashcanIcon />,
          rootClassName: styles.removeVendorOption,
        },
      ]);
    }

    if (state === InitProdState.SCREENED_OUT) {
      setMenuItems([
        {
          index: 0,
          name: t("Add back to evaluation"),
          type: AvailableActions.ADD_PRODUCT_TO_EVAL,
          menuListIcon: <AddCircledDarkIcon width="24" height="24" viewBox="-5 -5 30 30" />,
          rootClassName: styles.addBackToEvalOption,
        },
        {
          index: 1,
          name: t("Remove from evaluation"),
          type: AvailableActions.REMOVE_PRODUCT_FROM_EVAL,
          menuListIcon: <DeleteTrashcanIcon />,
          rootClassName: styles.removeVendorOption,
        },
      ]);
    }
  }, [state, t]);

  const handleMenuClick = (index: number) => {
    alert(index);
  };

  return (
    <td
      key={`vendor-eval-${prodInitId}`}
      className={classNames(
        state === InitProdState.SCREENED_OUT && "gx-screened-out-bk-gnd",
        state === InitProdState.AWARDED ? "gx-awarded" : "gx-not-awarded"
      )}
    >
      <div>
        {state === InitProdState.AWARDED && <SelectionRibbonIcon />}
        <TypographyComponent styling="p3" boldness="medium" color="carbon">
          {state === InitProdState.IN_EVALUATION && t("In evaluation")}
          {state === InitProdState.AWARDED && t("Selected")}
          {state === InitProdState.SCREENED_OUT && t("Screened out")}
          {state === InitProdState.LISTED && t("Listed")}
        </TypographyComponent>
        <PopoverMenu
          iconType="kebab"
          menuItems={menuItems}
          onClick={handleMenuClick}
          showSelectedOption={false}
          useIconButton={true}
          usePortal={true}
          alignmentType={"leftBottom"}
          isDynamicWidth={false}
          popoverMenuClass={styles.scorecardEvalStatusPopoverMenu}
        />
      </div>
    </td>
  );
};

export default EvaluationStatusColumn;
