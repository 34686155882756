import { useWebSocket, UUID } from "gx-npm-lib";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TooltipV2,
  TypographyComponent,
  UsernameDisplay,
} from "gx-npm-ui";
import { Fragment, ReactElement, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { WebSocketMessageEvent } from "../../../../app.constants";
import styles from "./active-viewer-avatars.styles.module.scss";
import { GCOM_3606__fontUpdate } from "../../../../lib/feature-flags";

type WsInMsgViewingInit = {
  event: WebSocketMessageEvent;
  initiativeId: UUID;
  userList: string[];
};

const MAX_AVATARS_DISPLAYED = 3;
const ActiveViewerAvatarsComponent = () => {
  const { initiativeId } = useParams();
  const [activeViewers, setActiveViewers] = useState<string[]>([]);
  const [additionalViewersTooltip, setAdditionalViewersTooltip] = useState<ReactElement[]>([]);
  const [wsMessage] = useWebSocket<WsInMsgViewingInit, (message: unknown) => void>();
  const additionalViewerCount = activeViewers.length - MAX_AVATARS_DISPLAYED;

  useEffect(() => {
    const viewersNames: ReactElement[] = [];
    activeViewers.slice(MAX_AVATARS_DISPLAYED).forEach((viewer) => {
      viewersNames.push(<Fragment key={viewer}>{`${viewer} `}</Fragment>);
      viewersNames.push(<br key={`${viewer}-br`} />);
    });
    setAdditionalViewersTooltip(viewersNames);
  }, [activeViewers]);

  useEffect(() => {
    if (!wsMessage || !initiativeId || wsMessage.initiativeId !== initiativeId) {
      return;
    }
    if (wsMessage.event !== WebSocketMessageEvent.VIEWING_INITIATIVE || !Array.isArray(wsMessage.userList)) {
      return;
    }
    setActiveViewers(wsMessage.userList.sort());
  }, [initiativeId, wsMessage]);

  return (
    <div className={styles.activeViewerWrapper}>
      {activeViewers.slice(0, MAX_AVATARS_DISPLAYED).map((viewer) => {
        return (
          <TooltipV2
            key={viewer}
            placement="bottom"
            PopperProps={{ modifiers: { offset: { offset: "0, 4px" } } }}
            title={viewer}
          >
            <div>
              <UsernameDisplay name={viewer} showName={false} invertColor={true} />
            </div>
          </TooltipV2>
        );
      })}
      {additionalViewerCount > 0 && (
        <TooltipV2
          placement="bottom"
          PopperProps={{ modifiers: { offset: { offset: "0, 4px" } } }}
          title={additionalViewersTooltip}
        >
          <div className={styles.additionalViewersAvatar}>
            <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
              <FeatureFlagBooleanOn>
                <TypographyComponent boldness="medium" styling="p4">
                  {`+${additionalViewerCount}`}
                </TypographyComponent>
              </FeatureFlagBooleanOn>
              <FeatureFlagBooleanOff>
                <TypographyComponent boldness="medium" type="p4">
                  {`+${additionalViewerCount}`}
                </TypographyComponent>
              </FeatureFlagBooleanOff>
            </FeatureFlagBooleanContainer>
          </div>
        </TooltipV2>
      )}
    </div>
  );
};

export default ActiveViewerAvatarsComponent;
