import classNames from "classnames";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { BuySmartLogo } from "gx-npm-icons";
import { isValidDate } from "gx-npm-lib";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
  useFeatureFlag,
} from "gx-npm-ui";
import { GCOM_280_GCOM_3832__overlappingAvatarFix, GCOM_3606__fontUpdate } from "../../../../../../lib/feature-flags";
import { SelectionContext } from "../../../../selection.context";
import { DateTimeFormatOptions } from "../../../../selection.types";
import TeamMembers from "./components/team-members.component";
import TeamMembersV2Component from "./team-members-v2/team-members-v2.component";
import DetailsChecklistSection from "./components/details-checklist-section.component";
import styles from "./initiative-details-card.module.scss";

const displayDate = (date: string) => {
  let display = "";
  if (isValidDate(date)) {
    const day: DateTimeFormatOptions = "numeric";
    const month: DateTimeFormatOptions = "long";
    const timeZone = "UTC";
    const year: DateTimeFormatOptions = "numeric";
    const options = { day, month, timeZone, year };
    display = new Date(date).toLocaleString("en-us", options);
  }
  return display;
};
const InitiativeDetailsCard = () => {
  const { t } = useTranslation();
  const { title, createdDate, statusUpdateDate } = useContext(SelectionContext);
  const isOverlappingAvatarFFOn = useFeatureFlag(GCOM_280_GCOM_3832__overlappingAvatarFix);
  const dateRange = `${displayDate(createdDate)} - ${displayDate(statusUpdateDate)}`;

  return (
    <div className={styles.detailsContainer}>
      <div className={styles.detailsHeader}>
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent boldness={"semi"} rootClassName={styles.headerTitle} styling={"p4"}>
              {t("Evaluation details")}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <TypographyComponent boldness="semi" rootClassName={styles.headerTitle} type="p4">
              {t("Evaluation details")}
            </TypographyComponent>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
        <BuySmartLogo variant="white" width="92" height="18" />
      </div>
      <div className={classNames(styles.detailsContent)}>
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent boldness={"semi"} rootClassName={styles.initTitle} styling={"p1"}>
              {title ? title : t("Untitled evaluation")}
            </TypographyComponent>
            <TypographyComponent rootClassName={styles.initRange} styling={"p4"}>
              {dateRange}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <TypographyComponent boldness="semi" rootClassName={styles.initTitle} type="p1">
              {title ? title : t("Untitled evaluation")}
            </TypographyComponent>
            <TypographyComponent rootClassName={styles.initRange} type="p4">
              {dateRange}
            </TypographyComponent>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
        {isOverlappingAvatarFFOn ? <TeamMembersV2Component /> : <TeamMembers />}
        <DetailsChecklistSection />
      </div>
    </div>
  );
};

export default InitiativeDetailsCard;
