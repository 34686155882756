import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { Button, TooltipV2, TypographyComponent } from "gx-npm-ui";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import backGroundImageMan from "../../../../assets/images/scoring-ftux-person.svg";
import backGroundImageGroup from "../../../../assets/images/scoring-ftux-group.svg";
import divider from "../../../../assets/images/dividerSourGrape.svg";
import styles from "./scoring-team-ftux.styles";
import QuickstartGuideComponent from "../scorecard/scorecard-header/quickstart-guide/quickstart-guide.component";

const propTypes = { isViewOnly: PropTypes.bool, onClick: PropTypes.func };
const useStyles = makeStyles(() => styles);
const ScoringTeamFtux = ({ isViewOnly = false, onClick = () => {} }) => {
  const { t } = useTranslation();

  const classes = useStyles();

  useEffect(() => {
    if (!window.Intercom) {
      return;
    }
    if (JSON.parse(localStorage.getItem("hasViewedScoreCardTourAfterAssigningScorers")) === true) {
      return;
    }
    window.Intercom("startTour", 371703);
    localStorage.setItem("hasViewedScoreCardTourAfterAssigningScorers", true);
  }, []);

  return (
    <div className={classNames(classes.wrapper)}>
      <div className={classNames(classes.purpleBanner)}>{}</div>
      <div className={classNames(classes.backdrop, "gx-scoring-team-ftux-banner")}>
        <div className={classes.left}>
          <div className={classes.headingWrapper}>
            <TypographyComponent styling="h4" color="white">
              {t("Build your scoring team")}
            </TypographyComponent>
            <img alt="divider" src={divider} className={classes.divider} />
            <QuickstartGuideComponent miniBtnVariant={"darkTheme"} />
          </div>
          <TypographyComponent styling="p3" rootClassName={classes.description}>
            {t(
              "Before the evaluation can begin, assign team members who will be responsible for scoring your vendors."
            )}
          </TypographyComponent>
        </div>
        <div className={classNames(classes.imageWrapper)}>
          <img className={classNames(classes.imagePerson)} src={backGroundImageMan} alt="" />
          <img src={backGroundImageGroup} alt="" />
        </div>
        <TooltipV2
          deactivate={!isViewOnly}
          placement="top"
          PopperProps={{ modifiers: { offset: { offset: "0px, -130px" } } }}
          rootClassName="gx-assign-scorers-ftux"
          title={t("This action available only for evaluation owners.")}
        >
          <div>
            <Button
              disabled={isViewOnly}
              onClick={onClick}
              rootClassName={classNames("gx-assign-scorers-button", "secondary-dark-theme-btn", "btn-block")}
            >
              {t("ASSIGN SCORERS")}
            </Button>
          </div>
        </TooltipV2>
      </div>
    </div>
  );
};

ScoringTeamFtux.propTypes = propTypes;
export default ScoringTeamFtux;
