import { colorPalette } from "gx-npm-common-styles";

const scoringFooterStyles = {
  footer: {
    margin: "auto 0 0 auto",
    "& .btn-secondary": {
      marginRight: "24px",
    },
  },
  footerWrapper: {
    "& .btn-block": {
      display: "inline-block",
    },
  },
  cancelBtn: {
    marginRight: "24px",
  },
};

const scoringOptionStyles = {
  iron: {
    color: colorPalette.neutrals.iron.hex,
  },
  container: {
    marginTop: "56px",
  },
  formControlLabel: {
    marginLeft: 0,
  },
  description: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "124px",
    width: "280px",
    marginLeft: "44px",
  },
  itemsCountInfo: {
    display: "flex",
    gap: "3px",
  },
  radioColorGCOM3695: {
    "&.Mui-checked": {
      color: colorPalette.interactions.defaultCta.hex,
    },
  },
};

const scoringSelectionsStyles = {
  options: {
    display: "flex",
    width: "722px",
    justifyContent: "space-between",
  },
};

const scoringTitleStyles = {
  description: {
    color: colorPalette.neutrals.iron.hex,
    marginTop: "16px",
    width: "600px",
  },
};

const scoringContainerStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
};

export {
  scoringContainerStyles,
  scoringFooterStyles,
  scoringOptionStyles,
  scoringSelectionsStyles,
  scoringTitleStyles,
};
