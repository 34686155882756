import { useTranslation } from "react-i18next";
import { SquareQuadrantIcon } from "gx-npm-icons";
import styles from "./quadrant-box.module.scss";
import { colorPalette } from "gx-npm-common-styles";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { GCOM_3606__fontUpdate } from "../../../../../../../../../../lib/feature-flags";

type QuadrantBoxProps = {
  position: string;
  qdPosition: string;
};

type QDPositionText = {
  [key: string]: string;
};
const QuadrantBox = ({ position, qdPosition }: QuadrantBoxProps) => {
  const { t } = useTranslation();

  const qdPositionText: QDPositionText = {
    Challenger: t("Challengers"),
    Leader: t("Leaders"),
    "Niche Player": t("Niche players"),
    Visionary: t("Visionaries"),
  };

  const message = qdPositionText[qdPosition];

  return (
    <>
      {position === qdPosition ? (
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent rootClassName={styles.qdBoxSelected}>{message}</TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <div className={styles.qdBoxSelected}>{message}</div>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      ) : (
        <SquareQuadrantIcon
          letterSpacing="0.35px"
          textFill={colorPalette.neutrals.coal.hex}
          text={qdPositionText[position]}
          fontSize="12px"
          fontWeight="500"
        />
      )}
    </>
  );
};

export default QuadrantBox;
