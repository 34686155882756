import { colorPalette, weightPalette } from "gx-npm-common-styles";

const iron = colorPalette.neutrals.iron.hex;
const lightBerry = colorPalette.interactions.lightBerry.hex;
const silver = colorPalette.neutrals.silver.hex;
const white = colorPalette.basic.white.hex;
const weightAmtBold = weightPalette.bold.amount;
const weightVarBold = weightPalette.bold.variation;
const defaultCta = colorPalette.interactions.defaultCta.hex;
const hoverCta = colorPalette.interactions.hoverCta.hex;
const selectedCta = colorPalette.interactions.selectedCta.hex;

const buttonStyles = {
  container: {
    display: "inline-flex",
    position: "relative",
    "& .gx-popover-menu": {
      position: "absolute",
      right: 0,
      top: "52px",
      "& .gx-popover-menu-list": {
        position: "unset",
      },
    },
  },
  root: {
    "&.gx-dropdown": {
      "& .gx-popover-menu-list": {
        left: "68px",
      },
    },
    "&.gx-divider": {
      borderLeft: `2px solid ${defaultCta}`,
      height: "32px",
      marginTop: "8px",
    },
    "&.gx-divider-disabled": {
      backgroundColor: white,
      color: iron,
      borderLeft: `2px solid ${silver}`,
    },
    "&.gx-split-button": {
      color: defaultCta,
      border: `2px solid ${defaultCta}`,
      backgroundColor: white,
      display: "inline-flex",
      flexDirection: "row",
      "&:focus, &:active": {
        backgroundColor: lightBerry,
        border: `2px solid ${selectedCta}`,
        "& .MuiButton-label": {
          color: selectedCta,
        },
        "& .gx-divider": {
          borderLeft: `2px solid ${selectedCta}`,
        },
        "& svg": {
          "& path": {
            fill: selectedCta,
          },
        },
      },
      "& .MuiButton-label": {
        color: defaultCta,
      },
      "& svg": {
        "& path": {
          fill: defaultCta,
        },
      },
      "&:hover": {
        backgroundColor: lightBerry,
        border: `2px solid ${hoverCta}`,
        "& .MuiButton-label": {
          color: hoverCta,
        },
        "& .gx-divider": {
          borderLeft: `2px solid ${hoverCta}`,
        },
        "& svg": {
          "& path": {
            fill: hoverCta,
          },
        },
      },
    },
    "&.gx-split-button-rounded": {
      borderRadius: "4px",
    },
    "&.gx-split-button-flat": {
      borderRadius: "0px",
    },
    "&.gx-split-button-disabled": {
      backgroundColor: white,
      color: iron,
      border: `2px solid ${silver}`,
    },
    "&.gx-button": {
      fontSize: "15px",
      fontVariationSettings: weightVarBold,
      fontWeight: weightAmtBold,
      letterSpacing: "0.75px",
      lineHeight: "26px",
      height: "48px",
      padding: "11px 24px",
      zIndex: "1",
      whiteSpace: "nowrap",
      "& span.MuiTouchRipple-rippleVisible": { opacity: 0.2 },
      "& span span.MuiTouchRipple-childPulsate": { all: "unset" },
      "& .gx-button-icon": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "6px",
        height: "24px",
        width: "24px",
        "& svg": {
          marginRight: "0px",
        },
      },
      "&.btn-secondary": {
        backgroundColor: white,
        color: defaultCta,
        padding: "9px 24px",
        "& span.MuiTouchRipple-child": {
          backgroundColor: defaultCta,
        },
        "&:hover": {
          backgroundColor: lightBerry,
        },
        "&:disabled": {
          backgroundColor: white,
          color: iron,
          "& svg": {
            "& path": {
              fill: iron,
            },
          },
        },
      },
    },
    "&.gx-button-split": {
      fontSize: "15px",
      fontVariationSettings: weightVarBold,
      fontWeight: weightAmtBold,
      letterSpacing: "0.75px",
      lineHeight: "26px",
      height: "48px",
      width: "44px",
      "&.btn-secondary-split": {
        backgroundColor: white,
        color: defaultCta,
        maxWidth: "44px",
        minWidth: "44px",
        "& span.MuiTouchRipple-child": {
          backgroundColor: defaultCta,
        },
        "&:hover": {
          backgroundColor: lightBerry,
        },
        "&:disabled": {
          backgroundColor: white,
          color: iron,
          "& svg": {
            "& path": {
              fill: iron,
            },
          },
        },
      },
      "& svg": {
        marginRight: "0px",
      },
      "& .gx-button-drop-down-icon": {
        display: "flex",
        "&.arrow-up": {
          "& svg": {
            transform: "rotate(180deg)",
          },
        },
      },
    },
  },
};

export { buttonStyles };
