import React, { useEffect, useState } from "react";
import { XDatePicker, useFeatureFlag } from "gx-npm-ui";
import { makeStyles } from "@material-ui/core";
import { listDatePickerStyles as styles } from "./styles";
import classNames from "classnames";
import { GCOM_3695_ctaButtonColorUpdate } from "../../../lib/feature-flags";

type listDatePickerPropTypes = {
  isViewOnly: boolean;
  placeholder: string;
  dueDate: string;
  handleAutoSave: (date: Date | string) => void;
  popperRootClassName?: string;
  rootClassName?: string;
  toolTipText: string;
  toolTipRootClassName?: string;
  setEdit?: (edit: boolean) => void;
};

const ListDatePicker = ({
  isViewOnly = false,
  placeholder = "",
  dueDate = "",
  handleAutoSave,
  popperRootClassName = "",
  rootClassName = "",
  toolTipText = "",
  toolTipRootClassName = "",
  setEdit,
}: listDatePickerPropTypes) => {
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);
  const [display, setDisplay] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setDisplay(dueDate || "");
  }, [dueDate]);

  const onChange = (value: Date) => {
    handleAutoSave(value || "1970-01-01T00:00:00.000Z");
  };

  const onOpen = () => {
    setIsOpen(true);
    setTimeout(() => setEdit?.(true));
  };

  const onClose = () => {
    setIsOpen(false);
    setEdit?.(false);
  };

  const useStyles = makeStyles(() => styles);
  const classes = useStyles();
  return (
    <XDatePicker
      datePickerFormat={"MMM DD, YYYY"}
      placeholder={placeholder}
      disabled={isViewOnly}
      rootClassName={classNames(
        classes.container,
        rootClassName,
        isOpen && classNames(classes.selected, isFFGCOM3695 && classes.selectedGCOM3695)
      )}
      onChange={onChange}
      popperRootClassName={popperRootClassName}
      toolTipText={!isOpen ? toolTipText : ""}
      toolTipRootClassName={toolTipRootClassName}
      value={display}
      showClearButton={true}
      showToolTip={true}
      onClose={onClose}
      onOpen={onOpen}
    />
  );
};

export default ListDatePicker;
