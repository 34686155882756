import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TextLink,
  TypographyComponent,
} from "gx-npm-ui";
import styles from "./scoring-weights.styles.module.scss";
import React from "react";
import { useTranslation } from "react-i18next";
import ScoringWeightsImg from "../../../../../assets/images/quick-start/requirements/scoringWeights.svg";
import { GCOM_3606__fontUpdate } from "../../../../../lib/feature-flags";

const ScoringWeightComponent = () => {
  const { t } = useTranslation();

  return (
    <div>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent styling={"h5"} boldness={"semi"}>
            {t("Scoring weights")}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <TypographyComponent type={"h5"} boldness={"semi"}>
            {t("Scoring weights")}
          </TypographyComponent>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
      <div className={styles.scoringWeightsImageContainer}>
        <img src={ScoringWeightsImg} alt={t("Scoring weights")} />
      </div>
      <div className={styles.description}>
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent rootClassName={styles.heading} styling={"p2"} boldness={"semi"}>
              {t(
                "Requirement priorities and category weights are used to influence how scores will be calculated on your team scorecard."
              )}
            </TypographyComponent>
            <TypographyComponent rootClassName={styles.additionalInfo} styling={"p2"} boldness={"regular"}>
              {t(
                "Individual requirements can be assigned a priority of Low, Medium, or High. The categories that those requirements reside in, can then be assigned a weight value between 1 and 100, such that the combined weights of all categories equals 100."
              )}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <TypographyComponent rootClassName={styles.heading} type={"p2"} boldness={"semi"}>
              {t(
                "Requirement priorities and category weights are used to influence how scores will be calculated on your team scorecard."
              )}
            </TypographyComponent>
            <TypographyComponent rootClassName={styles.additionalInfo} type={"p2"} boldness={"regular"}>
              {t(
                "Individual requirements can be assigned a priority of Low, Medium, or High. The categories that those requirements reside in, can then be assigned a weight value between 1 and 100, such that the combined weights of all categories equals 100."
              )}
            </TypographyComponent>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
        <div className={styles.helpLink}>
          <TextLink
            text={t("Read more about how scoring works")}
            target={"_blank"}
            href={"https://intercom.help/gartner-buysmart-help-center/en/articles/6276044-how-scoring-works"}
          />
        </div>
      </div>
    </div>
  );
};

export default ScoringWeightComponent;
