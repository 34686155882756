import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { reqLibHeaderStyles as styles } from "./req-library-header.styles";
import { useTranslation } from "react-i18next";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { GCOM_3606__fontUpdate } from "../../../../../lib/feature-flags";
import { colorPalette } from "gx-npm-common-styles";

const propTypes = {
  breadcrumbText: PropTypes.string,
  categoryNum: PropTypes.number,
  requirementNum: PropTypes.number,
  breadcrumbHandleClick: PropTypes.func,
};

const useStyles = makeStyles(() => styles);
const ReqsHeader = ({ breadcrumbText = "", breadcrumbHandleClick = () => {}, categoryNum = 0, requirementNum = 0 }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent styling={"h5"} rootClassName={classes.breadcrumb}>
            <span tabIndex={0} role="button" onClick={breadcrumbHandleClick} onKeyDown={breadcrumbHandleClick}>
              <TypographyComponent element={"span"} styling={"h5"}>
                {t("Gartner Library")}
              </TypographyComponent>
            </span>
            <TypographyComponent styling={"h5"} element={"span"} className={classes.breadcrumbDivider}>
              {" "}
              /{" "}
            </TypographyComponent>
            {breadcrumbText}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <h5 className={classes.breadcrumb}>
            <span tabIndex={0} role="button" onClick={breadcrumbHandleClick} onKeyDown={breadcrumbHandleClick}>
              {t("Gartner Library")}
            </span>
            <span className={classes.breadcrumbDivider}> / </span>
            {breadcrumbText}
          </h5>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
      <div className={classes.bannerWrapper}>
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent element={"p"}>{t("Provided by Gartner")}</TypographyComponent>
            <ul>
              <li>
                <TypographyComponent boldness={"bold"} color={colorPalette.neutrals.coal.hex} element={"span"}>
                  {categoryNum}
                </TypographyComponent>{" "}
                <TypographyComponent boldness={"regular"} color={colorPalette.neutrals.coal.hex} element={"span"}>
                  {categoryNum === 1 ? t("category") : t("categories")}
                </TypographyComponent>
              </li>
              <li>
                <TypographyComponent boldness={"bold"} color={colorPalette.neutrals.coal.hex} element={"span"}>
                  {requirementNum}
                </TypographyComponent>{" "}
                <TypographyComponent boldness={"regular"} color={colorPalette.neutrals.coal.hex} element={"span"}>
                  {t("requirement")}
                  {requirementNum === 1 ? "" : "s"}
                </TypographyComponent>
              </li>
            </ul>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <p>{t("Provided by Gartner")}</p>
            <ul>
              <li>
                <strong>{categoryNum}</strong> {categoryNum === 1 ? t("category") : t("categories")}
              </li>
              <li>
                <strong>{requirementNum}</strong> {t("requirement")}
                {requirementNum === 1 ? "" : "s"}
              </li>
            </ul>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      </div>
    </>
  );
};

ReqsHeader.propTypes = propTypes;
export default ReqsHeader;
