import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { handleEvent } from "gx-npm-lib";
import { AutoResizeTextField } from "../../../../../ui/textFields";
import { textFieldStyles as styles } from "./styles";

import { GCOM_3695_ctaButtonColorUpdate } from "../../../../../lib/feature-flags";
import { useFeatureFlag } from "gx-npm-ui";

const defaultPlaceholder = "Enter text";
const propTypes = {
  autoFocus: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isViewOnly: PropTypes.bool,
  handleAutoSave: PropTypes.func,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  rootClassName: PropTypes.string,
  value: PropTypes.string,
};
const useStyles = makeStyles(() => styles);
const RequirementsTextField = ({
  autoFocus = false,
  isDisabled = false,
  isViewOnly = false,
  handleAutoSave,
  name = "",
  onBlur = null,
  placeholder = defaultPlaceholder,
  rootClassName,
  value,
}) => {
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);
  const [display, setDisplay] = useState(value || "");

  useEffect(() => {
    setDisplay(value || "");
  }, [value]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const classes = useStyles();
  return (
    <div
      className={classNames(
        rootClassName,
        classes.requirementsTextField,
        isFFGCOM3695 && classes.requirementsTextFieldGCOM3695,
        isViewOnly && "gx-read-only"
      )}
    >
      <AutoResizeTextField
        autoFocus={autoFocus}
        autoHighlight={false}
        disabled={isDisabled || isViewOnly}
        fullWidth={false}
        handleAutoSave={(newValue) => handleAutoSave(name, newValue)}
        onBlur={(event) => handleEvent(onBlur, event)}
        placeHolder={placeholder}
        rootClassName={classNames("gx-req-text-field")}
        setValue={setDisplay}
        showToolTip={false}
        value={display}
        multiline
        onKeyPress={handleKeyPress}
      />
    </div>
  );
};

RequirementsTextField.propTypes = propTypes;
export default RequirementsTextField;
