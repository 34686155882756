import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { isValidResponse } from "gx-npm-lib";
import {
  BaseTextArea,
  Button,
  ButtonLoader,
  Dialog,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  Paragraph,
  SnackbarBanner,
  Switch,
  TextLink,
  TypographyComponent,
} from "gx-npm-ui";
import { postRequest } from "../../../../context/actions/apiRequests";
import styles from "./update-reqs-with-notification-dialog.styles";
import { GCOM_3606__fontUpdate } from "../../../../lib/feature-flags";

const propTypes = {
  hasScorecard: PropTypes.bool,
  hasSurvey: PropTypes.bool,
  hasSurveyRecipientViewed: PropTypes.bool,
  initiativeId: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};
const useStyles = makeStyles(() => styles);
const UpdateReqsWithNotificationDialog = ({
  hasScorecard = false,
  hasSurvey = false,
  hasSurveyRecipientViewed = false,
  initiativeId = "",
  isOpen = false,
  onClose = (_isReloading) => {},
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isError, setIsError] = useState(false);
  const [isNotifyingRecipients, setIsNotifyingRecipients] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [message, setMessage] = useState("");

  let subHeader = t(
    "Applying updates will impact your vendor questionnaire and scorecard that uses those requirements."
  );
  if (hasScorecard && !hasSurvey) {
    subHeader = t("Applying updates will impact your active scorecard that uses those requirements.");
  } else if (!hasScorecard && hasSurvey) {
    subHeader = t("Applying updates will impact your active questionnaire that uses those requirements.");
  }

  const handleClickCancel = () => {
    onClose(false);
  };

  const handleClickSave = () => {
    setIsSaving(true);
    handleSaveChanges().finally(() => setIsSaving(false));
  };

  const handleSaveChanges = async () => {
    const url = `api/v5/initiatives/${initiativeId}/requirements/action/promote`;
    const payload = { isNotifyingRecipients, notificationMessage: message };
    const response = await postRequest(url, payload);
    if (isValidResponse(response)) {
      onClose(true);
    } else {
      setIsError(true);
    }
  };

  return (
    <Fragment>
      <Dialog
        body={
          <div className={classes.bodyRoot}>
            <div className={classes.firstSubHeader}>
              <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                <FeatureFlagBooleanOn>
                  <TypographyComponent element={"p"} rootClassName={"gx-first-dialog-sub-header"}>
                    {subHeader}
                  </TypographyComponent>
                </FeatureFlagBooleanOn>
                <FeatureFlagBooleanOff>
                  <Paragraph rootClassName="gx-first-dialog-sub-header">{subHeader}</Paragraph>
                </FeatureFlagBooleanOff>
              </FeatureFlagBooleanContainer>
              {` `}
              <TextLink href="/#" t text={t("Learn more about requirements")} target={"_blank"} />
            </div>
            {hasSurveyRecipientViewed && (
              <Fragment>
                <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                  <FeatureFlagBooleanOn>
                    <TypographyComponent element={"p"} rootClassName={"gx-recommend-notify"}>
                      {t(`We recommend that you let vendors know what changed by opting to notify them.`)}
                    </TypographyComponent>
                    <TypographyComponent element={"p"} rootClassName={"gx-notify-switch"}>
                      <Switch
                        checked={isNotifyingRecipients}
                        handleSwitchToggle={() => setIsNotifyingRecipients((prev) => !prev)}
                      />
                      {t("Send a notification to vendors who have received a questionnaire")}
                    </TypographyComponent>
                  </FeatureFlagBooleanOn>
                  <FeatureFlagBooleanOff>
                    <Paragraph rootClassName="gx-recommend-notify">
                      {t(`We recommend that you let vendors know what changed by opting to notify them.`)}
                    </Paragraph>
                    <Paragraph rootClassName="gx-notify-switch">
                      <Switch
                        checked={isNotifyingRecipients}
                        handleSwitchToggle={() => setIsNotifyingRecipients((prev) => !prev)}
                      />
                      {t("Send a notification to vendors who have received a questionnaire")}
                    </Paragraph>
                  </FeatureFlagBooleanOff>
                </FeatureFlagBooleanContainer>
              </Fragment>
            )}
            {isNotifyingRecipients && (
              <Fragment>
                <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                  <FeatureFlagBooleanOn>
                    <TypographyComponent rootClassName={"gx-notification-label"} styling={"p4"}>
                      {t("Comments to be included with the email")}
                    </TypographyComponent>
                  </FeatureFlagBooleanOn>
                  <FeatureFlagBooleanOff>
                    <Paragraph rootClassName="gx-notification-label" type="p4">
                      {t("Comments to be included with the email")}
                    </Paragraph>
                  </FeatureFlagBooleanOff>
                </FeatureFlagBooleanContainer>
                <BaseTextArea
                  flavor="OUTLINED"
                  maxRows={4}
                  minRows={4}
                  onChange={(event) => setMessage(event.target.value || "")}
                  placeholder={t("Tell the vendor what changed...")}
                  value={message}
                />{" "}
              </Fragment>
            )}
          </div>
        }
        footer={
          <div className={classes.btnFooterContainer}>
            <Button onClick={handleClickCancel} rootClassName="gx-requirements-update-notification-dialog-cancel">
              {t("CANCEL")}
            </Button>
            <ButtonLoader
              btnClass="btn-primary"
              btnRootClassName="gx-requirements-update-notification-dialog-confirm"
              disabled={isNotifyingRecipients && !message}
              isLoading={isSaving}
              onClick={handleClickSave}
            >
              {t("APPLY UPDATES")}
            </ButtonLoader>
          </div>
        }
        open={isOpen}
        title={t("Apply updates")}
      />
      <SnackbarBanner isDefaultErrorMessage={true} isOpen={isError} setIsOpen={setIsError} type="ERROR" />
    </Fragment>
  );
};

UpdateReqsWithNotificationDialog.propTypes = propTypes;
export default UpdateReqsWithNotificationDialog;
