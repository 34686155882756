import { Product } from "../../../../../../selection.types";
import classNames from "classnames";
import styles from "./product-scoresheet.module.scss";
import { InitProdState, UUID } from "gx-npm-lib";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  ProductLogo,
  TooltipV2,
  TypographyComponent,
} from "gx-npm-ui";
import { GCOM_3606__fontUpdate } from "../../../../../../../../lib/feature-flags";

type ProductScoreSheetProps = {
  reqCategoryId: UUID;
  products: Product[];
  asterisk: boolean;
};
const ProductScoreSheet = ({ reqCategoryId, products, asterisk }: ProductScoreSheetProps) => {
  return (
    <>
      {products.map((product, prodIdx) => {
        const score = product?.scorecardCategories.find((scorecardCategory) => scorecardCategory.id === reqCategoryId)
          ?.scoreDisplay;

        return (
          <div
            className={classNames(
              styles.categoryBox,
              product.state === InitProdState.AWARDED && styles.awardedBackground
            )}
            key={product.id || prodIdx}
          >
            <TooltipV2 title={product.name} placement="top" rootClassName={styles.productLogoTooltip}>
              <div>
                <ProductLogo logo={product.imageLoc} name={product.name} imageWidth="32px" imageHeight="32px" />
              </div>
            </TooltipV2>

            <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
              <FeatureFlagBooleanOn>
                <TypographyComponent
                  styling={"h4"}
                  rootClassName={classNames(
                    styles.evalCategoryTotalNA,
                    product.state === InitProdState.AWARDED && styles.evalCategoryTotal
                  )}
                >
                  {score}
                  {product.state === InitProdState.AWARDED && asterisk && "*"}
                </TypographyComponent>
              </FeatureFlagBooleanOn>
              <FeatureFlagBooleanOff>
                <TypographyComponent
                  type="h4"
                  rootClassName={classNames(
                    styles.evalCategoryTotalNA,
                    product.state === InitProdState.AWARDED && styles.evalCategoryTotal
                  )}
                >
                  {score}
                  {product.state === InitProdState.AWARDED && asterisk && "*"}
                </TypographyComponent>
              </FeatureFlagBooleanOff>
            </FeatureFlagBooleanContainer>
          </div>
        );
      })}
    </>
  );
};

export default ProductScoreSheet;
