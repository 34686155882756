import { makeStyles } from "@material-ui/core/styles";
import { isValidResponse, ScoringLevel } from "gx-npm-lib";
import {
  ButtonLoader,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  SnackbarBanner,
  TooltipV2,
  TypographyComponent,
} from "gx-npm-ui";
import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as singleSpa from "single-spa";
import ScorecardSVG from "../../../../assets/images/startScorecard.svg";
import { postRequest } from "../../../../context/actions/apiRequests";
import RequirementsInvalidDialog from "./requirements-invalid-dialog.component";
import { StartScorecardDialog } from "./start-scorecard-dialog.component";
import styles from "./start-scorecard.styles";
import QuickstartGuideComponent from "../scorecard/scorecard-header/quickstart-guide/quickstart-guide.component";
import { GCOM_3606__fontUpdate } from "../../../../lib/feature-flags";

const propTypes = {
  isOwner: PropTypes.bool,
  onRefreshData: PropTypes.func,
  totalReqCatCount: PropTypes.number,
  totalReqCatWeight: PropTypes.number,
  totalReqItemCount: PropTypes.number,
};
const useStyles = makeStyles(() => styles);
const StartScorecard = ({
  isOwner = true,
  onRefreshData = () => {},
  totalReqCatCount = 0,
  totalReqCatWeight = 0,
  totalReqItemCount = 0,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { initiativeId = "" } = useParams();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState(ScoringLevel.REQUIREMENT);

  const handleCreateScoreCard = async () => {
    setIsLoading(true);
    const url = `api/v2/initiatives/${initiativeId}/scorecard/create`;
    const payload = { scoringLevel: selectedLevel };
    const response = await postRequest(url, payload);
    if (isValidResponse(response)) {
      onRefreshData();
      setIsDialogOpen(false);
    } else {
      setIsError(true);
    }
    setIsLoading(false);
  };
  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const handleConfirmGoToRequirements = () => {
    setIsDialogOpen(false);
    singleSpa.navigateToUrl(`/s/evaluation/${initiativeId}/requirements`);
  };

  return (
    <Fragment>
      <div className={classes.rootWrapper}>
        <div className={classes.leftSide}>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent color={"carbon"} styling={"h3"}>
                {t("Score vendors with your team.")}
              </TypographyComponent>
              <TypographyComponent color={"coal"} rootClassName={classes.supportingSubtitle} styling={"p2"}>
                {t("Manage your vendor evaluation with a custom scorecard built from your requirements.")}
                {` `}
                {t("Collaborate and add your own scores to compare vendors.")}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <TypographyComponent color="carbon" type="h3">
                {t("Score vendors with your team.")}
              </TypographyComponent>
              <TypographyComponent color="coal" rootClassName={classes.supportingSubtitle} type="p2">
                {t("Manage your vendor evaluation with a custom scorecard built from your requirements.")}
                {` `}
                {t("Collaborate and add your own scores to compare vendors.")}
              </TypographyComponent>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
          {!isOwner && (
            <TypographyComponent boldness="semi" color="coal" rootClassName={classes.nonOwnerSubtitle}>
              {t("Owners in your initiative can start the evaluation for your team.")}
            </TypographyComponent>
          )}
          <TooltipV2
            title={t("This action is only available to evaluation owners.")}
            rootClassName={"gx-start-scorecard-button-tooltip"}
            placement="left"
            PopperProps={{ modifiers: { offset: { offset: "0px, -218px" } } }}
            deactivate={isOwner}
          >
            <div>
              <ButtonLoader
                btnClass="btn-primary"
                disabled={!isOwner}
                isLoading={isLoading}
                rootClassName="gx-start-scorecard-button"
                onClick={openDialog}
              >
                {t("Start Scorecard")}
              </ButtonLoader>
            </div>
          </TooltipV2>
          <QuickstartGuideComponent rootClassName={classes.quickstartGuideComponent} />
        </div>
        <div className={classes.rightSide}>{<img alt="" src={ScorecardSVG} />}</div>
      </div>
      <StartScorecardDialog
        isLoading={isLoading}
        isOpen={totalReqCatWeight === 100 && isDialogOpen}
        onCreateScorecard={handleCreateScoreCard}
        onClose={() => setIsDialogOpen(false)}
        reqCount={totalReqItemCount}
        catCount={totalReqCatCount}
        selectedLevel={selectedLevel}
        setSelectedLevel={setSelectedLevel}
      />
      <RequirementsInvalidDialog
        isOpen={totalReqCatWeight !== 100 && isDialogOpen}
        onCancel={() => setIsDialogOpen(false)}
        onConfirm={handleConfirmGoToRequirements}
      />
      <SnackbarBanner isOpen={isError} setIsOpen={setIsError} type="ERROR" isDefaultErrorMessage={true} />
    </Fragment>
  );
};

StartScorecard.propTypes = propTypes;
export default StartScorecard;
