import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { AddTeamMemberIcon, CloseIcon } from "gx-npm-icons";
import { MiniButton, ProductLogo } from "gx-npm-ui";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import EvaluationPopperViewRow from "./evaluation-popper-view-row.component";
import styles from "./evaluation-popper-view.style";
import { navigateToUrl } from "single-spa";
import { useParams } from "react-router-dom";

const propTypes = {
  evaluatorsScores: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      isOptedOut: PropTypes.bool,
      progress: PropTypes.string,
      score: PropTypes.number,
      scorDisplay: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  forwardedRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  isProgressPopper: PropTypes.bool,
  isScored: PropTypes.bool,
  onClickClose: PropTypes.func,
  prodImageLoc: PropTypes.string,
  prodName: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string,
};
const useStyles = makeStyles(styles);
const EvaluationPopperView = ({
  evaluatorsScores = [],
  forwardedRef = null,
  isError = false,
  isLoading = false,
  isProgressPopper = false,
  isScored = false,
  onClickClose = () => {},
  prodImageLoc = "",
  prodName = "",
  subTitle = "",
  title = "",
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { initiativeId } = useParams();
  let isInformativeRow = true;
  let informativeDisplay = "";
  if (isLoading) {
    informativeDisplay = t("Loading...");
  } else if (isError) {
    informativeDisplay = t("There was an error loading the data...");
  } else if (evaluatorsScores.length === 0) {
    informativeDisplay = t("No team members have been assigned to score.");
  } else {
    isInformativeRow = false;
  }
  const handleAddTeamMemberClick = () => {
    navigateToUrl(`/s/evaluation/${initiativeId}/scorecard/team-management`);
  };
  return (
    <section className={classNames(classes.sectionWrapper)} ref={forwardedRef}>
      <div className={classNames(classes.productWrapper)}>
        <ProductLogo
          imageWidth="24px"
          imageHeight="24px"
          logo={prodImageLoc}
          name={prodName}
          rootClassName="gx-eval-popper-product-logo"
        />
        <div className={classNames(classes.productNameContainer)}>
          <p className={classNames(classes.productName)}>{prodName}</p>
        </div>
        <div
          aria-label={t("Close")}
          className={classNames(classes.closeBtn)}
          onClick={onClickClose}
          onKeyDown={onClickClose}
          role="button"
          tabIndex="0"
        >
          <CloseIcon />
        </div>
      </div>
      <div className={classNames(classes.titleWrapper)}>
        <p className={classNames(classes.title)}>{title}</p>
        <p className={classNames(classes.subTitle)}>{subTitle}</p>
      </div>
      <ul className={classNames(classes.listWrapper)}>
        {isInformativeRow && <li className={classNames(classes.informativeRow)}>{informativeDisplay}</li>}
        {!isInformativeRow &&
          evaluatorsScores.map((evalScore) => {
            return (
              <EvaluationPopperViewRow
                key={evalScore.id}
                isOptedOut={evalScore.isOptedOut}
                isProgressPopper={isProgressPopper}
                isScored={isScored}
                name={evalScore.name}
                progress={evalScore.progress}
                score={evalScore.score}
                scoreDisplay={evalScore.scoreDisplay}
              />
            );
          })}
        <div className={classes.addTeamMemberContainer}>
          <MiniButton rootClassName={classes.teamMemberMiniButton} onClick={handleAddTeamMemberClick}>
            <AddTeamMemberIcon />
            {t("Add team member")}
          </MiniButton>
        </div>
      </ul>

      {!isInformativeRow && <div className={classes.listFade} />}
    </section>
  );
};

EvaluationPopperView.propTypes = propTypes;
export default EvaluationPopperView;
