import React from "react";
import PropTypes from "prop-types";
import {
  Checkbox,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { listSelectionStyles as styles } from "./list-selection.styles";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { GCOM_3606__fontUpdate } from "../../../../../../lib/feature-flags";

const propTypes = {
  requirement: PropTypes.object,
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

const useStyles = makeStyles(() => styles);
const SelectionListItem = ({ requirement = {}, selectedIds = [], onChange = (_isChecked, _id) => {} }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const renderPriority = (priority) => {
    switch (priority) {
      case 1:
        return t("Low");
      case 2:
        return t("Medium");
      case 3:
        return t("High");
      default:
        return "";
    }
  };

  return (
    <div className={classes.reqItem}>
      <div className={classes.reqItemCheckbox}>
        <Checkbox
          inputProps={{ "aria-label": "Select " + requirement.name }}
          label=""
          rootClassName="select"
          onChange={(e) => onChange(e.target.checked, requirement.id)}
          checked={selectedIds.includes(requirement.id)}
          value={requirement.id}
        />
      </div>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent rootClassName={classes.reqItemName}>{requirement.name}</TypographyComponent>
          <TypographyComponent rootClassName={classes.reqItemDesc}>{requirement.description}</TypographyComponent>
          <TypographyComponent rootClassName={classes.reqItemPriority}>
            {renderPriority(requirement.priority)}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <div className={classes.reqItemName}>{requirement.name}</div>
          <div className={classes.reqItemDesc}>{requirement.description}</div>
          <div className={classes.reqItemPriority}>{renderPriority(requirement.priority)}</div>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
    </div>
  );
};

SelectionListItem.propTypes = propTypes;
export default SelectionListItem;
