import { colorPalette } from "gx-npm-common-styles";

const teamMembersV2Styles = {
  memberAvatarRoot: {
    display: "flex",
    flexDirection: "column" as const,
    "& .gx-user .gx-ava-owner": {
      width: "188px",
    },
    "& .MuiAvatar-root": {
      height: "46px",
      width: "46px",
    },
  },
  otherMembers: {
    "& .gx-user": {
      paddingTop: "12px",
    },
  },
  otherMembersRow: {
    display: "flex",
    "& .gx-user": {
      marginRight: "-7px",
    },
  },
  additionalMembersBadge: {
    alignItems: "center",
    backgroundColor: `var(${colorPalette.neutrals.silver.name})`,
    borderRadius: "50%",
    color: `var(${colorPalette.neutrals.coal.name})`,
    display: "flex",
    height: "48px",
    justifyContent: "center",
    marginRight: "-7px",
    marginTop: "12px",
    width: "48px",
    zIndex: 1,
  },
  nameTooltipArrow: {
    "& .MuiTooltip-arrow": {
      /* need to use !important to override the inline style Popper injects into arrow of tooltip :( */
      left: "72px !important;",
    },
  },
};

export default teamMembersV2Styles;
