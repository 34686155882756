import { colorPalette, weightPalette } from "gx-npm-common-styles";
import { requirementTextAreaStyles } from "../../../sections/requirements/lists/components/textFields/styles";
import checklistTextAreaStyles from "../../../sections/checklist/components/checklistListItem/checklist-list-item.styles";

const colorNeutralCarbon = colorPalette.neutrals.carbon.hex;
const colorNeutralIron = colorPalette.neutrals.iron.hex;
const colorNeutralSilver = colorPalette.neutrals.silver.hex;

const weightRegAmt = weightPalette.regular.amount;
const weightRegVar = weightPalette.regular.variation;

const getListTextBoxStyles = (width) => {
  return {
    name: {
      float: "left",
      width: width ? width : "100%",
      "& input": {
        fontVariationSettings: weightPalette.semiBold.variation,
        fontWeight: weightPalette.semiBold.amount,
      },
      "& .Mui-focused": {
        "& fieldset": {
          border: "2px solid",
          borderColor: "var(--color-interactions-blue-berry) !important",
        },
      },
      "&:hover": {
        "& fieldset": {
          border: "1px solid",
          borderColor: "var(--color-neutrals-stone) !important",
        },
      },
      "&.gx-read-only": {
        "& .gx-text-field-container .MuiInputBase-root.Mui-disabled": {
          "& fieldset": {
            border: 0,
          },
          "& input:not(:placeholder-shown)": {
            textFillColor: colorNeutralCarbon,
          },
        },
      },
    },
  };
};

const listDeleteButtonStyles = {
  root: {
    float: "right",
  },
  requirementItemDelTooltip: {
    top: "8px !important",
    left: "-3px !important",
    width: "120px",
    textAlign: "center",
  },
};

const listItemsContainerStyles = {
  taskList: {
    borderTop: `1px solid ${colorNeutralSilver}`,
    margin: 0,
    padding: 0,
  },
};

const listDatePickerStyles = {
  container: {
    "& button": {
      width: 140,
      "&:hover": {
        backgroundColor: "transparent",
        outlineColor: colorPalette.neutrals.stone.hex,
        outlineStyle: "solid",
        outlineWidth: 1,
        borderRadius: 2,
      },
    },
    "& input": {
      "&:placeholder-shown": {
        color: "transparent",
        fontVariationSettings: weightRegVar,
        fontWeight: weightRegAmt,
      },
    },
    "&:hover": {
      "& input": {
        "&:placeholder-shown": {
          color: colorNeutralCarbon,
        },
      },
    },
  },
  selected: {
    "& input": {
      "&:placeholder-shown": {
        color: colorNeutralCarbon,
      },
    },
    "& button": {
      outlineColor: colorPalette.interactions.blueBerry.hex,
      outlineStyle: "solid",
      outlineWidth: 2,
      "&:hover": {
        outlineColor: colorPalette.interactions.blueBerry.hex,
        outlineStyle: "solid",
        outlineWidth: 2,
      },
    },
  },
  selectedGCOM3695: {
    "& button": {
      outlineColor: colorPalette.interactions.defaultCta.hex,
      "&:hover": {
        outlineColor: colorPalette.interactions.defaultCta.hex,
      },
    },
  },
};
const listItemStyles = {
  task: {
    display: "flex",
    backgroundColor: "var(--color-white)",
    borderRadius: 0,
    boxShadow: `inset 0px -1px 0px ${colorNeutralSilver}`,
    color: "var(--color-neutrals-carbon)",
    listStyleType: "none",
    "& input": {
      boxSizing: "border-box",
      fontVariationSettings: weightRegVar,
      fontWeight: weightRegAmt,
      height: 48,
      paddingBottom: 13,
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 14,
      "&:placeholder-shown": {
        color: colorNeutralIron,
        fontVariationSettings: weightRegVar,
        fontWeight: weightRegAmt,
      },
    },
    "&.complete": {
      "& input": {
        color: colorNeutralIron,
        fontVariationSettings: weightRegVar,
        fontWeight: weightRegAmt,
      },
    },
    "&.not-complete": {
      "& input": {
        color: colorNeutralCarbon,
        fontVariationSettings: weightPalette.medium.variation,
        fontWeight: weightPalette.medium.amount,
      },
    },
    "&:hover": {
      backgroundColor: colorPalette.basic.white.hex,
      cursor: "pointer",
    },
    "&.edit": {
      backgroundColor: "var(--color-interactions-light-berry)",
    },
    "& fieldset": {
      border: 0,
      borderRadius: 0,
      paddingLeft: 0,
    },
    "&.gx-is-not-draggable": {
      cursor: "auto",
    },
  },
  classClass: { clear: "both" },
  draggable: {
    float: "left",
    height: 48,
    width: 48,
    "& .gx-drag-kebab": {
      visibility: "hidden",
      "&.gx-visible-kebab, &:active": {
        visibility: "visible",
      },
    },
    "&:focus .gx-drag-kebab": {
      visibility: "visible",
    },
    "& svg": {
      cursor: "grab",
    },
  },
};

const getTextAreaStyles = (type) => {
  let styles = {};
  if (type === "checklist") {
    styles = checklistTextAreaStyles;
  } else if (type === "requirements") {
    styles = requirementTextAreaStyles;
  }
  return styles;
};

export {
  getListTextBoxStyles,
  getTextAreaStyles,
  listDeleteButtonStyles,
  listItemsContainerStyles,
  listItemStyles,
  listDatePickerStyles,
};
