import { AppBar, Tab, Tabs } from "@material-ui/core";
import { SelectionRibbonIcon } from "gx-npm-icons";
import { InitStatus, UUID } from "gx-npm-lib";
import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as singleSpa from "single-spa";
import { TabIndex, TabName } from "../../../../app.constants";
import { HeaderV2Context } from "../../header-v2.context";
import styles from "./tabs-banner.styles.module.scss";
import { GCOM_3695_ctaButtonColorUpdate } from "../../../../lib/feature-flags";
import { useFeatureFlag } from "gx-npm-ui";
import classnames from "classnames";

const TabsBannerComponent = () => {
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);
  const { t } = useTranslation();
  const [index, setIndex] = useState(0);
  const { initiativeId = "", tab = "" } = useParams<{ initiativeId: UUID; tab: TabName | "" }>();
  const { initStatus } = useContext(HeaderV2Context);

  useEffect(() => {
    if (!tab) {
      return;
    }
    if (tab.toLocaleLowerCase() === TabName.OVERVIEW) {
      setIndex(TabIndex.OVERVIEW);
    } else if (tab.toLocaleLowerCase() === TabName.CHECKLIST) {
      setIndex(TabIndex.CHECKLIST);
    } else if (tab.toLocaleLowerCase() === TabName.REQUIREMENTS) {
      setIndex(TabIndex.REQUIREMENTS);
    } else if (tab.toLocaleLowerCase() === TabName.PRODUCTS) {
      setIndex(TabIndex.PRODUCTS);
    } else if (tab.toLocaleLowerCase() === TabName.SURVEY) {
      setIndex(TabIndex.SURVEY);
    } else if (tab.toLocaleLowerCase() === TabName.SCORECARD) {
      setIndex(TabIndex.SCORECARD);
    } else if (tab.toLocaleLowerCase() === TabName.SELECTION) {
      setIndex(TabIndex.SELECTION);
    }
  }, [tab]);

  const handleChange = (idx: number) => {
    if (idx === index) {
      return;
    }
    let tabName = TabName.OVERVIEW;
    if (idx === TabIndex.CHECKLIST) {
      tabName = TabName.CHECKLIST;
    } else if (idx === TabIndex.REQUIREMENTS) {
      tabName = TabName.REQUIREMENTS;
    } else if (idx === TabIndex.PRODUCTS) {
      tabName = TabName.PRODUCTS;
    } else if (idx === TabIndex.SURVEY) {
      tabName = TabName.SURVEY;
    } else if (idx === TabIndex.SCORECARD) {
      tabName = TabName.SCORECARD;
    } else if (idx === TabIndex.SELECTION) {
      tabName = TabName.SELECTION;
    }

    singleSpa.navigateToUrl(`/s/evaluation/${initiativeId}/${tabName}`);
  };

  const tabClasses = {
    root: styles.muiTabRoot,
    selected: classnames(styles.muiTabSelected, isFFGCOM3695 && styles.muiTabSelectedGCOM3695),
    wrapper: styles.muiTabWrapper,
  };
  return (
    <div className={styles.tabsBannerContainer}>
      <AppBar classes={{ root: styles.muiAppBarRoot }} elevation={0} position="sticky">
        <Tabs
          classes={{
            indicator: classnames(styles.muiTabsIndicator, isFFGCOM3695 && styles.muiTabsIndicatorGCOM3695),
            scrollButtons: styles.muiTabsScrollButtons,
          }}
          onChange={(_event, newIndex) => handleChange(newIndex)}
          scrollButtons="auto"
          textColor="primary"
          value={index}
          variant="scrollable"
        >
          <Tab classes={tabClasses} className="gx-dragon-tab-1" label={t("Overview")} />
          <Tab classes={tabClasses} className="gx-dragon-tab-2" label={t("Checklist")} />
          <Tab classes={tabClasses} className="gx-dragon-tab-3" label={t("Requirements")} />
          <Tab classes={tabClasses} className="gx-dragon-tab-4" label={t("Vendors")} />
          <Tab classes={tabClasses} className="gx-dragon-tab-5" label={t("Questionnaire")} />
          <Tab classes={tabClasses} className="gx-dragon-tab-6" label={t("Scorecard")} />
          <Tab
            classes={{ ...tabClasses, labelIcon: styles.muiTabLabelIcon }}
            className="gx-dragon-tab-7"
            icon={<Fragment>{initStatus === InitStatus.SELECTED && <SelectionRibbonIcon />}</Fragment>}
            label={t("Selection")}
          />
        </Tabs>
      </AppBar>
    </div>
  );
};

export default TabsBannerComponent;
