import React from "react";
import { useTranslation } from "react-i18next";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TextLink,
  TypographyComponent,
} from "gx-npm-ui";
import styles from "./calculating-scores.styles.module.scss";
import CalculatingScoresImg from "../../../../../../../assets/images/quick-start/scorecard/calculating-scores.svg";
import { GCOM_3606__fontUpdate } from "../../../../../../../lib/feature-flags";

const CalculatingScoresComponent = () => {
  const { t } = useTranslation();

  return (
    <div>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent styling={"h5"} boldness={"semi"}>
            {t("Calculating scores")}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <TypographyComponent type={"h5"} boldness={"semi"}>
            {t("Calculating scores")}
          </TypographyComponent>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
      <img src={CalculatingScoresImg} alt={t("Calculating scores")} className={styles.calculatingScoresImg} />
      <div className={styles.description}>
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent rootClassName={styles.heading} styling={"p2"} boldness={"semi"}>
              {t(
                "Final scores for individual requirements are determined by averaging scores from all assigned scorers."
              )}
            </TypographyComponent>
            <TypographyComponent rootClassName={styles.additionalInfo} styling={"p2"} boldness={"regular"}>
              {t(
                "Requirement priorities and category weights are used to influence the final score for any given vendor. A detailed breakdown of scoring can be found in the complete Help Center article below."
              )}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <TypographyComponent rootClassName={styles.heading} type={"p2"} boldness={"semi"}>
              {t(
                "Final scores for individual requirements are determined by averaging scores from all assigned scorers."
              )}
            </TypographyComponent>
            <TypographyComponent rootClassName={styles.additionalInfo} type={"p2"} boldness={"regular"}>
              {t(
                "Requirement priorities and category weights are used to influence the final score for any given vendor. A detailed breakdown of scoring can be found in the complete Help Center article below."
              )}
            </TypographyComponent>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
        <div className={styles.helpLink}>
          <TextLink
            text={t("Read more about how scores are calculated")}
            target={"_blank"}
            href={
              "https://intercom.help/gartner-buysmart-help-center/en/articles/6276044-how-scoring-works#h_7dfd5bb789"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default CalculatingScoresComponent;
