import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { LightningBoltExpanded, RibbonBulbIcon } from "gx-npm-icons";
import classes from "./research-slide-in-header-logo.styles.module.scss";
import { GCOM_3476_3523__researchRibbonUpdate } from "../../../feature-flags";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  useFeatureFlag,
} from "../../../featureFlags";

const ResearchSlideInHeaderLogoComponent = () => {
  const { t } = useTranslation();
  const isGCOM3523FF = useFeatureFlag(GCOM_3476_3523__researchRibbonUpdate);
  return (
    <div
      className={classNames(
        classes.researchSlideInHeaderLogoStyles,
        isGCOM3523FF && classes.researchSlideInHeaderLogoStylesGCOM3523
      )}
    >
      <FeatureFlagBooleanContainer flagName={GCOM_3476_3523__researchRibbonUpdate}>
        <FeatureFlagBooleanOff>
          <LightningBoltExpanded data-testid="lightning-bolt-expanded" width={"24"} height={"24"} />
        </FeatureFlagBooleanOff>
        <FeatureFlagBooleanOn>
          <RibbonBulbIcon data-testid="ribbon-bulb-expanded" />
        </FeatureFlagBooleanOn>
      </FeatureFlagBooleanContainer>
      <p className={classNames("medium-bold p4")}>{t("Gartner research behind this market")}</p>
    </div>
  );
};
export default ResearchSlideInHeaderLogoComponent;
