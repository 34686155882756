import React, { Fragment } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import BulkOperationsBarButton from "./bulk-operations-bar-button.component";
import { bulkOpBarStyles as styles } from "./styles";
import { GCOM_3695_ctaButtonColorUpdate } from "../../../../lib/feature-flags";
import { useFeatureFlag } from "gx-npm-ui";

const propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      btnLabel: PropTypes.node,
      btnVariant: PropTypes.string,
      hasPopover: PropTypes.bool,
      icon: PropTypes.node,
      name: PropTypes.string,
    })
  ),
  itemCount: PropTypes.number,
  onClickButton: PropTypes.func,
  onClickOption: PropTypes.func,
  popoverItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      index: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  isRequirementLibrary: PropTypes.bool,
};
const useStyles = makeStyles(() => styles);
const BulkOperationsBar = ({
  buttons = [],
  itemCount = 0,
  onClickButton = null,
  onClickOption = null,
  popoverItems = [],
  isRequirementLibrary = false,
}) => {
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);
  const classes = useStyles();
  const { t } = useTranslation();
  const reqConjugate = itemCount === 1 ? t("requirement") : t("requirements");
  return (
    <div
      aria-hidden={itemCount === 0}
      className={classNames(
        classes.container,
        isFFGCOM3695 && classes.containerGCOM3695,
        itemCount > 0 ? classes.containerOpen : classes.containerClosed,
        isRequirementLibrary && "gx-requirements-library-bulkbar"
      )}
      role="dialog"
    >
      <Box className={classes.wrapper}>
        <Box className={classes.textWrapper}>
          {itemCount} {reqConjugate} selected
        </Box>
        <Box className={classes.actionButtons}>
          {buttons.map((button, index) => {
            return (
              <Fragment key={button.name}>
                <BulkOperationsBarButton
                  btnLabel={button.btnLabel}
                  btnVariant={button.btnVariant}
                  hasPopover={!!button.hasPopover}
                  icon={button.icon}
                  index={index}
                  onClickButton={onClickButton}
                  onClickOption={onClickOption}
                  popoverItems={popoverItems}
                  isRequirementLibrary={isRequirementLibrary}
                />
                {index === 0 && <div className={classes.divider} />}
              </Fragment>
            );
          })}
        </Box>
      </Box>
    </div>
  );
};

BulkOperationsBar.propTypes = propTypes;
export default BulkOperationsBar;
