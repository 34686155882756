import { Box } from "@material-ui/core";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  Header,
  TypographyComponent,
} from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import styles from "./boxed-product-header.styles.module.scss";
import { GCOM_3606__fontUpdate } from "../../../lib/feature-flags";

const BoxedProductHeaderComponent = () => {
  const { t } = useTranslation();
  return (
    <Box className={styles.header}>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent rootClassName={styles.headerText} styling={"h3"}>
            {t("Vendor list")}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <Header rootClassName={styles.headerText}>{t("Vendor list")}</Header>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
    </Box>
  );
};

export default BoxedProductHeaderComponent;
