import { colorPalette } from "gx-npm-common-styles";

const topVendorsV2Styles = {
  root: {
    border: `solid 1px ${colorPalette.neutrals.silver.hex}`,
    marginTop: "32px",
  },
  vendor: {
    display: "inline-block",
    "&.vendor-cnt-1": {
      width: "100%",
    },
    "&.vendor-cnt-2": {
      width: "50%",
    },
    "&.vendor-cnt-3": {
      width: "33.33%",
    },
    "@media (max-width: 1240px)": {
      "&.vendor-cnt-3 $logoTitle": {
        display: "block",
        height: "112px",
        overflowY: "unset" as const,
        textAlign: "center" as const,
        "& .gx-p": {
          paddingLeft: "unset",
          paddingTop: "12px",
          textAlign: "center" as const,
        },
      },
    },
  },
  logoTitle: {
    alignItems: "center",
    borderRight: `solid 1px ${colorPalette.neutrals.silver.hex}`,
    display: "inline-flex",
    height: "88px",
    justifyContent: "center",
    margin: "12px 0",
    overflowY: "hidden" as const,
    padding: "12px 24px",
    transition: "height 0.5s",
    width: "100%",
    "&.last-vendor": {
      borderRight: "none",
    },
    "& .gx-p": {
      paddingLeft: "12px",
      textAlign: "left" as const,
    },
  },
  vendorScore: {
    borderTop: `solid 1px ${colorPalette.neutrals.silver.hex}`,
    padding: "32px 0",
    textAlign: "center" as const,
    "&.grey-background": {
      backgroundColor: colorPalette.neutrals.quartz.hex,
    },
    "& .gx-h": {
      paddingBottom: "8px",
    },
    "& .gx-p": {
      color: colorPalette.neutrals.iron.hex,
      paddingBottom: "24px",
    },
  },
};

export { topVendorsV2Styles };
