import { UUID } from "gx-npm-lib";
import { updateStateSubject } from "gx-npm-messenger-util";
import { ProductLinkComponent } from "gx-npm-ui";
import { useParams } from "react-router-dom";
import styles from "./product-name-cell.styles.module.scss";

const ProductNameCellComponent = ({ imageLoc = "", initProdId = "", name = "" }) => {
  const { initiativeId = "" } = useParams<{ initiativeId: UUID }>();

  const handleClick = () => {
    updateStateSubject("WORKSPACE_HEADER_SOURCE_PAGE", { sourcePage: "vendor-list" });
  };

  return (
    <ProductLinkComponent
      href={`/s/evaluation/${initiativeId}/product/${initProdId}/profile`}
      imageLoc={imageLoc}
      logoSize="32px"
      name={name}
      onClick={handleClick}
      rootClassName={styles.productLink}
      textBoldness="semi"
      textType="p1"
    />
  );
};

export default ProductNameCellComponent;
