import { colorPalette } from "gx-npm-common-styles";
const reqLibHeaderStyles = {
  breadcrumb: {
    marginBottom: "33px",
    "& span": {
      color: colorPalette.interactions.blueBerry.hex,
    },
    "& span:hover": {
      cursor: "pointer",
    },
  },
  breadcrumbGCOM3695: {
    "& span": {
      color: colorPalette.interactions.defaultCta.hex,
    },
  },
  breadcrumbDivider: {
    marginLeft: "8px",
    marginRight: "8px",
  },
  bannerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    minHeight: "72px",
    alignItems: "center",
    fontSize: "16px",
    backgroundColor: colorPalette.neutrals.silver.hex,
    borderRadius: "8px",
    marginBottom: "24px",
    padding: "12px 24px 12px 40px",
    width: "100%",
    whiteSpace: "nowrap",
    color: colorPalette.neutrals.coal.hex,
    "& p": {
      fontSize: "16px",
    },
    "& ul": {
      listStyleType: "none",
      margin: 0,
      padding: 0,
      "& li": {
        display: "inline-block",
        padding: "6px 0",
      },
      "& li:first-child": {
        marginRight: "20px",
        paddingRight: "22px",
        borderRight: `1px solid ${colorPalette.neutrals.stone.hex}`,
      },
    },
  },
};

export { reqLibHeaderStyles };
